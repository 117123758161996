// import React, { useEffect, useRef, useState } from 'react';
// import { Tabs, Tab, Box, Typography, IconButton, ListItem, Drawer, Card, CardMedia, Avatar, Stack, Button, Paper, TextField, CircularProgress } from '@mui/material';
// import { styled } from '@mui/system';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { CONSTANTS } from 'src/constants';
// import axiosService from 'src/services/axios';
// import * as Icons from '@mui/icons-material';
// import { useMerchant } from 'src/context/MerchantContext';
// import { Coupon, CouponMapping } from 'src/intefaces/coupon.interface';
// import QRCode from 'qrcode.react';
// import { Html5QrcodeScanner } from 'html5-qrcode';
// // import { Scanner } from '@yudiel/react-qr-scanner';
// import CloseIcon from '@mui/icons-material/Close';
// import './coupon-page.css';
// import moment from 'moment';
// import { RecordTypes } from 'src/types/enum';
// import { useAuth } from 'src/context/AuthContext';
// import { Member } from 'src/types/member-interface';
// import { useAlert } from 'src/components/CommonAlert';
// const FullWidthTab = styled(Tab)(({ theme }) => ({
//     width: '50%', // Make each tab take up the full width
// }));

// const TabPanel = ({ children, value, index, ...other }: any) => (
//     <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`full-width-tabpanel-${index}`}
//         aria-labelledby={`full-width-tab-${index}`}
//         {...other}
//     >
//         {value === index && (
//             <Box >
//                 {children}
//             </Box>
//         )}
//     </div>
// );
// const StyledTabs = styled(Tabs)(({ theme }) => ({
//     "border-bottom": 0,
//     '& .MuiTabs-indicator': {
//         display: 'none', // Hide the default indicator
//     },
// }));
// const StyledTab = styled(Tab)(({ theme }) => ({
//     borderRadius: '25px',
//     margin: theme.spacing(0.5),
//     padding: theme.spacing(1, 2),
//     color: theme.palette.text.primary,
//     minHeight: 0,
//     textTransform: 'none'

// }));

// // Styled components
// const StyledListItem = styled(ListItem)(({ theme }) => ({
//     '&:nth-of-type(even)': {
//         backgroundColor: theme.palette.grey[100],
//     },
//     '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.common.white,
//     },
// }));

// const OutletName = styled(Typography)(({ theme }) => ({
//     fontWeight: 'bold',
//     color: theme.palette.primary.main,
// }));

// const OutletDetails = styled(Typography)(({ theme }) => ({
//     color: theme.palette.text.secondary,
// }));

// const ArrowIcon = styled(IconButton)(({ theme }) => ({
//     marginLeft: 'auto',
// }));
// const MobileCouponsView: React.FC = () => {
//     const [value, setValue] = React.useState(0);
//     const navigate = useNavigate();
//     const [tabs, setTabs] = useState<string[]>(["Active", "Expired", "Redeemed"]);
//     const [noticeLayout, setNoticeLayout] = useState<string>();
//     const [memberCouponMap, setMemberCouponMap] = useState<CouponMapping[]>([]);
//     const { merchantid } = useParams()
//     const { merchant, setBackButton } = useMerchant();
//     const [selectedCoupon, setSelectedCoupon] = useState<CouponMapping | undefined>();
//     const [couponTransferMode, setCouponTransferMode] = useState<boolean>(false);
//     const [couponReedemMode, setCouponReedemMode] = useState<boolean>(false);
//     const [scannedMember, setScannedMember] = useState<Member | undefined>(undefined);
//     const [searchingMember, setSearchingMember] = useState<boolean>(false);
//     const [uTUsername, setUTUsername] = useState<string>("");
//     const [uTPassword, setUTPassword] = useState<string>("");
//     const location = useLocation();
//     const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//         setValue(newValue);
//     };
//     const { isAuthenticated } = useAuth();
//     const scannerRef = useRef<HTMLDivElement>(null);
//     const html5QrScannerRef = useRef<Html5QrcodeScanner | null>(null);
//     const openOutlet = (id: number) => {
//         navigate("/" + id);
//         window.scrollTo(0, 0);
//         setValue(0)
//     }
//     const getNoticeLayout = async () => {
//         try {
//             const response = await axiosService.get<{ id: string, noticeLayout: string } | any>(`${CONSTANTS.ApiConstants.public_getNoticeLayout}/${merchantid}`)
//             if (response.status != 200) {

//             } else {
//                 const json = response.data;
//                 setNoticeLayout(json?.noticeLayout);
//             }
//         } catch (ex: any) {

//             console.error("Exception Caught", ex)
//         }
//     }
//     const { showAlert } = useAlert();
//     const getCoupons = async () => {
//         try {
//             const response = await axiosService.get<CouponMapping[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.couponMapping]}`)
//             if (response.status != 200) {
//                 setMemberCouponMap([])
//             } else {
//                 const json = response.data;
//                 setMemberCouponMap(json);
//             }
//         } catch (ex: any) {
//             setMemberCouponMap([])
//             console.error("Exception Caught", ex)
//         }
//     }
//     const postRedeem= async()=>{
//         try {
//             const payload = {
//                 password : uTPassword,
//                 couponMappingId: selectedCoupon?.id,
//                 merchantid:merchant?.id
//             }
//             const response:any = await axiosService.post<any>(`${CONSTANTS.ApiConstants.redeemCoupon}`,payload)
//             if (response.status != 200) {
//                 showAlert(response.message || "Failed to Redeem","error");
//             } else {
//                 showAlert( "Redeem Successful","success");
//                 setSelectedCoupon(undefined);
//                 setScannedMember(undefined)
//                 setCouponReedemMode(false);
//                 setUTPassword("")
//                 setUTUsername("")
//                 getCoupons()
//             }
//         } catch (ex: any) {
//             showAlert(ex.response.message || "Failed to Redeem","error");
//         }
//     }
//     const postTransfer= async()=>{
//         try {
//             const payload = {
//                 password : uTPassword,
//                 couponMappingId: selectedCoupon?.id,
//                 username : uTUsername,
//                 transferTo:scannedMember?.id,
//                 merchantid:merchant?.id
//             }
//             const response:any = await axiosService.post< any>(`${CONSTANTS.ApiConstants.transferCoupon}`,payload)
//             if (response.status != 200) {
//                 showAlert(response.message || "Failed to Transfer","error");
//             } else {
//                 showAlert( "Transfer Successful","success");
//                 setSelectedCoupon(undefined);
//                 setScannedMember(undefined)
//                 setUTPassword("")
//                 setUTUsername("")
//                 setCouponTransferMode(false)
//                 getCoupons()
//             }
//         } catch (ex: any) {
//             showAlert(ex.response.message || "Failed to Redeem","error");
//         }
//     }
//     const startScan = () => {

//         if (scannerRef.current) {
//             //   console.log("html5QrScannerRef.current ",html5QrScannerRef.current );
//             html5QrScannerRef.current = new Html5QrcodeScanner(
//                 "qr-reader",
//                 { fps: 10, qrbox: 250, showZoomSliderIfSupported: true, aspectRatio: 1, },
//                 true

//             );

//             html5QrScannerRef.current.render(
//                 (result) => {
//                     // setScanResult(result.text);
//                     html5QrScannerRef.current?.clear();
//                 },
//                 (error) => {
//                     console.error(error);
//                 }
//             );
//         }
//     };

//     const stopScan = () => {
//         html5QrScannerRef.current?.clear();
//         //setScanResult(null);
//     };
//     useEffect(() => {
//         getCoupons()
//         getNoticeLayout()
//         setBackButton && setBackButton(false);

//     }, [])
//     // useEffect(() => {
//     //     if (selectedCoupon) {
//     //         couponTransferMode && startScan();
//     //     } else {
//     //         stopScan();
//     //     }
//     // }, [selectedCoupon])
//     useEffect(() => {
//         couponTransferMode && startScan();
//         !couponTransferMode && stopScan();
//     }, [couponTransferMode])
//     console.log("memberCouponMap", memberCouponMap);
//     const Coupon = ({ mcoupon }: { mcoupon: CouponMapping }) => {
//         return (
//             <Card
//                 onClick={() => setSelectedCoupon(mcoupon)}
//                 elevation={0}
//                 sx={{
//                     marginTop: "8px",
//                     display: 'flex',
//                     alignItems: 'center',
//                     width: "100%",
//                     borderRadius: 2,
//                     overflow: 'hidden',
//                     position: 'relative',
//                     background: `${mcoupon.coupon.themeColor}`,
//                     border: `1px solid ${mcoupon.coupon.themeColor}`,
//                     borderLeft: 0,
//                     '&::before': {
//                         content: '""',
//                         position: 'absolute',
//                         left: 0, // Aligns the scalloped border with the left side of the card
//                         top: 0,
//                         height: '100%',
//                         width: 20, // Width of the scalloped border area
//                         backgroundImage: `radial-gradient(circle, white 50%, transparent 50%)`,
//                         backgroundSize: '10px 10px', // Size of each scallop
//                         backgroundPosition: '-5px 0', // Adjust this to align scallops perfectly
//                         backgroundRepeat: 'repeat-y', // Repeats the scallops along the y-axis
//                         backgroundColor: `${mcoupon.coupon.themeColor}`, // Matches the background of the scallops with the card border
//                         zIndex: 1, // Ensures the scalloped border appears on top of the card's background
//                     },
//                 }}
//             >
//                 {/* Left Section with Icon */}

//                 <Box
//                     sx={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         width: 100,
//                         color: 'white',
//                         zIndex: 2, // Ensures the icon is above the scalloped border
//                     }}
//                 >
//                     {mcoupon.coupon.image ?
//                         <Avatar
//                             variant='square'
//                             sx={{ width: "50%", height: "50%" }}
//                             src={mcoupon.coupon.image}
//                         />
//                         :
//                         React.createElement(Icons[(mcoupon.coupon.icon) as keyof typeof Icons], { style: { color: mcoupon.coupon.iconColor, fontSize: "50" } })

//                     }
//                 </Box>

//                 {/* Right Section with Content */}
//                 <Box
//                     sx={{
//                         padding: 1,
//                         display: 'flex',
//                         flexDirection: 'column',
//                         flexGrow: 1,
//                         textAlign: "left",
//                         backgroundColor: `${mcoupon.coupon.bgColor}`,
//                         zIndex: 2, // Ensures the content is above the scalloped border
//                     }}
//                 >
//                     <Typography
//                         variant="subtitle1"
//                         sx={{
//                             display: '-webkit-box',
//                             WebkitBoxOrient: 'vertical',
//                             overflow: 'hidden',
//                             textOverflow: 'ellipsis',
//                             WebkitLineClamp: 3, // Limits text to 3 lines
//                             lineHeight: '1.5rem', // Set line height (adjust as needed)
//                             height: '4.5rem', // 3 lines * lineHeight (3 * 1.5rem)
//                             textAlign: 'left',
//                             fontWeight: "bold"
//                         }}
//                     >
//                         {mcoupon.coupon.name}
//                     </Typography>
//                     <Typography variant="caption" sx={{}}>
//                         {selectedCoupon?.coupon?.validUntil ? `Expiry : ${moment(selectedCoupon?.coupon?.validUntil ?? null).utc(true).local().toString()}` : ""}
//                     </Typography>
//                 </Box>
//             </Card>
//         );
//     };
//     const ReedemCoupon = () => {
//         return <Box sx={{ width: '100%', p: 2 }}>
//             <Stack style={{ alignItems: "center" }}>
//                 <Typography variant='h4' sx={{ textAlign: "center" }}>
//                     {"Reedem"}
//                 </Typography>

//                 <br />
//                 <TextField
//                     onChange={(e) => { setUTPassword(e.target.value)}}
//                     fullWidth
//                     id="password"
//                     label="Password"
//                 />
//                 <br />
//                 <Button fullWidth variant="outlined" style={{ flex: 1, color: merchant?.FooterActiveButtonColor, borderColor: merchant?.FooterActiveButtonColor, }} onClick={() => postRedeem()}>Reedem</Button>
//                 <br />
//                 <IconButton style={{ color: merchant?.FooterActiveButtonColor, borderColor: merchant?.FooterActiveButtonColor, }} onClick={() => { setCouponReedemMode(false) }} aria-label="cancel">
//                     <CloseIcon />
//                 </IconButton>
//             </Stack>
//         </Box>
//     }
//     const onQrScanned = async (memberId: string) => {

//         try {
//             setSearchingMember(true);
//             const response = await axiosService.get<Member>(`${CONSTANTS.ApiConstants.getMemberDetails}/${memberId}`)

//             if (response.status != 200) {
//                 setScannedMember(undefined)
//                 setSearchingMember(false);
//                 showAlert('Failed to Get Member Details', 'error');
//             } else {
//                 const json: Member = response.data;
//                 if (json.DOB) {
//                     json.DOB = moment(json.DOB ?? null).utc(true).local();;
//                     json.RegistredTime = moment(json.RegistredTime ?? null).utc(true).local();
//                 }
//                 setSearchingMember(false);
//                 if (json?.Mobile) {
//                     setScannedMember(json)
//                 }
//                 else { showAlert('Member Not Found', 'error'); }
//             }
//         } catch (ex) {
//             setScannedMember(undefined)
//             setSearchingMember(false);
//             showAlert('Failed to Get Member Details', 'error');
//             console.error("Exception Caught", ex)
//         }
//     }
//     const TransferCoupon = () => {
//         return <Box sx={{ width: '100%', p: 2 }}>
//             <Stack style={{ alignItems: "center" }}>
//                 <Typography variant='h4' sx={{ textAlign: "center" }}>
//                     {"Tranfer"}
//                 </Typography>
//                 <Stack style={{ width: "80vw", height: "80vw", justifyContent: "center", alignItems: "center" }}>
//                     {searchingMember ? <CircularProgress size={50} /> :
//                         (!scannedMember ? <Scanner onScan={(result) => onQrScanned(result[0].rawValue)} /> :
//                             <Box >
//                                 <Avatar src={scannedMember?.Avatar} ></Avatar>
//                                 <Typography variant="subtitle1">{scannedMember.Mobile} </Typography>
//                                 <Typography variant='body1'>{scannedMember.MemberName} </Typography>
//                             </Box>)
//                     }
//                 </Stack>
//                 <br />
//                 <TextField
//                   onChange={(e) => { setUTUsername(e.target.value)}}
//                     fullWidth
//                     id="username"
//                     label="+60"
//                 />
//                 <br />
//                 <TextField
//                    onChange={(e) => { setUTPassword(e.target.value)}}
//                     fullWidth
//                     id="password"
//                     label="Password"
//                 />
//                 <br />
//                 <Button fullWidth variant="outlined" style={{ flex: 1, color: merchant?.FooterActiveButtonColor, borderColor: merchant?.FooterActiveButtonColor, }} onClick={() => postTransfer()}>Transfer</Button>
//                 <br />
//                 <IconButton style={{ color: merchant?.FooterActiveButtonColor, borderColor: merchant?.FooterActiveButtonColor, }} onClick={() => { setCouponTransferMode(false) }} aria-label="cancel">
//                     <CloseIcon />
//                 </IconButton>
//             </Stack>
//         </Box>
//     }
//     const CouponDetails = () => {
//         if (selectedCoupon)
//             return <Box
//                 sx={{ padding: 2 }}
//                 role="presentation"
//             >
//                 <Stack style={{ alignItems: "center" }}>
//                     <Typography variant='h4' sx={{ textAlign: "center" }}>
//                         {selectedCoupon?.coupon?.name}
//                     </Typography>
//                     <Typography variant='caption' sx={{ textAlign: "center" }}>
//                         {selectedCoupon?.coupon?.validUntil ? `Expiry : ${moment(selectedCoupon?.coupon?.validUntil ?? null).utc(true).local().toString()}` : ""}
//                     </Typography>
//                     <Stack sx={{ mt: 4, alignItems: "center" }}>
//                         <QRCode value={selectedCoupon?.coupon?.id.toString()} size={150} />
//                         <small>{selectedCoupon?.coupon?.id.toString()}</small>
//                     </Stack>
//                     <Stack direction="row" sx={{ mt: 4, width: "100%", "justifyContent": "center" }}>
//                         <Button variant="outlined" style={{ flex: 1, color: merchant?.FooterActiveButtonColor, borderColor: merchant?.FooterActiveButtonColor, }} onClick={() => setCouponTransferMode(true)}>Transfer</Button>
//                         &nbsp;&nbsp;&nbsp;&nbsp;
//                         <Button variant="outlined" style={{ flex: 1, color: merchant?.FooterActiveButtonColor, borderColor: merchant?.FooterActiveButtonColor, }} onClick={() => setCouponReedemMode(true)}>Redeem</Button>
//                     </Stack>
//                     {
//                         selectedCoupon?.coupon?.description && selectedCoupon?.coupon?.description != '<p><br></p>' ? <Paper elevation={0} sx={{ mt: 4, borderTopLeftRadius: 20, borderTopRightRadius: 20, overflow: "hidden" }}>
//                             {/* <Typography variant="h6">Description</Typography> */}
//                             <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: (selectedCoupon?.coupon?.description || "").replace(/<p><br><\/p>/g, '') }} sx={{ overflow: "hidden" }} />
//                         </Paper> : <></>
//                     }
//                     {
//                         selectedCoupon?.coupon?.terms && selectedCoupon?.coupon?.terms != '<p><br></p>' ? <Paper elevation={0} sx={{ mt: 4, borderTopLeftRadius: 20, borderTopRightRadius: 20, overflow: "hidden" }}>
//                             {/* <Typography variant="h6">Description</Typography> */}
//                             <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: (selectedCoupon?.coupon?.terms || "").replace(/<p><br><\/p>/g, '') }} sx={{ overflow: "hidden" }} />
//                         </Paper> : <></>
//                     }
//                 </Stack>
//             </Box>
//         else
//             return <></>
//     }
//     return (
//         isAuthenticated ? <Box sx={{ width: '100%', p: 2 }}>
//             {memberCouponMap && memberCouponMap.length ? <StyledTabs
//                 value={value}
//                 onChange={handleChange}
//                 aria-label=""
//                 TabIndicatorProps={{ style: { background: merchant?.HeaderColorCode } }}
//                 sx={{
//                     "&.Mui-selected": {
//                         background: merchant?.HeaderColorCode || "initial",
//                         color: "white"
//                     }
//                 }}
//             >
//                 {tabs && tabs.map((t) => {
//                     return <StyledTab sx={{
//                         "&.Mui-selected": {
//                             background: merchant?.HeaderColorCode || "initial",
//                             color: "white"
//                         }
//                     }}
//                         disableRipple label={t} id={t} aria-controls="map" />
//                 })}
//             </StyledTabs> : <></>}
//             <TabPanel fullWidth value={1} index={1} style={{ textAlign: "center" }}>
//                 {memberCouponMap && memberCouponMap.length ? memberCouponMap.filter((n) => {
//                     if (n.coupon) {
//                         const isValidFromToday = moment(n?.validFrom).isSameOrAfter(moment(new Date()), 'date');
//                         const isExpiredBeforeToday = moment(n?.validTill).isBefore(moment(new Date()), 'date');
//                         const isNtRedeemed = !n?.redeemed;
//                         if (value == 0) { //active
//                             return n?.validFrom && n?.validTill && isValidFromToday && !isExpiredBeforeToday && isNtRedeemed;
//                         } else if (value == 1) { //expired
//                             return isExpiredBeforeToday && isNtRedeemed;
//                         } else if (value == 2) { // redeemed
//                             return !isNtRedeemed;
//                         }
//                         return false
//                     } else {
//                         return false
//                     }
//                 }).map((n: CouponMapping) => {
//                     if (n?.coupon?.format == "image") {
//                         return <img onClick={() => setSelectedCoupon(n)} src={n.coupon.image} style={{ marginTop: "8px", width: "100%", height: "auto" }} />
//                     }
//                     return <Coupon mcoupon={n}></Coupon>
//                 }) :
//                     <Typography sx={{ textAlign: "center" }}>Sorry! No Coupons for you at this moment.</Typography>
//                 }
//             </TabPanel>
//             {selectedCoupon && <Drawer
//                 variant={'temporary'}
//                 open={!!selectedCoupon}
//                 onClose={() => setSelectedCoupon(undefined)}
//                 anchor='bottom'
//                 PaperProps={{
//                     sx: {
//                         borderRadius: '16px 16px 0 0', // Rounded corners at the top
//                         height: '75vh', // Adjust height as needed
//                     },
//                 }}
//             >
//                 {couponTransferMode ? TransferCoupon() : (couponReedemMode ? ReedemCoupon() : CouponDetails())}
//             </Drawer>}
//         </Box > :
//             <Stack sx={{ width: "100%", height: "80vh", alignItems: "center", justifyContent: "center" }}>
//                 <Typography variant="body1">Login to check your coupons</Typography>
//                 <br />
//                 <Button variant="text" onClick={() => navigate(`/${merchant?.id}/membersignin`, { state: { from: location.pathname } })}>Login</Button>
//             </Stack>
//     );
// };

// export default MobileCouponsView;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  TablePagination,
  Stack,
  Typography,
  Card,
  Box,
  OutlinedInput,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search,
} from "@mui/icons-material";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { deleteData, get, post, put } from "../../services/api.service";

const MobileCouponsView: React.FC = () => {
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [AddDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [EditDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [AddCategoryDialogVisible, setAddCategoryDialogVisible] =
    useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [branchData, setBranchData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [selectedId, setSelectedId] = useState("");
  const [price, setPrice] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [redeemPoints, setRedeemPoints] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);

  const openAddNewService = () => {
    setAddDialogVisible(true);
    resetFields();
  };

  const confirmEdit = (rowData: any) => {
    setSelectedId(rowData.id);
    setEditDialogVisible(true);
    getDataByServiceID(rowData.id);
    setIsEdit(true);
  };

  const confirmDelete = (rowData: any) => {
    setSelectedId(rowData.id);
    setDeleteDialogVisible(true);
  };

  const onDeleteCancel = () => {
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setDeleteDialogVisible(false);
    setIsEdit(false);
  };

  const categorybox = [
    {
      name: "This Product is redeemable by reward points",
      key: "Reward Points",
    },
  ];

  const [selectedCategoryBox, setSelectedCategoryBox] = useState([
    categorybox[0],
  ]);

  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let _selectedCategoryBox = [...selectedCategoryBox];
    if (e.target.checked) {
      _selectedCategoryBox.push(categorybox[0]);
    } else {
      _selectedCategoryBox = _selectedCategoryBox.filter(
        (category) => category.key !== categorybox[0].key
      );
    }
    setSelectedCategoryBox(_selectedCategoryBox);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          style={{
            color: "#264164",
          }}
        >
          <VisibilityIcon style={{ height: "21px", width: "21px" }} />
        </IconButton>

        <IconButton onClick={() => confirmEdit(rowData)}>
          <EditIcon color="primary" style={{ height: "21px", width: "21px" }} />
        </IconButton>
        <IconButton onClick={() => confirmDelete(rowData)}>
          <DeleteIcon color="error" style={{ height: "21px", width: "21px" }} />
        </IconButton>
      </div>
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isRewardPointsSelected = selectedCategoryBox.some(
    (category) => category.key === "Reward Points"
  );

  const openAddNewCategory = () => {
    setAddCategoryDialogVisible(true);
  };

  const onDeleteCategory = () => {
    setAddCategoryDialogVisible(false);
  };

  const resetFields = () => {
    setServiceName("");
    setSelectedBranchId("");
    setSelectedCategoryId("");
    setRedeemPoints("");
    setSelectedCategory("");
    setSelectedBranch("");
    setPrice("");
  };

  async function getDataByServiceID(id: any) {
    try {
      let url = `/service/${id}`;
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          let service = response.data.data;
          setServiceName(service.name);
          setSelectedBranch(service.branchname);
          setSelectedBranchId(service.branch_id);
          setSelectedCategory(service.categoryname);
          setSelectedCategoryId(service.category_id);
          setPrice(service.price.toString());
          setRedeemPoints(service.redeem_points.toString());
          if (service.redeem_points) {
            setSelectedCategoryBox([categorybox[0]]);
          }
          setSelectedBranch(response.data.data.branch_id);
          setSelectedCategory(response.data.data.category_id);
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function getAllService() {
    try {
      let url = "/service/getall";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setServiceData(response.data.data);
        } else {
          console.log("Error in API call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getBranchList() {
    try {
      let url = "/branch/dropdown";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setBranchData(response.data.data);
        } else {
          console.log("Error in API call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function addCategory() {
    try {
      let url = "/subcategory/Service";
      let body = {
        name: categoryName,
        isActive: true,
      };
      const response = await post(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setAddCategoryDialogVisible(false);
          getAllCategory();
        } else {
          console.log("Error in API call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllCategory() {
    try {
      let url = "/category/type/Service";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setCategoryData(response.data.data);
          console.log(response.data.data, "response");
        } else {
          console.log("Error in API call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteService() {
    try {
      let url = `/service/${selectedId}`;
      const response = await deleteData(url);
      if (response && response.data) {
        if (response.data.success) {
          setDeleteDialogVisible(false);
          getAllService();
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function addService() {
    try {
      let url = "/service";
      let body = {
        name: serviceName,
        description: "High-quality shampoo for all hair types.",
        price: parseInt(price),
        category_id: selectedCategory,
        redeem_points: redeemPoints,
        branch_id: selectedBranch,
        Serviceskubarcode: "SKUBARCODE123",
        servicebrand: "Brand A",
        servicegroup: "Group A",
        servicecolorgroup: "Color Group A",
        servicegroupsize: "Size Group A",
        servicetax: "5.00",
        servicevarientgroup: "Variant Group A",
        servicetextragroup: "Extra Group A",
        serviceuplinerewardgroup: "Reward Group A",
        servicesize: "Medium",
        serviceweight: "500g",
        servicetype: "Type A",
        servicenormal: true,
        serviceofferprice: "45.00",
        servicecosting: "40.00",
        isreward: true,
      };

      const response = await post(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setAddDialogVisible(false);
          getAllService();
        } else {
          console.log("Error in API call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateService() {
    try {
      let url = `/service/${selectedId}`;
      let body = {
        name: serviceName,
        description: "High-quality shampoo for all hair types.",
        price: parseInt(price),
        stock: 100,
        redeem_points: redeemPoints,
        branch_id: selectedBranch,
        category_id: selectedCategory,
      };
      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setEditDialogVisible(false);
          getAllService();
        } else {
          console.log("Error in API call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllService();
    getAllCategory();
    getBranchList();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        serviceData.filter((item: any) => {
          return (
            (item?.categoryname &&
              item?.categoryname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(serviceData);
    }
  }, [searchQuery, serviceData]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Services</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Messages"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <Paper>
        <Box sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Branch
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Redeem Points
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                    className="flex justify-content-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={openAddNewService}
                    >
                      Add Service
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((service: any, index: any) => (
                  <TableRow key={service.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{service.name}</TableCell>
                    <TableCell>{service.price}</TableCell>
                    <TableCell>{service.categoryname}</TableCell>
                    <TableCell>{service.branchname}</TableCell>
                    <TableCell>{service.redeem_points}</TableCell>
                    <TableCell>{actionBodyTemplate(service)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      {/* Add and Edit Dialog */}
      <Dialog
        open={!isEdit ? AddDialogVisible : EditDialogVisible}
        onClose={onDeleteCancel}
      >
        <DialogTitle>
          {!isEdit ? "Add Service" : "Update Existing Service"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Service Name"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            type="number"
          />

          <div className="flex  align-items-center  justify-content-center">
            <TextField
              select
              label="Category"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value as string);
              }}
              fullWidth
              margin="normal"
              style={{ width: "547px" }}
              InputProps={{
                style: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            >
              {categoryData.map((category: any) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              aria-label="add category"
              onClick={openAddNewCategory}
              style={{
                marginLeft: 8,
                backgroundColor: "#cc0400",
                borderRadius: "5px",
              }}
            >
              <AddIcon style={{ color: "#FFF" }} />
            </IconButton>
          </div>
          <TextField
            select
            fullWidth
            margin="normal"
            label="Branch"
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            {branchData.map((branch: any) => (
              <MenuItem key={branch.id} value={branch.id}>
                {branch.name}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRewardPointsSelected}
                onChange={onCategoryChange}
              />
            }
            label={categorybox[0].name}
          />
          {isRewardPointsSelected && (
            <TextField
              fullWidth
              margin="normal"
              label="Redeem Points"
              value={redeemPoints}
              type="number"
              onChange={(e) => setRedeemPoints(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={!isEdit ? addService : updateService}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button onClick={onDeleteCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new category */}
      <Dialog
        open={AddCategoryDialogVisible}
        onClose={onDeleteCategory}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: { padding: "10px" },
        }}
      >
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: "10px" }}>
            <TextField
              label="Category Name"
              placeholder="Add Category Name"
              fullWidth
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              variant="outlined"
              style={{ marginTop: "10px" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={addCategory} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onDeleteCategory} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onDeleteCancel}
        onConfirm={deleteService}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete service"}
        title={"Confirm Delete"}
      />
    </Stack>
  );
};
export default MobileCouponsView;
