import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { UserPlus as UserPlus } from '@phosphor-icons/react';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import { Tabs as TabsIcon } from '@phosphor-icons/react/dist/ssr/Tabs';
import { CONSTANTS } from '../../constants';
import { useNavigate } from 'react-router-dom';
import axiosService from '../../services/axios';
import { Box, Card, OutlinedInput, InputAdornment, Drawer } from '@mui/material';
import { useAlert } from '../../components/CommonAlert';
import NoticeBoardInviteDialog from '../../components/ConfirmationDialog';
import { useAuth } from '../../context/AuthContext';
import { RecordTypes, Roles } from 'src/types/enum';
import { NoticeBoard } from 'src/intefaces/notice-board.inteface';
import { NoticeBoardsTable } from './noticeBoard-table';
import { useState } from 'react';
import NoticeTabs from './notice-tabs';
export default function NoticeBoardsPage(): React.JSX.Element {
    const page = 0;
    const rowsPerPage = 25;
    const [noticeBoards, setNoticeBoards] = React.useState<NoticeBoard[]>([]);
    const [openInvite, setOpenInvite] = React.useState<boolean>(false);
    const [filteredData, setFilteredData] = React.useState<NoticeBoard[]>([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [inviteLInk, setInviteLink] = React.useState("");
    const { user } = useAuth();
    
  
    const { showAlert } = useAlert();

    const getNoticeBoards = async () => {
        try {
            console.log("called");
            const response = await axiosService.get<NoticeBoard[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.noticeBoard]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get noticeBoards', 'error');
                setNoticeBoards([])
                setFilteredData([])
            } else {
                const json = response.data;
                setNoticeBoards(json);
                setFilteredData(json)
            }
        } catch (ex: any) {
            setNoticeBoards([])
            setFilteredData([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get noticeBoards', 'error');
            console.error("Exception Caught", ex)
        }
    }
    React.useEffect(() => {
        getNoticeBoards()
    }, [])
    const onCopy = () => {
        navigator.clipboard.writeText(inviteLInk).then(() => {
            setOpenInvite(false)
        }).catch(err => {
            setOpenInvite(false)
            showAlert('Failed to copy!', 'error');
            console.log("failed to copy", err);
        });

    }
    React.useEffect(() => {
        setFilteredData(noticeBoards.filter((item: NoticeBoard) => {
        
            return (item?.dateFrom && item?.dateFrom.toString().includes(searchQuery.toLowerCase())) ||
                (item?.expiry && item?.expiry.toString().includes(searchQuery.toLowerCase())) ||
                (item?.title && item?.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.description && item?.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.imageLink && item?.imageLink.toLowerCase().includes(searchQuery.toLowerCase()))
        }));
    }, [searchQuery])
    const onDeleteCallback = () => getNoticeBoards();
    const navigate = useNavigate();
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">NoticeBoards</Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        {/* <Button color="inherit" startIcon={<UploadIcon fontSize="var(--icon-fontSize-md)" />}>
              Quick Actions
            </Button> */}

                    </Stack>
                </Stack>
                <div>
                    <Button
                        startIcon={<TabsIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/noticeTabs')}>
                        Tabs
                    </Button>
                    <Button
                        startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/noticeBoards/add')}
                    >
                        Add
                    </Button>
                </div>
            </Stack>
            <Box>
                <Card sx={{ p: 2 }}>
                    <OutlinedInput
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        placeholder="Search noticeBoard"
                        startAdornment={
                            <InputAdornment position="start">
                                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
                            </InputAdornment>
                        }
                        sx={{ maxWidth: '500px' }}
                    />
                </Card>
            </Box>
            <NoticeBoardsTable
                count={filteredData?.length ?? 0}
                page={page}
                rows={filteredData}
                rowsPerPage={rowsPerPage}
                onDeleteCallback={onDeleteCallback}
            />
            <NoticeBoardInviteDialog
                open={openInvite && !!inviteLInk}
                onClose={() => { setOpenInvite(false); }}
                onConfirm={onCopy}
                confirmLabel={"Copy Link"}
                cancelLabel={"Cancel"}
                message={`Copy and share the link :  ${inviteLInk}`}
                title={"Invite NoticeBoard"}
            />
        </Stack>

    );
}
