export const enum RecordActions {
    add = "add",
    edit = "edit",
    view = "view"
}
export const enum RecordTypes {
    merchant = "merchant",
    outlet = "outlet",
    partner = "partner",
    staff = "staff",
    member = "member",
    noticeBoard = "noticeBoard",
    noticeTabs = "noticeTabs",
    bottomButtons = "bottomButtons",
    messages = "messages",
    messageForm = "messageForm",
    messageCategory = "messageCategory",
    coupon ="coupon",
    couponMapping = "couponMapping"
}
export const enum Roles {
    superadmin = "superadmin",
    merchant = "merchant",
    outlet = "outlet",
    partner = "partner",
    staff = "staff",
    member = "member"
}
export const enum DataType {
    string = "string",
    date = "date",
    number = "number",
    datetime = "datetime",
    file = "file"
}
export const enum ZoomMode {
    Fullpage = "Fullpage",
    Ascend = "Ascend"
}
export const enum NoticeLayout {
    FullDetails = "FullDetails",
    ImageSummaries = "ImageSummaries",
    BarSummaries = "BarSummaries"
}
export const enum BottomButtonType {
    SELFDEFINE = "SelfDefine",
    SELFSUBBUTTON = "SubButton",
    STANDARD = "Standard",

}
export const enum MenuViewPanel {
    LEFTNAV = "left-nav",
    PROFILE = "profile-panel"
}