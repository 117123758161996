import { useParams } from "react-router-dom";
import React from 'react';

import { Button, Card, CardActions, CardContent, FormControlLabel, Grid, Switch } from "@mui/material";
import { Controller, useFormContext } from 'react-hook-form';
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";
export const RewardFunction = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardCoupon"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardCoupon" />
                            }
                            label="Coupon"
                        />
                    } />
            </Grid>

            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardeWallet"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardeWallet" />
                            }
                            label="eWallet"
                        />
                    } />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardVIPCard"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardVIPCard" />
                            }
                            label="VIPCard"
                        />
                    } />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardChopCard"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardChopCard" />
                            }
                            label="ChopCard"
                        />
                    } />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardUnlimited"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardUnlimited" />
                            }
                            label="Unlimited"
                        />
                    } />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardRedemption"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardRedemption" />
                            }
                            label="Redemption"
                        />
                    } />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardAngpao"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardAngpao" />
                            }
                            label="Angpao"
                        />
                    } />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardSpinWin"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardSpinWin" />
                            }
                            label="SpinWin"
                        />
                    } />
            </Grid> <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMembershipRewardReferral"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionMembershipRewardReferral" />
                            }
                            label="Referral"
                        />
                    } />

            </Grid>
        </Grid>)
}