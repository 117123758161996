import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TablePagination,
  Box,
  FormControl,
  InputLabel,
  Paper,
  InputAdornment,
  Grid,
  Stack,
  Typography,
  Card,
  OutlinedInput,
} from "@mui/material";
import {
  Add,
  Print,
  Delete,
  Edit,
  ArrowUpward,
  ArrowDownward,
  Undo,
  Search,
  Visibility,
} from "@mui/icons-material";
import { deleteData, get, post, put } from "../../services/api.service";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";

const InventoryPage: React.FC = () => {
  const rowsPerPageOptions = [10, 25, 50, 100];

  const [selectedBranch, setSelectedBranch] = useState<string>("Abc Salon");
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const [selectedProductId, setSelectedProductId] = useState("");
  const [productsData, setProductsData] = useState<any[]>([]);

  const [stockName, setStockName] = useState("");
  const [stockCount, setStockCount] = useState("");
  const [price, setPrice] = useState("");
  const [remarks, setRemarks] = useState("");
  const [stockData, setStockData] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [branchData, setBranchData] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [stockpurchaseDialogVisible, setStockpurchaseDialogVisible] =
    useState<boolean>(false);
  const [stocklossDialogVisible, setStocklossDialogVisible] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);

  function resetFields() {
    setStockName("");
    setSelectedBranch("");
    setStockCount("");
    setRemarks("");
    setSelectedBranchId("");
    setSelectedProductId("");
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openAddNewStock = () => {
    setAddDialogVisible(true);
    resetFields();
  };

  const confirmDelete = (rowData: any) => {
    setSelectedId(rowData.id);
    setDeleteDialogVisible(true);
  };

  const confirmEdit = (rowData: any) => {
    setSelectedRowData(rowData);
    setEditDialogVisible(true);
    getStockById(rowData.id);
    setSelectedId(rowData.id);
    setIsEdit(true);
  };

  const onCancel = () => {
    setDeleteDialogVisible(false);
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setStockpurchaseDialogVisible(false);
    setStocklossDialogVisible(false);
    setIsEdit(false);
  };

  const confirmStockPurchase = (rowData: any) => {
    setSelectedId(rowData.id);
    setSelectedRowData(rowData);
    setStockpurchaseDialogVisible(true);
  };

  const confirmStockLoss = (rowData: any) => {
    setSelectedId(rowData.id);
    setSelectedRowData(rowData);
    setStocklossDialogVisible(true);
  };

  async function getProduct() {
    try {
      const url = "/product/dropdown";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          console.log(response.data.data, " response");

          setProductsData(response.data.data);
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function addNewStock() {
    try {
      const url = "/stock";

      const body = {
        name: stockName,
        stockcount: parseInt(stockCount),
        remarks: remarks,
        isproductlinked: checked,
        productid: selectedProductId,
        merchant_id: "",
        outlet_id: "",
      };

      const response = await post(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setAddDialogVisible(false);
          getAllStock();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllStock() {
    try {
      const url = "/stock/getall";
      const response = await get(url);
      if (response?.data?.success) {
        setStockData(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // async function getBranchList() {
  //   try {
  //     let url = "/branch/dropdown";

  //     const response = await get(url);
  //     if (response && response.data) {
  //       if (response.data.success) {
  //         setBranchData(response.data.data);
  //       } else {
  //         console.log("Error in Api call, ", response.data.message);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async function deleteStock() {
    try {
      const url = `/stock/delete/${selectedId}`;
      const response = await deleteData(url);
      if (response?.data?.success) {
        setDeleteDialogVisible(false);
        getAllStock();
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting multi-service: ", error);
    }
  }

  async function editStock() {
    try {
      const url = `/stock/${selectedId}`;

      const body = {
        name: stockName,
        stockcount: parseInt(stockCount),
        remarks: remarks,
        isproductlinked: checked,
        productid: checked ? selectedProductId : "",
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setEditDialogVisible(false);
          getAllStock();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function stockPurchase() {
    try {
      const url = `/stock/stockpurchase/${selectedId}`;

      const body = {
        stockcount: parseInt(stockCount),
        remarks: remarks,
        price: parseInt(price),
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setStockpurchaseDialogVisible(false);
          getAllStock();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function stockLoss() {
    try {
      const url = `/stock/stockloss/${selectedId}`;

      const body = {
        stockcount: parseInt(stockCount),
        remarks: remarks,
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setStocklossDialogVisible(false);
          getAllStock();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getStockById(id: string) {
    try {
      const url = `/stock/${id}`;
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          const stockData = response.data.data;
          setStockName(stockData.name || "");
          setStockCount(
            stockData.stockcount ? stockData.stockcount.toString() : ""
          );
          setRemarks(stockData.remarks || "");
          setChecked(true);
          setSelectedProductId(stockData.productid || "");
          setSelectedRowData(stockData);
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAllStock();
    // getBranchList();
    getProduct();
  }, []);

  const actionBodyTemplate = (rowData: any) => (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <IconButton color="primary" onClick={() => confirmStockPurchase(rowData)}>
        <ArrowUpward />
      </IconButton>
      <IconButton color="default" onClick={() => confirmStockLoss(rowData)}>
        <ArrowDownward />
      </IconButton>
      <IconButton color="secondary">
        <Visibility />
      </IconButton>
      <IconButton color="primary" onClick={() => confirmEdit(rowData)}>
        <Edit />
      </IconButton>
      <IconButton color="error" onClick={() => confirmDelete(rowData)}>
        <Delete />
      </IconButton>
    </div>
  );

  const actionHeaderTemplate = () => (
    <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        onClick={openAddNewStock}
      >
        Add New
      </Button>
      <Button startIcon={<Print />} variant="contained" color="primary">
        Print Checklist
      </Button>
      {/* <Button variant="contained" color="success">
        Export
      </Button> */}
    </div>
  );

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Inventory</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search stock"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <Paper>
        <Box sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#E0E0E0" }}>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    Stock Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    Linked Product
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    Stock Left
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    Remark
                  </TableCell>
                  <TableCell align="right">{actionHeaderTemplate()}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{index + 1}</TableCell>

                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.productname}</TableCell>
                      <TableCell>{row.stockcount}</TableCell>
                      <TableCell>{row.remarks}</TableCell>

                      <TableCell>{actionBodyTemplate(row)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <span>
          {`Showing ${startRow} to ${endRow} of ${stockData.length} entries`}
        </span>
        <TablePagination
          component="div"
          count={stockData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          labelRowsPerPage={""}
        />
      </div> */}

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onCancel}
        onConfirm={deleteStock}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete Stock"}
        title={"Confirm Delete"}
      />

      {/* Add && Edit New Stock Dialog */}
      <Dialog
        open={!isEdit ? addDialogVisible : editDialogVisible}
        onClose={onCancel}
      >
        <DialogTitle>
          {!isEdit ? "Add New Stock" : "Update Existing Stock"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Stock Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={stockName}
            onChange={(e) => setStockName(e.target.value)}
          />
          <TextField
            label="Current Stock Count"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={stockCount}
            onChange={(e) => setStockCount(e.target.value)}
          />
          <TextField
            label="Remark"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label="Link this stock to product."
          />
          {checked && (
            <TextField
              select
              label="Select Product"
              value={selectedProductId}
              onChange={(e) => setSelectedProductId(e.target.value as string)}
              fullWidth
              margin="normal"
              style={{ width: "547px" }}
              InputProps={{
                style: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            >
              {productsData.map((product: any) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={!isEdit ? addNewStock : editStock}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Stock Purchase Dialog */}
      <Dialog
        open={stockpurchaseDialogVisible}
        onClose={onCancel}
        PaperProps={{
          sx: {
            width: "40vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle
          sx={{
            paddingBottom: 0,
          }}
        >
          <Grid item xs={12}>
            <label className="font-bold">Stock Purchase</label>
          </Grid>
          <div className="grid col-12">
            <label className="text-base">Branch: {selectedBranch}</label>
          </div>
        </DialogTitle>
        <DialogContent>
          {/* <Grid item xs={12}>
            <label className="font-bold">Stock Loss</label>
          </Grid>
          <div className="grid col-12">
            <label className="mt-2">Branch: {selectedBranch}</label>
          </div> */}
          <TextField
            label="Total item count for this batch of stock"
            value={stockCount}
            onChange={(e) => setStockCount(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Total cost for this batch of stock(Not unit cost)"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            id="outlined-start-adornment"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">RM</InputAdornment>
              ),
            }}
          />
          <TextField
            label="Remark"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Update Remark"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={stockPurchase} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Stock Loss Dialog */}
      <Dialog
        open={stocklossDialogVisible}
        onClose={onCancel}
        PaperProps={{
          sx: {
            width: "40vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle
          sx={{
            paddingBottom: 0,
          }}
        >
          <Grid item xs={12}>
            <label className="font-bold">Stock Less</label>
          </Grid>
          <div className="grid col-12">
            <label className="text-base">Branch: {selectedBranch}</label>
          </div>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Amount of stock loss"
            value={stockCount}
            onChange={(e) => setStockCount(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Remark"
            placeholder="Update Remark"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={stockLoss} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default InventoryPage;
