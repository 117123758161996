import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Stack,
  Typography,
  Box,
  Card,
  OutlinedInput,
  InputAdornment,
  Paper,
} from "@mui/material";
import { Delete, Add, Search } from "@mui/icons-material";
import { deleteData, get, post, put } from "../../services/api.service";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { useAuth } from "src/context/AuthContext";
import { Merchant } from "src/types/merchant-interface";
import axiosService from "src/services/axios";
import { RecordTypes } from "src/types/enum";
import { CONSTANTS } from "src/constants";
const mainCategories = [
  { name: "Service", id: "service" },
  { name: "Product", id: "product" },
  { name: "Multi-Service Package", id: "multiservice" },
  { name: "Unlimited Plans", id: "unlimitedplan" },
];

const CategoryPage: React.FC = () => {
  const { user } = useAuth();
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [categoryDatalist, setCategoryDatalist] = useState<any[]>([]);
  const [selectedCategoryType, setSelectedCategoryType] = useState<any>("");
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [categoryName, setCategoryName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const [categoryNameError, setCategoryNameError] = useState("");
  const [typeError, setTypeError] = useState("");

  const startRow = page * rowsPerPage + 1;

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton color="error" onClick={() => confirmDelete(rowData)}>
          <Delete />
        </IconButton>
      </div>
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const confirmDelete = (rowData: any) => {
    setDeleteDialogVisible(true);
    setSelectedId(rowData.id);
  };

  const onDeleteCancel = () => {
    setAddDialogVisible(false);
    setDeleteDialogVisible(false);
  };

  const resetFields = () => {
    setCategoryName("");
    setSelectedCategoryType("");
    setTypeError("");
    setCategoryNameError("");
  };

  const openAddNewCategory = () => {
    setAddDialogVisible(true);
    resetFields();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function addCategory() {
    try {
      let url = "/category";
      let types = "";
      if (selectedCategoryType === "product") {
        types = "Product";
      } else if (selectedCategoryType === "service") {
        types = "Service";
      } else if (selectedCategoryType === "multiservice") {
        types = "Multiservice";
      } else if (selectedCategoryType === "unlimitedplan") {
        types = "Plan";
      }

      let body: any = {
        name: categoryName,
        type: types,
        // outlet_id: selectedBranch,
        outlet_id: "",
      };

      const response = await post(url, body);

      if (response && response.data) {
        if (response.data.success) {
          setAddDialogVisible(false);
          getAllCategoryList();
        } else {
          console.log("Error in Api call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllCategoryList() {
    try {
      let url = "/category/list";

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCategoryDatalist(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteCategory() {
    try {
      let url = `/category/delete/${selectedId}`;

      const response = await deleteData(url);

      if (response && response.data) {
        if (response.data.success) {
          setDeleteDialogVisible(false);
          getAllCategoryList();
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([
          { MerchantName: "--Select Outlet--" } as Merchant,
          ...json,
        ]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };

  const validateFields = () => {
    let valid = true;

    if (!categoryName.trim()) {
      setCategoryNameError("Category Name is required");
      valid = false;
    } else {
      setCategoryNameError("");
    }

    if (!selectedCategoryType) {
      setTypeError("Type is required");
      valid = false;
    } else {
      setTypeError("");
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      addCategory();
    }
  };

  useEffect(() => {
    getAllCategoryList();
    getOutlets();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        categoryDatalist.filter((item: any) => {
          return (
            (item?.categoryname &&
              item?.categoryname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(categoryDatalist);
    }
  }, [searchQuery, categoryDatalist]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Categories</Typography>
        </Stack>
      </Stack>
      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Messages"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>
      <Paper>
        <Box sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#e9e9e9" }}>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Type
                  </TableCell>
                  {user?.role === "merchant" && (
                    <TableCell
                      sx={{
                        fontSize: "medium",
                        fontWeight: "bold",
                        backgroundColor: "#e9e9e9",
                      }}
                      className="flex justify-content-end"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={openAddNewCategory}
                      >
                        Add New
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <TableRow key={row.id}>
                      <TableCell>{startRow + index}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      {user?.role === "merchant" && (
                        <TableCell>{actionBodyTemplate(row)}</TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onDeleteCancel}
        onConfirm={deleteCategory}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete category"}
        title={"Confirm Delete"}
      />

      {/* Add Dialog */}
      <Dialog open={addDialogVisible} onClose={onDeleteCancel}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent className="pt-0 pb-0">
          <TextField
            label="Category Name"
            value={categoryName}
            onChange={(e) => {
              setCategoryName(e.target.value);
              if (e.target.value) {
                setCategoryNameError("");
              }
            }}
            fullWidth
            margin="normal"
            error={!!categoryNameError}
            helperText={categoryNameError}
          />
          {/* <TextField
            select
            fullWidth
            margin="normal"
            label="Outlets"
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            {outlets.map((branch: any) => (
              <MenuItem key={branch.id} value={branch.id}>
                {branch.MerchantName}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            select
            label="Type"
            value={selectedCategoryType}
            onChange={(e) => {
              setSelectedCategoryType(e.target.value as string);
              if (e.target.value) {
                setTypeError("");
              }
            }}
            fullWidth
            margin="normal"
            style={{ width: "547px" }}
            InputProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            error={!!typeError}
            helperText={typeError}
          >
            {mainCategories.map((cate) => (
              <MenuItem key={cate.id} value={cate.id}>
                {cate.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onDeleteCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default CategoryPage;
