import * as React from 'react';
export default function Overview(): React.JSX.Element {
  return (
    <h1><center>Welcome</center></h1>
    // <Grid container>
      
  
    //   <Grid lg={6} sm={6} xs={12}>
    //     <TotalMerchants diff={16} trend="down" sx={{ height: '100%' }} value="1.6k" />
    //   </Grid>
      
    //   <Grid lg={6} sm={6} xs={12}>
    //     <TotalProfit sx={{ height: '100%' }} value="$15k" />
    //   </Grid>

    
    // </Grid>
  );
}
