import styled from "@emotion/styled";
import { Box, Stack, Avatar, Typography } from "@mui/material";
import { Merchant } from "src/types/merchant-interface";

interface HeaderImageProps {
    merchant: Merchant
}

const ImageWrapper = styled('div')<{ src: string }>(({ src }) => ({
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '50vh',
    width: '100%',
}));

export const HeaderImage: React.FC<HeaderImageProps> = ({ merchant }) => {
    return (
        <Box sx={{ position: "relative", }}>
            {merchant.MerchantCover && <Box sx={{ minHeight: 90 }}>
                <img src={merchant.MerchantCover} style={{ width: "100%", height: "auto" }} />
            </Box>}
            <Stack sx={merchant.MerchantCover ? { position: "absolute", bottom:"-40px", left: "0",p:2, } : { p: 2, pb: 0 }} direction="row">
                <Avatar
                    src={merchant.MerchantLogo} // URL of the uploaded image
                    alt="Logo"
                    variant="rounded"
                    sx={{
                        objectFit: 'contain',
                        width: 100,
                        height: 100
                    }}
                />
                <Stack sx={{ color: merchant.MerchantCover ? "white" : "auto", pl: "0.5em",pt:"1em" }}>
                    <Typography variant='subtitle2' sx={{fontWeight:"bold"}}>{merchant.MerchantName}</Typography>
                    <Typography variant="caption">{merchant.MerchantSubTitle}</Typography>
                </Stack>
            </Stack>
        </Box>
    );
};