import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { useNavigate, useParams } from 'react-router-dom';
import { MerchantFormWrapper } from './merchant-form/form-wrapper';
import { RecordActions, RecordTypes } from '../../types/enum';

export default function MerchantAddForm({ recordType }: { recordType: "outlet" | "merchant" | "partner" }): React.JSX.Element {
  const { action, id } = useParams();
  const navigate = useNavigate();
  const addRecord = () => {
    if (recordType == RecordTypes.merchant) {
      navigate(`/merchants/add`)
    } else {
      navigate(`/${recordType}/add`)
    }
  }
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>{recordType} Details</Typography>

        </Stack>
        <div>
          {action != RecordActions.add && <Button
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={addRecord}>
            Add
          </Button>}
          <Button onClick={() => { navigate(`/${recordType == RecordTypes.merchant ? "merchants" : recordType}`) }} startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />} variant="text" >
            View All
          </Button>
        </div>
      </Stack>
      <MerchantFormWrapper action={action} id={id} recordType={recordType} />
    </Stack>
  );
}
