import { useParams } from "react-router-dom";
import React from 'react';

import { Card, CardContent, Grid, TextField, Button, CardActions } from "@mui/material"
import { Controller, useFormContext } from 'react-hook-form';
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";

export const WhatsAppFunction = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
                <Controller
                    name="WhatsappAppkey"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Whatsapp Appkey" />
                    } />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="WhatsappAuthkey"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Whatsapp Authkey" />
                    } />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="MetaAPIKey"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="MetaAPIKey" />
                    } />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="MetaAuthKey"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="MetaAuthKey" />
                    } />
            </Grid>


        </Grid>)
}