import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useStyle } from "../../context/StyleContext";
import {
  addItem,
  clearAllItems,
  deleteItemById,
  getItems,
  updateItemById,
} from "../../libs/indexedDb";
import { Add, Clear, Delete, Label, Remove } from "@mui/icons-material";
// import html2pdf from "html2pdf.js";
import { get, post } from "../../services/api.service";

const CartPage: React.FC = () => {
  const { setChildStyle } = useStyle();
  const scrollContainerRef: any = useRef(null);
  const [mainCategory, setMainCategory] = useState([]);
  const [categoryNamesByMainCategory, setCategoryNamesByMainCategory] =
    useState([]);
  const [productByCategory, setProductByCategory] = useState([]);
  const [products, setProducts] = useState<any>([]);
  const [cartItems, setCartItems] = useState([]);
  // const [voucher] = useState<string>("");
  const [voucherValue] = useState<number>(0.0);
  const [voucherType] = useState<string>("");
  const [voucherApply] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(0.0);
  const [discount, setDiscount] = useState<number>(0.0);
  const [cashTopup, setCashTopup] = useState<number>(0.0);
  const [netAmount, setNetAmount] = useState<number>(0.0);
  const [partialAmount, setPartialAmount] = useState<number>(0.0);
  const [value, setValue] = useState<string>("0.0");
  const [price, setPrice] = useState<string>("0.0");
  const [paymentAmt, setPaymentAmt] = useState("");
  const [selectedStaff, setSelectedStaff] = useState<any>([]);
  const [serviceStaffIds, setServiceStaffIds] = useState<any>([]);

  const [currentView, setCurrentView] = useState<string>("service");
  const [currentCategoryView, setCurrentCategoryView] = useState<string>("all");

  const [checkout, setCheckout] = useState(false);
  const [visibleCheckout, setVisibleCheckout] = useState(false);
  const [visibleStaff, setVisibleStaff] = useState<boolean>(false);
  const [visibleServiceStaff, setVisibleServiceStaff] =
    useState<boolean>(false);
  const [visibleSalesStaff, setVisibleSalesStaff] = useState<boolean>(false);

  const [staffData, setStaffData] = useState<StaffItem[]>([]);
  const [itemId, setItemId] = useState("");
  const [discountDialogVisible, setDiscountDialogVisible] =
    useState<boolean>(false);

  const [discountDialogOpenItem, setDiscountDialogOpenItem] = useState<any>();
  const [selectedType, setSelectedType] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  // const [checked, setChecked] = useState<boolean>(false);

  const [printReceipt, setPrintReceipt] = useState<boolean>(false);
  const [visibleUnitPrice, setvisibleUnitPrice] = useState<boolean>(false);

  const [visibleAssistStaff, setvisibleAssistStaff] = useState<boolean>(false);

  const [cashPaidValue, setCashPaidValue] = useState("");

  const [staffGroups, setStaffGroups] = useState<any>([]);
  const [ServiceStaffGroups, setServiceStaffGroups] = useState<any>([]);

  const [salesStaffIds, setSalesStaffIds] = useState<any>([]);
  const [SalesStaffGroups, setSalesStaffGroups] = useState<any>([]);
  const [disableBtn, setDisableBtn] = useState(false);

  const [memberData, setMemberData] = useState([]);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [memberError, setMemberError] = useState("");

  const [isKIV, setIsKIV] = useState(false);

  async function getAllMember() {
    try {
      let url = "/members/dropdown";

      const response = await get(url);
      if (response && response.data) {
        if (response.status == 200) {
          setMemberData(response.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllMember();
  }, []);

  const handleSalesStaff = (items: any) => {
    const newStaff = {
      id: items.id,
      name: items.firstName,
      discount: "100",
      price: discountDialogOpenItem.price,
    };

    if (SalesStaffGroups.some((group: any) => group.id === items.id)) {
      return;
    }

    if (SalesStaffGroups.length >= 4) {
      // alert("Cannot add more than 3 staff members.");
      return;
    }

    const updatedGroups = [...SalesStaffGroups, newStaff];

    const discountPerStaff = (100 / updatedGroups.length).toFixed(2);

    const totalPrice = discountDialogOpenItem.price;
    const pricePerStaff = (totalPrice / updatedGroups.length).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map((group) => ({
      ...group,
      discount: discountPerStaff,
      price: pricePerStaff,
    }));

    setSalesStaffGroups(updatedGroupsWithDiscountAndPrice);
    setSalesStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const handleServiceStaff = (items: any) => {
    const newStaff = {
      id: items.id,
      name: items.firstName,
      discount: "100",
      price: discountDialogOpenItem.price,
    };

    if (ServiceStaffGroups.some((group: any) => group.id === items.id)) {
      return;
    }

    if (ServiceStaffGroups.length >= 4) {
      // alert("Cannot add more than 3 staff members.");
      return;
    }

    const updatedGroups = [...ServiceStaffGroups, newStaff];

    const discountPerStaff = "100.00";

    const totalPrice = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map((group) => ({
      ...group,
      discount: discountPerStaff,
      price: pricePerStaff,
    }));

    setServiceStaffGroups(updatedGroupsWithDiscountAndPrice);
    setServiceStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const handleCheckout = async () => {
    setDisableBtn(true);
    let payload = {
      products: cartItems,
      payment_method: selectedPayments,
      remarks: remark,
      subtotal: total,
      finaldiscount: discount,
      cashpaid: cashPaidValue,
      paytopup: cashTopup,
      netprice: netAmount,
      isreceipt: printReceipt,
      memberid: selectedMember.toString(),
      outlet_id: "",
    };

    let receiptPrint = printReceipt;

    let url = "/cartmain/maincartdata";

    const response = await post(url, payload);

    if (response && response.data) {
      if (response.data.success) {
        if (receiptPrint) {
          url = `/cartmanage/getpdf/${response.data.data.id}`;

          const responses = await get(url);

          if (responses && responses.data) {
            if (responses.data.success) {
              window.open(responses.data.data, "_blank");
              setDisableBtn(false);
            } else {
              setDisableBtn(false);
              console.log("Error in Api call ", responses.data.message);
            }
          } else {
            setDisableBtn(false);
            console.log("Response not found.");
          }
        }
      } else {
        setDisableBtn(false);
        console.log("Error in Api call ", response.data.message);
      }
    } else {
      setDisableBtn(false);
      console.log("Response not found.");
    }
    setDisableBtn(false);
    resetFields();
    setVisibleCheckout(false);
    clearAllItems();
    getCart();
  };

  function resetFields() {
    setRemark("");
    setSelectedPayment("Cash");
    setTotal(0.0);
    setDiscount(0.0);
    setCashTopup(0.0);
    setNetAmount(0.0);
    setCashPaidValue("");
    setPrintReceipt(false);
    setDisableBtn(false);
    setPartialAmount(0.0);
    setIsKIV(false);
  }

  const handleRemoveSalesStaffGroupChange = (data: any, id: string) => {
    const updatedGroups = data.salestaffid.filter(
      (group: any) => group.id !== id
    );

    const discountPerStaff = "100.00";
    const totalPrice = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );
    handleSalesStaffChange(data, updatedGroupsWithDiscountAndPrice);
  };

  const handleRemoveSalesStaffGroup = (id: string) => {
    const updatedGroups = SalesStaffGroups.filter(
      (group: any) => group.id !== id
    );

    const discountPerStaff = "100.00";
    const totalPrice: any = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );

    setSalesStaffGroups(updatedGroupsWithDiscountAndPrice);
    setSalesStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const handleRemoveServiceStaffGroupChange = (data: any, id: string) => {
    const updatedGroups = data.service.filter((group: any) => group.id !== id);

    const discountPerStaff = "100.00";
    const totalPrice: any = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );
    handleServiceStaffChange(data, updatedGroupsWithDiscountAndPrice);
  };

  const handleRemoveServiceStaffGroup = (id: string) => {
    const updatedGroups = ServiceStaffGroups.filter(
      (group: any) => group.id !== id
    );

    const numberOfStaff = updatedGroups.length;
    const discountPerStaff = "100.00";
    const totalPrice: any = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );

    setServiceStaffGroups(updatedGroupsWithDiscountAndPrice);
    setServiceStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const [selectedPayments, setSelectedPayment] = useState<string | null>(
    "Cash"
  );
  const paymentModes: any[] = [
    { name: "Cash", id: "Cash" },
    { name: "Bank Transhfer", id: "Bank Transhfer" },
    { name: "Toch & Go", id: "Toch & Go" },
    { name: "Credit Card", id: "Credit Card" },
  ];

  interface StaffItem {
    id: string;
    firstName: string;
  }

  const confirmDiscount = (item: any) => {
    setDiscountDialogVisible(true);
    setDiscountDialogOpenItem(item);
    if (item.ispercentage == true) {
      setSelectedType("Percentage");
    } else if (item.ispercentage == false) {
      setSelectedType("Monetary");
    }
    setValue(item.discount);
  };

  const isStaffInSalesGroups = (staffId: any) => {
    return SalesStaffGroups.some((group: any) => group.id === staffId);
  };

  const isStaffInServiceGroups = (staffId: any) => {
    return ServiceStaffGroups.some((group: any) => group.id === staffId);
  };

  const confirmSalesStaff = (item: any) => {
    setSalesStaffGroups([]);
    setSalesStaffIds([]);
    setVisibleSalesStaff(true);
    setDiscountDialogOpenItem(item);
    setValue(item.salestaffid);
  };

  const confirmServiceStaff = (item: any) => {
    setServiceStaffGroups([]);
    setServiceStaffIds([]);
    setVisibleServiceStaff(true);
    setDiscountDialogOpenItem(item);
    setValue(item.salestaffid);
  };

  const addDiscount = (rowData: any) => {
    setDiscountDialogVisible(false);
    handleDiscountChange(discountDialogOpenItem);
  };

  const confirmPrice = async (value: any) => {
    try {
      const updatedCartItems = cartItems.map((item: any) => {
        if (item.id === itemId) {
          return {
            ...item,
            partial_payment: value,
          };
        }
        return item;
      });

      await Promise.all(
        updatedCartItems.map(
          async (item) => await updateItemById(item.id, item)
        )
      );
      setvisibleUnitPrice(false);
      setPaymentAmt("");
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const onDeleteCancel = () => {
    setDiscountDialogVisible(false);
    handleDiscountChange(discountDialogOpenItem);
  };

  async function getStaffList() {
    try {
      let url = "/staff/dropdown";

      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          const staffList: StaffItem[] = response.data.data;
          setStaffData(staffList);
        } else {
          console.log("Error in API call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const discountOption = [
    { name: "Monetary", id: "Monetary" },
    { name: "Percentage", id: "Percentage" },
  ];

  const handleCheckOutClose = () => {
    setCheckout(false);
  };

  const handleUnitPriceClose = () => {
    setvisibleUnitPrice(false);
    setPaymentAmt("");
    // handlePriceChange(discountDialogOpenItem);
  };

  const handleSalesStaffClose = () => {
    setVisibleSalesStaff(false);
    handleSalesStaffChange(discountDialogOpenItem, null);
    setSalesStaffGroups([]);
    setSalesStaffIds([]);
    // handlePriceChange(discountDialogOpenItem);
  };

  const handleServiceStaffClose = () => {
    setVisibleServiceStaff(false);
    handleServiceStaffChange(discountDialogOpenItem, null);
    setServiceStaffGroups([]);
    setServiceStaffIds([]);
    // handlePriceChange(discountDialogOpenItem);
  };

  const handleVisibleCheckOutClose = () => {
    setVisibleCheckout(false);
  };

  const handleCategoryViewChange = (view: string) => {
    setCurrentCategoryView(view);
  };

  const handleViewChange = (view: string) => {
    setCurrentView(view);
  };

  const handleChangeKIVCheck = async (
    id: string,
    value: boolean,
    item: any
  ) => {
    setIsKIV(value);
    try {
      let Item = {
        desc: item.desc,
        price: item.price,
        totalprice: item.totalprice,
        productid: item.productid,
        type: item.type,
        discount: item.discount,
        remarks: item.remarks,
        id: item.id,
        units: item.units,
        salestaffid: item.salestaffid,
        mainstaffid: item.mainstaffid,
        service: item.service,
        ispercentage: item.ispercentage,
        partial_payment: item.partial_payment,
        isKIV: value,
      };
      await updateItemById(item.id, Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleRemarksChange = async (id: string, value: string, item: any) => {
    try {
      let Item = {
        desc: item.desc,
        price: item.price,
        totalprice: item.totalprice,
        productid: item.productid,
        type: item.type,
        discount: item.discount,
        remarks: value,
        id: item.id,
        units: item.units,
        salestaffid: item.salestaffid,
        mainstaffid: item.mainstaffid,
        service: item.service,
        ispercentage: item.ispercentage,
        partial_payment: item.partial_payment,
        isKIV: item.isKIV,
      };
      await updateItemById(item.id, Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handlepartial_paymentChange = async (
    id: string,
    value: string,
    item: any
  ) => {
    try {
      let Item = {
        desc: item.desc,
        price: item.price,
        totalprice: item.totalprice,
        productid: item.productid,
        type: item.type,
        discount: item.discount,
        remarks: item.remark,
        id: item.id,
        units: item.units,
        salestaffid: item.salestaffid,
        mainstaffid: item.mainstaffid,
        service: item.service,
        ispercentage: item.ispercentage,
        partial_payment: value,
        isKIV: item.isKIV,
      };
      await updateItemById(item.id, Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleDiscountChange = async (item: any) => {
    try {
      if (item) {
        let isPer = false;
        if (selectedType == "Monetary") {
          isPer = false;
        } else if (selectedType == "Percentage") {
          isPer = true;
        }

        let totPrice = parseFloat(item.price) * item.units;
        if (item.ispercentage === true) {
          let dis = (parseFloat(item.price) * parseFloat(value)) / 100;
          totPrice = totPrice - dis;
        } else if (item.ispercentage === false) {
          totPrice = totPrice - parseFloat(value);
        }
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: totPrice,
          productid: item.productid,
          type: item.type,
          discount: value,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: isPer,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
        };
        await updateItemById(item.id, Item);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handlePriceChange = async (item: any) => {
    try {
      if (item) {
        let totPrice = parseFloat(price) * item.units;
        if (item.ispercentage === true) {
          let dis = (parseFloat(price) * item.discount) / 100;
          totPrice = totPrice - dis;
        } else if (item.ispercentage === false) {
          totPrice = totPrice - item.discount;
        }
        let Item = {
          desc: item.desc,
          price: price,
          totalprice: totPrice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
        };
        await updateItemById(item.id, Item);
        if (
          (Item.salestaffid && Item.salestaffid.length > 0) ||
          (Item.service && Item.service.length > 0)
        ) {
          let serviceStaff: any = [];
          let salesStaff: any = [];

          if (Item.salestaffid && Item.salestaffid.length > 0) {
            const updatedGroups: any[] = Item.salestaffid;

            const numberOfStaff = Item.salestaffid.length;
            const discountPerStaff =
              numberOfStaff > 0 ? (100 / numberOfStaff).toFixed(2) : "0";
            const totalPrice: any = parseInt(Item.price);
            const pricePerStaff =
              numberOfStaff > 0
                ? (totalPrice / numberOfStaff).toFixed(2)
                : totalPrice;

            salesStaff = updatedGroups.map((group: any) => ({
              ...group,
              discount: discountPerStaff,
              price: pricePerStaff,
            }));
          }

          if (Item.service && Item.service.length > 0) {
            const updatedGroups: any[] = Item.service;

            const numberOfStaff = Item.service.length;
            const discountPerStaff =
              numberOfStaff > 0 ? (100 / numberOfStaff).toFixed(2) : "0";
            const totalPrice: any = parseInt(Item.price);
            const pricePerStaff =
              numberOfStaff > 0
                ? (totalPrice / numberOfStaff).toFixed(2)
                : totalPrice;

            serviceStaff = updatedGroups.map((group: any) => ({
              ...group,
              discount: discountPerStaff,
              price: pricePerStaff,
            }));
          }
          let updatedItem = {
            desc: Item.desc,
            price: Item.price,
            totalprice: Item.totalprice,
            productid: Item.productid,
            type: Item.type,
            discount: Item.discount,
            remarks: Item.remarks,
            id: Item.id,
            units: Item.units,
            salestaffid: salesStaff,
            mainstaffid: Item.mainstaffid,
            service: serviceStaff,
            ispercentage: Item.ispercentage,
            partial_payment: item.partial_payment,
            isKIV: item.isKIV,
          };
          await updateItemById(item.id, updatedItem);
        }
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleMainStaffChange = async (item: any) => {
    try {
      if (item) {
        let selectStaff: any = [];
        if (selectedStaff && selectedStaff.length > 0) {
          selectStaff = selectedStaff;
          selectStaff[0]["price"] = item.price;
        }
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: selectStaff,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
        };
        await updateItemById(item.id, Item);
        setSelectedStaff([]);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleSalesStaffChange = async (item: any, data: any) => {
    try {
      let salesStaffs = salesStaffIds;
      if (data !== null) {
        salesStaffs = data;
      }
      if (item) {
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: salesStaffs,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
        };
        await updateItemById(item.id, Item);
        setSalesStaffIds([]);
        setSalesStaffGroups([]);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleServiceStaffChange = async (item: any, data: any) => {
    try {
      let serviceStaffs = serviceStaffIds;
      if (data !== null) {
        serviceStaffs = data;
      }
      if (item) {
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: serviceStaffs,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
        };
        await updateItemById(item.id, Item);
        setServiceStaffIds([]);
        setServiceStaffGroups([]);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleAssistStaffChange = async (item: any) => {
    try {
      if (item) {
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: salesStaffIds,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
        };
        await updateItemById(item.id, Item);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  useEffect(() => {
    let totalPartialAmount = 0;
    cartItems.forEach((item: any) => {
      let pAmount = 0;
      if (item.partial_payment) {
        pAmount = parseFloat(item.partial_payment);
      }

      totalPartialAmount += pAmount;
    });

    setPartialAmount(totalPartialAmount);
  }, [cartItems]);

  const cartDataBodyTemplate = () => {
    return (
      <tbody>
        {cartItems &&
          cartItems.map((data: any) => {
            return (
              <tr key={data.id}>
                <td>
                  {data.units} X {data.desc}
                </td>
                <td>
                  {/* <Button
                    variant="text"
                    onClick={() => {
                      confirmPrice(data);
                    }}
                  >
                  </Button> */}
                  {parseFloat(data.price).toFixed(2)}
                </td>
                <td>
                  {data.ispercentage == true && (
                    <Button
                      aria-hidden="true"
                      variant="text"
                      onClick={() => {
                        confirmDiscount(data);
                      }}
                    >
                      {parseFloat(data.discount).toFixed(2)}%
                    </Button>
                  )}
                  {data.ispercentage == false && (
                    <Button
                      aria-hidden="true"
                      variant="text"
                      onClick={() => {
                        confirmDiscount(data);
                      }}
                    >
                      {parseFloat(data.discount).toFixed(2)}
                    </Button>
                  )}
                </td>
                <td>{parseFloat(data.totalprice).toFixed(2)}</td>
                <td>
                  {/* <TextField
                    value={data.partial_payment}
                    onChange={(e) =>
                      handlepartial_paymentChange(data.id, e.target.value, data)
                    }
                    type="number"
                    fullWidth
                    rows={2}
                  /> */}
                  <Button
                    aria-hidden="true"
                    variant="text"
                    onClick={(e: any) => {
                      setvisibleUnitPrice(true);
                      setItemId(data.id);
                    }}
                  >
                    {parseFloat(data.partial_payment).toFixed(2)}
                  </Button>
                </td>
                <td>
                  <div className="flex flex-column gap-2">
                    {data.salestaffid.length == 0 && (
                      <Button
                        aria-hidden="true"
                        variant="text"
                        onClick={() => {
                          confirmSalesStaff(data);
                        }}
                      >
                        Staff
                      </Button>
                    )}
                    {data.salestaffid &&
                      data.salestaffid.length > 0 &&
                      data.salestaffid.map((item: any) => (
                        <div key={item.id} className="grid align-items-center">
                          <div className="col-8 flex flex-column gap-2">
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {item.name}
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.discount).toFixed(2)}%
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.price).toFixed(2)}
                            </label>
                          </div>
                          <div className="col-4">
                            <Clear
                              className="text-red-900 cursor-pointer"
                              onClick={() => {
                                handleRemoveSalesStaffGroupChange(
                                  data,
                                  item.id
                                );
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="flex flex-column gap-2">
                    {data.type == "Service" && data.service.length == 0 && (
                      <Button
                        aria-hidden="true"
                        variant="text"
                        onClick={() => {
                          confirmServiceStaff(data);
                        }}
                      >
                        Staff
                      </Button>
                    )}
                    {data.type == "Service" &&
                      data.service &&
                      data.service.length > 0 &&
                      data.service.map((item: any) => (
                        <div key={item.id} className="grid align-items-center">
                          <div className="col-8 flex flex-column gap-2">
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {item.name}
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.discount).toFixed(2)}%
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.price).toFixed(2)}
                            </label>
                          </div>
                          <div className="col-4">
                            <Clear
                              className="text-red-900 cursor-pointer"
                              onClick={() => {
                                handleRemoveServiceStaffGroupChange(
                                  data,
                                  item.id
                                );
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </td>
                {data.type === "Product" ? (
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={data.isKIV}
                          onChange={(e: any) =>
                            handleChangeKIVCheck(
                              data.id,
                              e.target.checked,
                              data
                            )
                          }
                          color="primary"
                        />
                      }
                      label={""}
                    />
                  </td>
                ) : (
                  <td></td>
                )}
                <td>
                  <TextField
                    value={data.remarks}
                    onChange={(e) =>
                      handleRemarksChange(data.id, e.target.value, data)
                    }
                    fullWidth
                    multiline
                    rows={2}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  // const handleGeneratePdf = async () => {
  //   const element = document.getElementById("pdf-content");
  //   if (element) {
  //     const options = {
  //       margin: 1,
  //       filename: "generated.pdf",
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //       pagebreak: { mode: ["avoid-all"] },
  //     };

  //     try {
  //       // Generate the PDF
  //       const pdf = await html2pdf()
  //         .set(options)
  //         .from(element)
  //         .outputPdf("blob");

  //       // Create a URL for the Blob
  //       const url = URL.createObjectURL(pdf);

  //       // Open the Blob URL in a new tab
  //       window.open(url, "_blank");
  //     } catch (error) {
  //       console.error("Error generating PDF:", error);
  //     }
  //   }
  // };

  // async function getVoucherDetails() {
  //   try {
  //     setVoucherApply(true);
  //     let url = `/voucher/apply/${voucher}`;

  //     const response = await get(url);
  //     if (response && response.data) {
  //       if (response.data.success) {
  //         if (response.data.data.isPercentage) {
  //           setVoucherType("percentage");
  //         } else {
  //           setVoucherType("fixed");
  //         }
  //         setVoucherValue(parseInt(response.data.data.voucher_value));
  //         // setBranchData(response.data.data);
  //       } else {
  //         setVoucherApply(false);
  //         setVoucherType("");
  //         setVoucherValue(0.0);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleAddToCart = async (item: any) => {
    try {
      let type = "";
      if (currentView == "1") {
        type = "Service";
      } else if (currentView == "2") {
        type = "Product";
      } else if (currentView == "3") {
        type = "MultiService";
      } else if (currentView == "4") {
        type = "Plan";
      }
      let Item = {
        desc: item.name,
        price: parseFloat(item.price).toFixed(2),
        totalprice: (1 * parseFloat(item.price)).toFixed(2),
        productid: item.id,
        type: type,
        discount: 0,
        remarks: "",
        id: item.id,
        units: 1,
        salestaffid: [],
        mainstaffid: [],
        service: [],
        ispercentage: false,
        partial_payment: parseFloat(item.price).toFixed(2),
      };
      await addItem(Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const getCart = async () => {
    try {
      const result = await getItems();
      setCartItems(result);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const addMore = async (item: any) => {
    try {
      let totPrice = parseFloat(item.price) * (item.units + 1);
      if (item.ispercentage === true) {
        let dis = (parseFloat(item.price) * item.discount) / 100;
        totPrice = totPrice - dis;
      } else if (item.ispercentage === false) {
        totPrice = totPrice - item.discount;
      }
      let Item = {
        desc: item.desc,
        price: parseFloat(item.price).toFixed(2),
        totalprice: totPrice.toFixed(2),
        productid: item.productid,
        type: item.type,
        discount: item.discount,
        remarks: item.remarks,
        id: item.id,
        units: item.units + 1,
        salestaffid: item.salestaffid,
        mainstaffid: item.mainstaffid,
        service: item.service,
        ispercentage: item.ispercentage,
        partial_payment: item.partial_payment,
      };
      await updateItemById(item.id, Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const remove = async (item: any) => {
    try {
      if (item.units > 1) {
        let totPrice = parseFloat(item.price) * (item.units - 1);
        if (item.ispercentage === true) {
          let dis = (parseFloat(item.price) * item.discount) / 100;
          totPrice = totPrice - dis;
        } else if (item.ispercentage === false) {
          totPrice = totPrice - item.discount;
        }

        let updatedItem = {
          desc: item.desc,
          price: parseFloat(item.price).toFixed(2),
          totalprice: totPrice.toFixed(2),
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units - 1,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
        };

        await updateItemById(item.id, updatedItem);
      } else {
        await removeFromCart(item.id);
      }

      await getCart();
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const removeFromCart = async (id: string) => {
    try {
      await deleteItemById(id);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const getProducts = async () => {
    try {
      let url = "/cart/getitems";

      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setProducts(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSalesDiscountChange = (index: number, newDiscount: string) => {
    const discountValue = parseFloat(newDiscount) || 0;
    const updatedGroups = [...SalesStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const newPrice = (originalPrice * discountValue) / 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        discount: newDiscount,
        price: newPrice.toFixed(2),
      };
    }

    setSalesStaffGroups(updatedGroups);
    setSalesStaffIds(updatedGroups);
  };

  const handleSalesPriceChange = (index: number, newPrice: string) => {
    const priceValue = parseFloat(newPrice) || 0;
    const updatedGroups = [...SalesStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const discountValue = (priceValue / originalPrice) * 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        price: newPrice,
        discount: discountValue.toFixed(2),
      };
    }
    setSalesStaffGroups(updatedGroups);
    setSalesStaffIds(updatedGroups);
  };

  const handleServiceDiscountChange = (index: number, newDiscount: string) => {
    const discountValue = parseFloat(newDiscount) || 0;
    const updatedGroups = [...ServiceStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const newPrice = (originalPrice * discountValue) / 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        discount: newDiscount,
        price: newPrice.toFixed(2),
      };
    }

    setServiceStaffGroups(updatedGroups);
    setServiceStaffIds(updatedGroups);
  };

  const handleServicePriceChange = (index: number, newPrice: string) => {
    const priceValue = parseFloat(newPrice) || 0;
    const updatedGroups = [...ServiceStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const discountValue = (priceValue / originalPrice) * 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        price: newPrice,
        discount: discountValue.toFixed(2),
      };
    }
    setServiceStaffGroups(updatedGroups);
    setServiceStaffIds(updatedGroups);
  };

  useEffect(() => {
    handleAssistStaffChange(discountDialogOpenItem);
  }, [visibleAssistStaff]);

  useEffect(() => {
    handleMainStaffChange(discountDialogOpenItem);
  }, [visibleStaff]);

  // useEffect(() => {
  //   handleServiceStaffChange(discountDialogOpenItem);
  // }, [visibleServiceStaff]);

  // useEffect(() => {
  //   handleDiscountChange(discountDialogOpenItem);
  // }, [discountDialogVisible]);

  // useEffect(() => {
  //   handlePriceChange(discountDialogOpenItem);
  // }, [visibleUnitPrice]);

  useEffect(() => {
    setChildStyle("fixed");

    return () => {
      setChildStyle("");
    };
  }, [setChildStyle]);

  useEffect(() => {
    const product = products.find((p: any) => p.id === currentView);
    const categories: any = product
      ? product.categories.map((category: any) => ({
          id: category.id,
          name: category.categoryname,
        }))
      : [];
    if (categories.length > 0) {
      let allCategory = {
        id: "all",
        name: "All",
      };
      categories.unshift(allCategory);
      setCategoryNamesByMainCategory(categories);
      handleCategoryViewChange("all");
    } else {
      setCategoryNamesByMainCategory([]);
    }
  }, [currentView]);

  useEffect(() => {
    let result: any = [];
    for (let product of products) {
      if (product.id == currentView) {
        for (let category of product.categories) {
          if (currentCategoryView === "all") {
            result.push({
              id: category.id,
              name: category.categoryname,
              items: category.items.map((item: any) => ({
                id: item.id,
                name: item.productname,
                price: item.price,
              })),
            });
          } else if (category.id === currentCategoryView) {
            result.push({
              id: category.id,
              name: category.categoryname,
              items: category.items.map((item: any) => ({
                id: item.id,
                name: item.productname,
                price: item.price,
              })),
            });
          }
        }
      }
    }
    setProductByCategory(result);
  }, [currentView, currentCategoryView]);

  useEffect(() => {
    let newTotal = cartItems.reduce(
      (acc, item: any) => acc + parseFloat(item.totalprice),
      0
    );

    // let finalTotal = newTotal;

    // if (voucherType == "percentage") {
    //   let dis = (newTotal * voucherValue) / 100;
    //   finalTotal = newTotal - dis;
    // } else if (voucherType == "fixed") {
    //   finalTotal = newTotal - voucherValue;
    // }

    setTotal(newTotal);
    // setFinalTotal(finalTotal);
  }, [cartItems, voucherValue, total, voucherType, voucherApply]);

  useEffect(() => {
    let totalDiscount = 0;

    cartItems.forEach((item: any) => {
      let discount = 0;
      if (!item.ispercentage) {
        discount = parseFloat(item.discount);
      } else if (item.ispercentage) {
        discount = (parseFloat(item.discount) / 100) * parseFloat(item.price);
      }

      totalDiscount += discount;
    });

    setDiscount(totalDiscount);
  }, [cartItems, total, discount]);

  useEffect(() => {
    let finalTotal = total;
    let totalPartialAmount = partialAmount;

    let netTotal = finalTotal - totalPartialAmount;

    setNetAmount(netTotal);
  }, [total, discount, cashTopup, partialAmount]);

  useEffect(() => {
    const handleWheel = (event: any) => {
      event.preventDefault();
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += event.deltaY;
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel);
    }
    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getProducts();
      await getCart();
    };

    fetchData();
    getStaffList();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      let mainCategories: any = products.map((product: any) => ({
        id: product.id,
        name: product.mainCategoryName,
        disabled: product.categories.length > 0 ? false : true,
      }));
      setMainCategory(mainCategories);
      const enabledCategories = mainCategories.filter(
        (category: any) => !category.disabled
      );
      if (enabledCategories.length > 0) {
        const initialCategory = enabledCategories[0].id;
        setCurrentView(initialCategory);
        handleViewChange(initialCategory);
      }
    }
  }, [products]);

  return (
    <div className="dashboard-margin">
      <div className="grid">
        <div className="col-8">
          <div className="grid">
            <div className="col-12">
              <div
                className="flex align-items-center gap-2 flex-nowrap w-full white-space-nowrap overflow-x-auto"
                ref={scrollContainerRef}
              >
                {mainCategory.map((category: any) => (
                  <Button
                    variant="contained"
                    id={category.id}
                    key={category.id}
                    className={`calendar-btn ${
                      currentView == category.id ? "selected" : ""
                    }`}
                    disabled={category.disabled}
                    onClick={() => handleViewChange(category.id)}
                  >
                    {category.name}
                  </Button>
                ))}
                <i className="ri-search-line text-2xl"></i>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <div
                className="flex align-items-center gap-2 flex-nowrap w-full white-space-nowrap overflow-x-auto"
                ref={scrollContainerRef}
              >
                {categoryNamesByMainCategory.map((category: any) => (
                  <Button
                    variant="contained"
                    id={category.id}
                    key={category.id}
                    className={`calendar-btn ${
                      currentCategoryView == category.id ? "selected" : ""
                    }`}
                    onClick={() => handleCategoryViewChange(category.id)}
                  >
                    {category.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className="grid mt-2 pb-6 product-show-h">
            {productByCategory.map((category: any) => (
              <div className="col-12" key={category.id} id={category.id}>
                <span className="form-label text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                  {category.name}
                </span>
                <div className="grid mt-2 ">
                  {category.items.map((item: any) => (
                    <div className="col-3" key={item.id} id={item.id}>
                      <div
                        className="cursor-pointer flex flex-column gap-1 border-3 border-black border-round-lg p-3 bg-white"
                        onClick={() => handleAddToCart(item)}
                      >
                        <span className="form-label text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                          {item.name}
                        </span>
                        <span>{item.price}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-4">
          <TextField
            select
            label="Member"
            value={selectedMember}
            onChange={(e) => {
              setSelectedMember(e.target.value as string);
              if (e.target.value) {
                setMemberError("");
              }
            }}
            fullWidth
            error={!!memberError}
            helperText={memberError}
            margin="normal"
          >
            {memberData.map((category: any) => (
              <MenuItem key={category.id} value={category.id}>
                {category.label}
              </MenuItem>
            ))}
          </TextField>
          <div className="grid box-shadow-common">
            <div className="col-12 flex align-items-center justify-content-between bg-white p-3">
              <span>No membership applied yet.</span>
              <span className="text-primary">Search</span>
            </div>
          </div>
          {/* <div className="grid mt-3 box-shadow-common">
            <div className="col-12 flex align-items-center justify-content-between bg-white p-3">
              <div className="p-inputgroup flex-1">
                <InputText
                  placeholder="Add voucher"
                  onChange={(e) => setVoucher(e.target.value)}
                />
                <span
                  className="p-inputgroup-addon text-red-600 font-bold cursor-pointer"
                  onClick={getVoucherDetails}
                >
                  Apply
                </span>
              </div>
            </div>
          </div> */}
          <div className="grid mt-3 box-shadow-common cart-item-card">
            <div className="col-12 flex align-items-center justify-content-center bg-white p-3 border-bottom-2 border-300">
              <span className="form-label">Seleted items</span>
            </div>
          </div>
          <div className="grid product-cart-h mt-0">
            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map((item: any) => (
                <div
                  className="col-12 bg-white p-3 border-bottom-1 border-300"
                  key={item.id}
                  id={item.id}
                >
                  <div className="grid m-0">
                    <div className="col-9 flex flex-column gap-1 p-0">
                      <span className="form-label">
                        {item.units} X {item.desc}
                      </span>
                      <span className="form-label">RM{item.price}</span>
                    </div>
                    <div className="col-3 flex align-items-center justify-content-end gap-1 p-0">
                      {/* <i className="cursor-pointer ri-subtract-fill text-xl text-red-700 surface-200 p-1"></i> */}
                      <IconButton color="success" onClick={() => addMore(item)}>
                        <Add />
                      </IconButton>
                      <IconButton color="primary" onClick={() => remove(item)}>
                        <Remove />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => removeFromCart(item.id)}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            {cartItems && cartItems.length == 0 && (
              <div className="col-12 bg-white p-3 border-bottom-1 border-300 flex justify-content-center p-2">
                <span className="form-label">No Item Selected yet.</span>
              </div>
            )}
          </div>
          {cartItems && cartItems.length > 0 && (
            <div className="grid sticky totalamount-text bg-common">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#4CAF50",
                  padding: 2,
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  RM{total.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  mb: 1,
                  p: 0,
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setVisibleCheckout(true)}
                  disabled={!selectedMember}
                >
                  {!selectedMember
                    ? "Please selecte member first"
                    : "Proceed to checkout"}
                </Button>
              </Box>
              <Dialog open={checkout} onClose={handleCheckOutClose}>
                <DialogTitle>Add Payment Details</DialogTitle>
                <DialogContent>
                  <div>
                    <div style={{ marginTop: "10px" }}>
                      <TextField
                        label="Account Holder Name"
                        id="account-holder"
                        placeholder="Enter your name"
                        className="p-1"
                        style={{
                          width: "100%",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                        variant="outlined"
                      />
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <TextField
                        label="Card Number"
                        id="card-number"
                        placeholder="Enter your card number"
                        className="p-1"
                        style={{
                          width: "100%",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                        variant="outlined"
                      />
                    </div>

                    <div
                      style={{ marginTop: "10px" }}
                      className="flex flex-row flex-1 align-items-center justify-content-between"
                    >
                      <div style={{}} className="flex flex-initial flex-column">
                        <TextField
                          label="Month/year"
                          id="month-year"
                          placeholder="12/56"
                          className="p-1"
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            marginTop: "10px",
                          }}
                          variant="outlined"
                        />
                      </div>
                      <div style={{}} className="flex flex-initial flex-column">
                        <TextField
                          label="CVV"
                          id="cvv"
                          placeholder="***"
                          className="p-1"
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            marginTop: "10px",
                          }}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <Button variant="contained" className="add-button">
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleCheckOutClose}
                        className="btn-close ml-2 "
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={visibleSalesStaff}
                PaperProps={{
                  sx: {
                    width: "68vw",
                    maxWidth: "none",
                  },
                }}
                onClose={() => {
                  if (!visibleSalesStaff) return;
                  setVisibleSalesStaff(false);
                }}
              >
                <DialogContent>
                  <label className="form-label col-12">
                    Choose Sales staff
                  </label>
                  <div className="col-12">
                    <label>
                      You can choose at most 4 sales staff for this item.
                    </label>
                  </div>
                  <div className="grid mt-2">
                    <div className="col-4">
                      {staffData.map((items: StaffItem) => (
                        <Button
                          variant="contained"
                          key={items.id}
                          onClick={() => {
                            handleSalesStaff(items);
                          }}
                          disabled={isStaffInSalesGroups(items.id)}
                          className="p-button-text ml-2 mb-2 add-button"
                        >
                          {items.firstName}
                        </Button>
                      ))}
                    </div>
                    <div className="col-8">
                      {SalesStaffGroups.map((group: any, index: any) => (
                        <div
                          key={index}
                          className="grid col-12 align-items-center"
                        >
                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <TextField
                                id={`name-${index}`}
                                disabled
                                style={{
                                  width: "100%",
                                  borderRadius: "4px",
                                }}
                                value={group.name}
                                variant="outlined"
                              />
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-3">
                              <div className="p-inputgroup flex flex-1">
                                <TextField
                                  id={`discount-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleSalesDiscountChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.discount}
                                  variant="outlined"
                                />
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  %
                                </span>
                              </div>
                            </div>
                          )}

                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <div className="p-inputgroup flex flex-1">
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  RM
                                </span>
                                <TextField
                                  id={`price-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleSalesPriceChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.price}
                                  variant="outlined"
                                />
                              </div>
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-1">
                              {staffGroups && (
                                <span
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() =>
                                    handleRemoveSalesStaffGroup(group.id)
                                  }
                                >
                                  X
                                </span>
                              )}
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-12 py-0">
                              <label>
                                Total amount is after discount. (RM
                                {group.price})
                              </label>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="col-12">
                      <Button
                        variant="contained"
                        className="add-button"
                        onClick={handleSalesStaffClose}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={visibleServiceStaff}
                PaperProps={{
                  sx: {
                    width: "60vw",
                    maxWidth: "none",
                  },
                }}
                onClose={() => {
                  if (!visibleServiceStaff) return;
                  setVisibleServiceStaff(false);
                }}
              >
                <DialogContent>
                  <label className="form-label col-12">
                    Choose Service staff
                  </label>
                  <div className="col-12">
                    <label>
                      You can choose at most 4 service staff for this item.
                    </label>
                  </div>
                  <div className="grid mt-2">
                    <div className="col-5">
                      {staffData.map((items: StaffItem) => (
                        <Button
                          variant="contained"
                          key={items.id}
                          onClick={() => {
                            handleServiceStaff(items);
                          }}
                          disabled={isStaffInServiceGroups(items.id)}
                          className="p-button-text ml-2 mb-2 add-button"
                        >
                          {items.firstName}
                        </Button>
                      ))}
                    </div>
                    <div className="col-7">
                      {ServiceStaffGroups.map((group: any, index: any) => (
                        <div
                          key={index}
                          className="grid col-12 align-items-center"
                        >
                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <TextField
                                id={`name-${index}`}
                                disabled
                                style={{
                                  width: "100%",
                                  borderRadius: "4px",
                                }}
                                value={group.name}
                                variant="outlined"
                              />
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-3">
                              <div className="p-inputgroup flex flex-1">
                                <TextField
                                  id={`discount-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleServiceDiscountChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.discount}
                                  variant="outlined"
                                />
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  %
                                </span>
                              </div>
                            </div>
                          )}

                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <div className="p-inputgroup flex flex-1">
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  RM
                                </span>
                                <TextField
                                  id={`price-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleServicePriceChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.price}
                                  variant="outlined"
                                />
                              </div>
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-1">
                              {staffGroups && (
                                <span
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() =>
                                    handleRemoveServiceStaffGroup(group.id)
                                  }
                                >
                                  X
                                </span>
                              )}
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-12 py-0">
                              <label>
                                Total amount is after discount. (RM
                                {group.price})
                              </label>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="col-12">
                      <Button
                        variant="contained"
                        className="add-button"
                        onClick={handleServiceStaffClose}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={visibleCheckout}
                PaperProps={{
                  sx: {
                    width: "73vw",
                    maxWidth: "none",
                  },
                }}
                onClose={handleVisibleCheckOutClose}
              >
                <DialogTitle>Checkout confirmation</DialogTitle>
                <DialogContent>
                  <div className="grid">
                    <div className="col-12">
                      <label className="form-label">Selected items</label>
                      <table className="cart-table mt-2">
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Unit Price(RM)</th>
                            <th>Discount(RM or %)</th>
                            <th>Total Sales Amt(RM)</th>
                            <th>Total Payment Amt(RM)</th>
                            <th>Sales</th>
                            <th>Service</th>
                            <th>Taken / KIV</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        {cartDataBodyTemplate()}
                      </table>
                    </div>
                  </div>
                  <div className="grid mt-3">
                    <div className="col-6">
                      <div className="grid">
                        <div className="col-12">
                          <label className="form-label">Payment method</label>
                          <TextField
                            id={`payment-method`}
                            select
                            value={selectedPayments}
                            onChange={(e) => setSelectedPayment(e.target.value)}
                            fullWidth
                            InputProps={{
                              style: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              },
                            }}
                          >
                            {paymentModes.map((staff) => (
                              <MenuItem key={staff.id} value={staff.id}>
                                {staff.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                      {selectedPayments && selectedPayments === "Cash" && (
                        <div className="grid">
                          <div className="col-12">
                            <label className="form-label">
                              Cash paid
                              <span className="font-medium">
                                {" "}
                                (To calculate charge)
                              </span>
                            </label>
                            <TextField
                              id="cash-paid"
                              style={{
                                width: "100%",
                                borderRadius: "4px",
                              }}
                              value={cashPaidValue}
                              onChange={(e) => setCashPaidValue(e.target.value)}
                              variant="outlined"
                            />
                          </div>
                        </div>
                      )}
                      <div className="grid">
                        <div className="col-12">
                          <label className="form-label">Remark</label>
                          <TextField
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            fullWidth
                            multiline
                            rows={2}
                          />
                          <label>
                            This remarks will be shown in customer receipt.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="grid mt-3">
                        <div className="col-6 flex gap-2 flex-column align-items-end">
                          <label className="font-semibold">Subtotal</label>
                          <label className="font-semibold">Discount</label>
                          <label className="font-semibold">
                            Total Payment Amt (RM)
                          </label>
                          <label className="font-semibold">
                            Pay with cash topup
                          </label>
                          <label className="text-3xl font-bold">
                            Net amount
                          </label>
                        </div>
                        <div className="col-6 flex gap-2 flex-column align-items-start">
                          <label className="font-semibold">
                            {total?.toFixed(2)}
                          </label>
                          <label className="font-semibold">
                            {discount?.toFixed(2)}
                          </label>
                          <label className="font-semibold">
                            {partialAmount?.toFixed(2)}
                          </label>
                          <label className="font-semibold">
                            {cashTopup?.toFixed(2)}
                          </label>
                          <label className="text-3xl font-bold">
                            {netAmount?.toFixed(2)}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-12">
                      <hr />
                      <div className="flex align-items-center mt-3">
                        <Checkbox
                          id="ingredient1"
                          checked={printReceipt}
                          onChange={(e: any) =>
                            setPrintReceipt(e.target.checked)
                          }
                        />
                        <label htmlFor="ingredient1" className="ml-2">
                          Print receipt
                        </label>
                      </div>
                      <Button
                        variant="contained"
                        // onClick={() => setclockInDialogVisible(true)}
                        onClick={handleCheckout}
                        disabled={disableBtn}
                        className="p-button-text w-full mt-2"
                      >
                        Confirm checkout
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={discountDialogVisible}
                PaperProps={{
                  sx: {
                    width: "60vw",
                    maxWidth: "none",
                  },
                }}
                onClose={onDeleteCancel}
              >
                <DialogTitle>Change Unit Discount</DialogTitle>
                <DialogContent>
                  <div className="grid col-12">
                    <div className="col-6">
                      <TextField
                        label="Type"
                        id={`type`}
                        select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        fullWidth
                        className="mt-1"
                        InputProps={{
                          style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      >
                        {discountOption.map((staff) => (
                          <MenuItem key={staff.id} value={staff.id}>
                            {staff.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="col-6">
                      <TextField
                        label={`Value ${
                          selectedType === "Monetary" ? "(RM)" : "(%)"
                        }`}
                        id="value"
                        className="mt-1"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setValue(e.target.value);
                        }}
                        style={{
                          width: "100%",
                          borderRadius: "4px",
                        }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <Checkbox
                      onChange={(e: any) => setChecked(e.target.checked!)}
                      checked={checked}
                    ></Checkbox>
                    <label className="ml-2">
                      Apply the price change to all same item.
                    </label>
                  </div> */}
                  <div className="col-12 mt-2">
                    <Button
                      variant="contained"
                      className="add-button"
                      onClick={addDiscount}
                    >
                      Save
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={visibleUnitPrice}
                PaperProps={{
                  sx: {
                    width: "60vw",
                    maxWidth: "none",
                  },
                }}
                onClose={handleUnitPriceClose}
              >
                <DialogContent>
                  <label className="form-label col-12">
                    Change Payment Amt (RM)
                  </label>
                  <div className="col-12 ">
                    <div className="p-inputgroup flex flex-1">
                      {/* <span
                        className="p-inputgroup-addon cursor-pointer"
                        style={{
                          padding: "16.5px 14px",
                        }}
                      >
                        RM
                      </span> */}
                      <TextField
                        id="price"
                        style={{
                          width: "30vw",
                          borderRadius: "4px",
                        }}
                        value={paymentAmt}
                        onChange={(e) => setPaymentAmt(e.target.value)}
                        onInput={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setPaymentAmt(e.target.value);
                        }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <Button
                      variant="contained"
                      className="add-button"
                      onClick={() => confirmPrice(paymentAmt)}
                    >
                      Save
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartPage;
