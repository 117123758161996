
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import { useParams } from 'react-router-dom';
import { RecordActions, RecordTypes, Roles } from '../../types/enum';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { MessageForm } from 'src/intefaces/message-form.interface';
import { useMerchant } from 'src/context/MerchantContext';


export function MsgForm(): React.JSX.Element {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [action, setAction] = React.useState<string>(RecordActions.edit);
    const { showAlert } = useAlert();
    const methods = useForm<MessageForm>({
        defaultValues: {
            "id": 0,
            "title": "Message",
            "categoryVisible": true,
            "nameVisible": true,
            "contactVisible": true,
            "emailVisible": true,
            "messageVisible": true,
            "fileVisible": true,
            "categoryLabel": "Category",
            "nameLabel": "Name",
            "contactLabel": "Contact",
            "emailLabel": "Email",
            "messageLabel": "Message",
            "fileLabel": "File",
            "dateTimeVisible": true,
            "dateTimeLabel": "Date / Time",
            "categorySeq":0,
            "nameSeq":1,
            "contactSeq":2,
            "emailSeq":3,
            "messageSeq":4,
            "fileSeq":5,
            "dateTimeSeq":6,
        }
    });
    const createNewMessageForm = async (data: any) => {
        try {

            const response = await (await axiosService.post<MessageForm | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.messageForm]}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Message Form', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Message Form Created Successfully', 'success');
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save Message Form', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editMessageForm = async (data: any) => {
        try {

            const response = await (await axiosService.put<MessageForm | any>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.messageForm]}/${data.id}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Message Form', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Message Form Created Successfully', 'success');
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save Message Form', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const getMessageFormDetails = async () => {
        try {
            const response = await axiosService.get<MessageForm>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.messageForm]}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive MessageForm Details', 'error');
            } else {
                const json: MessageForm = response.data;
                console.log("json.id", json.id)
                if (!json.id) {
                    console.log("json.id", action)
                    setAction(RecordActions.add)
                }
                methods.reset(json);
            }
        } catch (ex) {
            showAlert('Failed to Retrive MessageForm Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const onSubmit = methods.handleSubmit(async (data: any) => {
        try {
            if (loading) {
                return
            }
            setLoading(true);
            console.log("action", action)
            if (action == RecordActions.add) {
                createNewMessageForm(data);
            } else if (action == RecordActions.edit) {
                editMessageForm(data);
            }
        }
        catch (ex) {
            setLoading(false);
        } finally {

        }
    });

    React.useEffect(() => {
        getMessageFormDetails();
    }, [])
    return (
        <FormProvider {...methods} >
            <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="title"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            FormHelperTextProps={{ style: { color: 'red' } }}
                                            {...field}
                                            fullWidth
                                            id="title"
                                            label="Title"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="categoryVisible"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                            <Checkbox
                                                checked={field.value == true}
                                                {...field}
                                            />
                                            <InputLabel>Category</InputLabel>
                                        </Stack>
                                    } />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="categoryLabel"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="categoryLabel"
                                            label="Label"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="categorySeq"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="categorySeq"
                                            label="Sequence"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="nameVisible"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                            <Checkbox
                                                checked={field.value == true}
                                                {...field}
                                            />
                                            <InputLabel>Name</InputLabel>
                                        </Stack>
                                    } />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="nameLabel"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="nameLabel"
                                            label="Label"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="nameSeq"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="nameSeq"
                                            label="Sequence"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="contactVisible"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                            <Checkbox
                                                checked={field.value == true}
                                                {...field}
                                            />
                                            <InputLabel>Contact</InputLabel>
                                        </Stack>
                                    } />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="contactLabel"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="contactLabel"
                                            label="Label"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="contactSeq"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="contactSeq"
                                            label="Sequence"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="emailVisible"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                            <Checkbox
                                                checked={field.value == true}
                                                {...field}
                                            />
                                            <InputLabel>Email</InputLabel>
                                        </Stack>
                                    } />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="emailLabel"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="emailLabel"
                                            label="Label"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="emailSeq"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="emailSeq"
                                            label="Sequence"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="messageVisible"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                            <Checkbox
                                                checked={field.value == true}
                                                {...field}
                                            />
                                            <InputLabel>Message</InputLabel>
                                        </Stack>
                                    } />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="messageLabel"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="messageLabel"
                                            label="Label"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="messageSeq"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="messageSeq"
                                            label="Sequence"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="fileVisible"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                            <Checkbox
                                                checked={field.value == true}
                                                {...field}
                                            />
                                            <InputLabel>File</InputLabel>
                                        </Stack>
                                    } />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="fileLabel"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="fileLabel"
                                            label="Label"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="fileSeq"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="fileSeq"
                                            label="Sequence"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="dateTimeVisible"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                            <Checkbox
                                                checked={field.value == true}
                                                {...field}
                                            />
                                            <InputLabel>Date/Time</InputLabel>
                                        </Stack>
                                    } />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="dateTimeLabel"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="dateTimeLabel"
                                            label="Label"
                                        />} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Controller
                                    name="dateTimeSeq"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="dateTimeSeq"
                                            label="Sequence"
                                        />} />
                            </Grid>
                        </Grid>
                    </CardContent >
                </Card >

                {action != RecordActions.view && <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        zIndex: 9,
                        width: '100%',
                        backgroundColor: '#f5f5f5',
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'flex-end', // Align the button to the right
                        boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
                    }}
                >
                    <Button disabled={loading} variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Box>
                }
            </Box>
        </FormProvider >
    );
}

