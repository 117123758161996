import React, { useEffect, useState } from 'react';
import { Grid, FormControlLabel, TextField, Button, FormControl, FormLabel, Radio, RadioGroup, InputLabel, MenuItem, OutlinedInput, Select, Box, Divider, Typography, Chip, Avatar, IconButton, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack } from "@mui/material"
import { Controller, useFormContext } from 'react-hook-form';
import { FormProp } from "../../types/formprop-interface";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import axiosService from "../../services/axios";
import { CONSTANTS } from "../../constants";
import { Merchant } from "../../types/merchant-interface";
import { RecordTypes } from "../../types/enum";
import { OutletGroup, OutletGroupCreateRes } from "../../types/outlet-group-interface";
import Delete from "@mui/icons-material/Delete";
import { useAlert } from "../../components/CommonAlert";

export const AccessControl = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    const [selectedOutletOptions, setSelectedOutletOptions] = useState<number[]>([]);
    const [outletOptions, setOutletOptions] = useState<Merchant[]>([]);
    const [groups, setGroups] = useState<OutletGroup[]>([])
    const [newGroupName, setNewGroupName] = useState<string>('');
    const [expandedGroupId, setExpandedGroupId] = useState<number | null>(null);
    const watchMode = methods.watch("accessmode");
    const { showAlert } = useAlert();
    const getOutlets = async () => {
        try {
            const response = await axiosService.get<Merchant[]>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`)
            if (response.status != 200) {
                setOutletOptions([])
            } else {
                const json = response.data;
                setOutletOptions(json);
            }
        } catch (ex) {
            setOutletOptions([])
            console.error("Exception Caught", ex)
        }
    }
    const getOutletGroups = async () => {
        try {
            const response = await axiosService.get<OutletGroup[]>(`${CONSTANTS.ApiConstants.findAllGroupInfo}`)
            if (response.status != 200) {
                setGroups([])
            } else {
                const json = response.data;
                setGroups(json);
            }
        } catch (ex) {
            setGroups([])
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        getOutlets();
        getOutletGroups();
    }, []);
    const handleChange = (event: any) => {
        const selected = event.target.value;
        setSelectedOutletOptions(selected);
    };
    const handleSaveClick = async () => {
        const newGroup = { name: newGroupName || "Group", outlets: selectedOutletOptions }
        try {
            const response = await axiosService.post<OutletGroupCreateRes | any>(`${CONSTANTS.ApiConstants.outletGroupCreate}`, newGroup)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to create group', 'error');
            } else {
                const json = response.data;
                showAlert("Group Created Sucessfully", 'success');
                setTimeout(() => getOutletGroups(), 200)
                setSelectedOutletOptions([])
                setNewGroupName("");

            }
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to create group', 'error');
            console.error("Exception Caught", ex)
        }
    };
    const handleExpandClick = (groupId: number) => {
        setExpandedGroupId((prevGroupId) => (prevGroupId === groupId ? null : groupId));
    };
    const handleRemoveGroup = async (id: number) => {
        try {
            const response = await axiosService.delete<OutletGroupCreateRes | any>(`${CONSTANTS.ApiConstants.outletGroupCreate}/${id}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to create group', 'error');
            } else {
                showAlert("Group Deleted Sucessfully", 'success');
                setTimeout(() => getOutletGroups(), 200)
            }
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to create group', 'error');
            console.error("Exception Caught", ex)
        }
    }
    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="accessmode"
                        control={methods.control}
                        render={({ field, fieldState }) => <FormControl>
                            <FormLabel id="OTPMode-group-label">Mode</FormLabel>
                            <RadioGroup
                                {...field}
                                value={field.value || ''}
                                onChange={(e) => field.onChange(e.target.value)}
                            >
                                <FormControlLabel value="open" control={<Radio />} label="Open Access (All outlets can see all members)" />
                                <FormControlLabel value="restricted" control={<Radio />} label="Restricted (Outlets can view own member only, Create groups to share members between outlets)" />
                            </RadioGroup>
                        </FormControl>
                        } />

                </Grid>
            </Grid>

            <br />
            <Divider />
            <br />
            {watchMode == 'restricted' && <Grid container spacing={2}  >
                <Grid item xs={12} sm={5}>
                    <Stack>
                        <FormControl variant="outlined" sx={{ m: 2 }}>
                            <TextField
                                value={newGroupName}
                                onChange={(e) => { setNewGroupName(e.target.value.trim()) }}
                                fullWidth
                                id="groupName"
                                label="Group Name"
                            />
                        </FormControl>
                        <FormControl variant="outlined" sx={{ m: 2 }}>
                            <InputLabel id="multi-select-label">Select Users</InputLabel>
                            <Select
                                labelId="multi-select-label"
                                multiple
                                value={selectedOutletOptions}
                                onChange={handleChange}
                                input={<OutlinedInput label="Select Users" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        {(selected).map((outletid) => {
                                            const outlet = outletOptions.find((o) => o.id == outletid)
                                            if (outlet) {
                                                return <Chip key={outlet.id} label={outlet.MerchantName} />
                                            }
                                        })}
                                    </Box>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {outletOptions.map((outlet) => (
                                    <MenuItem key={outlet.id} value={outlet.id}>
                                        {outlet.MerchantName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" sx={{ m: 2 }}>
                            <Button variant={"contained"} type="button" onClick={handleSaveClick} >Create Group</Button>
                        </FormControl>
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={6} >

                    <TableContainer sx={{ ml: 10 }} elevation={0} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Group Name</TableCell>
                                    <TableCell>Number of Users</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groups && groups.length ? groups.map((group) => (
                                    <React.Fragment key={group.id}>
                                        <TableRow>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleExpandClick(group.id)}
                                                >
                                                    {expandedGroupId === group.id ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1">{group.name}</Typography>
                                            </TableCell>
                                            <TableCell>{group.outlets.length}</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => handleRemoveGroup(group.id)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                                <Collapse in={expandedGroupId === group.id} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <Typography variant="h6" gutterBottom component="div">
                                                            Users
                                                        </Typography>
                                                        <Table size="small" aria-label="users">
                                                            <TableBody>
                                                                {group.outlets.map((outlet) => (
                                                                    <TableRow key={outlet.id}>
                                                                        <TableCell component="th" scope="row">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                {/* <Avatar src={outlet.MerchantLogo} alt={outlet.MerchantName} sx={{ mr: 2 }} />
                                                                                    {editingUserId === outlet.id ? (
                                                                                        <TextField
                                                                                            value={newUserName}
                                                                                            onChange={(e) => setNewUserName(e.target.value)}
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                        />
                                                                                    ) : (
                                                                                        <Typography variant="body1">{user.name}</Typography>
                                                                                    )} */
                                                                                    <>
                                                                                        <Avatar src={outlet.MerchantLogo} alt={outlet.MerchantName} sx={{ mr: 2 }} />
                                                                                        <Typography variant="body1">{outlet.MerchantName}</Typography>
                                                                                    </>
                                                                                }
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {/* {editingUserId === user.id ? (
                                                                                    <IconButton onClick={() => handleUserSaveClick(user.id, group.id)}>
                                                                                        <Save />
                                                                                    </IconButton>
                                                                                ) : (
                                                                                    <IconButton onClick={() => handleUserEditClick(user.id, user.name)}>
                                                                                        <Edit />
                                                                                    </IconButton>
                                                                                )} */}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )) : <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                                        <Typography color={"secondary"} > No groups created yet.</Typography>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Grid>
            </Grid>}

        </>)
}