import { useParams } from "react-router-dom";
import { Card, CardContent, Grid, TextField, Button, CardActions } from "@mui/material"
import React from 'react';
import { useFormContext, Controller } from "react-hook-form";
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";

export const PaymentGateway = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
                <Controller
                    name="PayerID"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Payer ID" />
                    } /></Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="PayerKey"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Payer Key" />
                    } /></Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="StripePublicKey"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Stripe PublicKey" />
                    } /></Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="StripeSecreteKey"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Stripe SecreteKey" />
                    } /></Grid>

        </Grid>)
}