import React, { useState } from 'react';
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    TextField,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Stack,
    Typography,
    CardContent,
    Card,
    Button,
    Box,
    Checkbox,
    Avatar,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as Icons from '@mui/icons-material';
import { BottomButton } from 'src/intefaces/bottom-button.interface';
import { useForm, useFieldArray } from 'react-hook-form';
import { BottomButtonType, RecordTypes } from 'src/types/enum';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { Check } from '@phosphor-icons/react';
import IconSelector from 'src/components/IconSelector';
import { MuiColorInput } from 'mui-color-input';



type FormValues = {
    cart: {
        name: string;
        price: number;
        quantity: number;
    }[];
};
const BottomButtons: React.FC = () => {
    const [buttons, setButtons] = useState<BottomButton[]>([]);
    const [expanded, setExpanded] = useState<number | null>(null);
    const [isIconSelectorOpen, setIsIconSelectorOpen] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { showAlert } = useAlert();
    const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
    const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
    const [iconSelectorOpen, setIconSelectorOpen] = useState<string>();

    const handleIconSelect = (index: number, iconName: string, subindex: number | undefined = undefined) => {
        console.log("subindexxx", subindex);
        if (subindex != undefined && subindex >= 0)
            setValue(`bottomButtons.${index}.subButtons.${subindex}.symbol`, iconName as any); // Set the icon value in the form
        else
            setValue(`bottomButtons.${index}.symbol`, iconName); // Set the icon value in the form

    };

    const IconComponent = selectedIcon ? (Icons as any)[selectedIcon] : null;
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch
    } = useForm<{ bottomButtons: BottomButton[] }>({
        defaultValues: {
            bottomButtons: []
        },
        mode: "onBlur"
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "bottomButtons",

    });

    const handleDelete = async (id: number) => {
        setSelectedId(id);
        setOpenDeleteDlg(true);
    };
    const handleExpandClick = (index: number) => {
        setExpanded(expanded === index ? null : index);
    };
    const onDeleteConfirm = async () => {
        try {
            setOpenDeleteDlg(false)
            const response = await axiosService.delete<BottomButton[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.bottomButtons]}/${selectedId}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to update buttons', 'error');
            } else {
                getButtons();
                showAlert(' Button deleted', 'success');
            }
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to update buttons', 'error');
            console.error("Exception Caught", ex)
        } finally {
            setSelectedId(undefined);
        }
    }

    const addNewButton = () => {
        if (items.length <= 5) {
            append({
                name: '',
                feature: '',
                hyperlink: '',
                symbol: '',
                isBig: false,
                showHeader: true,
                subButtons: Array(5).fill({ name: '', hyperlink: '', symbol: '' }),
                type: BottomButtonType.STANDARD,
                sequence: items.length + 1
            })
        } else {
            showAlert("Maximum 6 buttons can be created", "error");
        }
    }
    const onSingleSubmit = (data: BottomButton, index: number) => {
        if (data.type == BottomButtonType.STANDARD) {
            data.hyperlink = data.feature;
        }
        if (data.id) {
            updateButton(data, index)
        } else {
            createButton(data, index)
        }
    };
    const items = watch('bottomButtons');

    // Function to determine if other fields should be shown
    const shouldShowFeatures = (index: number) => {
        return items[index]?.type == BottomButtonType.STANDARD;

    };
    const shouldShowHyperlink = (index: number) => {
        return items[index]?.type == BottomButtonType.SELFDEFINE;
    };

    const shouldSubButtons = (index: number) => {
        return items[index]?.type == BottomButtonType.SELFSUBBUTTON;
    };

    const getButtons = async () => {
        try {
            const response = await axiosService.get<BottomButton[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.bottomButtons]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get buttons', 'error');
                setValue("bottomButtons", [])
            } else {
                const json = response.data;
                setValue("bottomButtons", json)
            }
        } catch (ex: any) {
            setValue("bottomButtons", [])
            showAlert(ex?.response?.data?.message ?? 'Failed to get buttons', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const createButton = async (data: BottomButton, index: number) => {
        try {
            const response = await axiosService.post<BottomButton[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.bottomButtons]}`, data)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to create buttons', 'error');
            } else {
                setValue(`bottomButtons.${index}`, response?.data);
                showAlert('Created button successfully', 'success');
            }
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to create buttons', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const updateButton = async (data: BottomButton, index: number) => {
        try {
            const response = await axiosService.put<BottomButton[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.bottomButtons]}/${data.id}`, data)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to update buttons', 'error');
            } else {
                showAlert(' Button updated', 'success');
            }
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to update buttons', 'error');
            console.error("Exception Caught", ex)
        }
    }

    React.useEffect(() => {
        getButtons()
    }, [])

    return (

        <Box component={'form'} >
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Bottom Buttons</Typography>
                </Stack>
            </Stack>
            <Card>
                <CardContent>
                    <List key={`bottomButtons`}>
                        {fields.map((button, index) => (
                            <div key={index}>
                                <ListItem button onClick={() => handleExpandClick(index)} key={`bottomButtons${index}`}>
                                    <Stack direction={"row"} sx={{ justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <Stack direction={"row"} sx={{ justifyContent: "start", alignItems: "center" }}>
                                            <Avatar
                                                sx={{
                                                    bgcolor: 'primary.main', // Circle background color
                                                    width: 25,                // Circle width
                                                    height: 25,               // Circle height
                                                }}
                                            >
                                                {items[index].sequence}
                                            </Avatar>
                                            &nbsp;
                                            &nbsp;
                                            {items[index].symbol && (
                                                React.createElement(Icons[(items[index].symbol) as keyof typeof Icons])
                                            )}
                                            &nbsp;
                                            <Typography variant='h6'>{items[index].name}</Typography>

                                            &nbsp;
                                            <Typography variant="subtitle1" >({items[index].type})</Typography>

                                        </Stack>
                                        <Stack direction={"row"} sx={{ justifyContent: "end", alignItems: "center" }}>
                                            {items[index].id && <IconButton edge="end" onClick={() => handleDelete(items[index].id || 0)}>
                                                <DeleteIcon />
                                            </IconButton>}
                                            {expanded === index ? <ExpandLess /> : <ExpandMore />}
                                        </Stack>
                                    </Stack>

                                </ListItem>
                                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                    <TextField
                                        label="Button Name"
                                        sx={{ display: "none" }}
                                        {...register(`bottomButtons.${index}.id`)}
                                        fullWidth
                                    />
                                    <Grid container spacing={2} padding={2}>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Button Name"
                                                {...register(`bottomButtons.${index}.name`, {
                                                    required: true
                                                })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {/* <div>
                                                <IconAutoSuggest onSelectIcon={handleSelectIcon} />
                                                {selectedIcon && SelectedIconComponent && (
                                                    <div style={{ marginTop: '20px' }}>
                                                        <Typography variant="h6">Selected Icon:</Typography>
                                                        <SelectedIconComponent fontSize="large" />
                                                        <Typography>{selectedIcon}</Typography>
                                                    </div>
                                                )}
                                            </div> */}
                                            <div {...register(`bottomButtons.${index}.symbol`)} key={`bottomButtons.${index}.symbol`}>

                                                <Button variant="outlined" onClick={() => setIconSelectorOpen(`bottomButtons.${index}`)}>
                                                    Select Icon
                                                </Button>
                                                {items[index].symbol && (
                                                    <IconButton onClick={() => setIconSelectorOpen(`bottomButtons.${index}`)}>
                                                        {React.createElement(Icons[(items[index].symbol) as keyof typeof Icons])}
                                                    </IconButton>
                                                )}

                                                <IconSelector
                                                    key={"parent" + index}
                                                    id={"parent" + index}
                                                    open={iconSelectorOpen == `bottomButtons.${index}`}
                                                    onClose={() => setIconSelectorOpen("")}
                                                    onSelect={(iconname) => handleIconSelect(index, iconname)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>Button Type</InputLabel>
                                                <Select
                                                    {...register(`bottomButtons.${index}.type`)}
                                                    value={items[index].type}
                                                >
                                                    <MenuItem value={BottomButtonType.STANDARD}>Standard</MenuItem>
                                                    <MenuItem value={BottomButtonType.SELFDEFINE}>Self Define</MenuItem>
                                                    <MenuItem value={BottomButtonType.SELFSUBBUTTON}>Self Define Sub Button</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {shouldShowFeatures(index) && <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth key={`bottomButtons${index}feature.control`}>
                                                <InputLabel key={`bottomButtons${index}featurelabel`}>Select Button Feature</InputLabel>
                                                <Select
                                                    key={`bottomButtons${index}featureselect`}
                                                    {...register(`bottomButtons.${index}.feature`)}
                                                    value={items[index].feature}
                                                >
                                                    {Object.keys(CONSTANTS.BOTTOMBUTTONFEATURE).map((k, index) => {
                                                        return <MenuItem value={k}>{CONSTANTS.BOTTOMBUTTONFEATURE[k]}</MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>}

                                        {shouldShowHyperlink(index) && <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Button Hyperlink"
                                                {...register(`bottomButtons.${index}.hyperlink`)}

                                                fullWidth
                                            />
                                        </Grid>}
                                        <Grid item xs={12} sm={6} md={2}>
                                            <TextField
                                                label="Sequence"
                                                {...register(`bottomButtons.${index}.sequence`)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={2}>
                                            <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                                <Checkbox
                                                    checked={items[index].isBig}
                                                    {...register(`bottomButtons.${index}.isBig`)}
                                                />
                                                <InputLabel>Is Big</InputLabel>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={2}>
                                            <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                                <Checkbox
                                                    checked={items[index].showHeader}
                                                    {...register(`bottomButtons.${index}.showHeader`)}
                                                />
                                                <InputLabel>Show Header</InputLabel>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={2}>
                                            <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                                <Checkbox
                                                    checked={items[index].showStatusBar}
                                                    {...register(`bottomButtons.${index}.showStatusBar`)}
                                                />
                                                <InputLabel>Show Status Bar</InputLabel>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4}>
                                            <Stack direction={"row"} sx={{ alignItems: "center" }} >
                                                <MuiColorInput style={{flex:1,maxWidth:"200px"}} format="hex"  {...register(`bottomButtons.${index}.statusBarColor`)} onChange={(value) => setValue(`bottomButtons.${index}.statusBarColor`, value)} value={items[index]?.statusBarColor || "#000"} /> &nbsp;
                                                <InputLabel >Statusbar ColorCode</InputLabel>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    {shouldSubButtons(index) && <Grid container spacing={2} padding={2}>
                                        {items[index].subButtons && items[index].subButtons.map((subButton, subIndex) => {

                                            return (
                                                <Grid container spacing={2} key={subIndex}>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            label="Name"
                                                            {...register(`bottomButtons.${index}.subButtons.${subIndex}.name`)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            label="Link"
                                                            {...register(`bottomButtons.${index}.subButtons.${subIndex}.hyperlink`)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <div {...register(`bottomButtons.${index}.subButtons.${subIndex}.symbol`)} key={`bottomButtons.${index}.subButtons.${subIndex}.symbol`}>

                                                            <Button variant="outlined" onClick={() => setIconSelectorOpen(`bottomButtons.${index}.subButtons.${subIndex}`)} key={`bottomButtons.${index}.subButtons.${subIndex}.symbol.button`}>
                                                                Select Icon
                                                            </Button>
                                                            {items[index].subButtons[subIndex].symbol && (
                                                                <IconButton onClick={() => setIconSelectorOpen(`bottomButtons.${index}.subButtons.${subIndex}`)}>
                                                                    {React.createElement(Icons[(items[index].subButtons[subIndex].symbol) as keyof typeof Icons])}
                                                                </IconButton>
                                                            )}

                                                            <IconSelector
                                                                key={"child" + `bottomButtons.${index}.subButtons.${subIndex}.symbol`}
                                                                id={"child" + `bottomButtons.${index}.subButtons.${subIndex}.symbol`}
                                                                open={iconSelectorOpen == `bottomButtons.${index}.subButtons.${subIndex}`}
                                                                onClose={() => setIconSelectorOpen("")}
                                                                onSelect={(iconname) => handleIconSelect(index, iconname, subIndex)}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>)
                                        })}
                                    </Grid>}
                                    <Grid container>
                                        <Grid item sx={{ textAlign: "end" }} padding={2}>
                                            <Button
                                                variant='contained'
                                                type="button"
                                                onClick={handleSubmit((data) => onSingleSubmit(items[index], index))}>
                                                Save
                                            </Button>
                                        </Grid></Grid>
                                </Collapse>
                            </div>
                        ))}
                    </List>
                    <Button variant='text' color="primary" onClick={() => { addNewButton(); handleExpandClick(fields.length) }}>
                        <AddIcon /> Add New Button
                    </Button>
                </CardContent>
            </Card>
            <br />
            <br />
            <br />
            <br />
            <br />
            <DeleteConfirmationDialog
                open={openDeleteDlg}
                onClose={() => { setOpenDeleteDlg(false); setSelectedId(undefined) }}
                onConfirm={onDeleteConfirm}
                confirmLabel={"Delete"}
                cancelLabel={"Cancel"}
                message={"Are you sure you want to delete merchant"}
                title={"Confirm Delete"}
            />
        </Box >
    );
};

export default BottomButtons;
