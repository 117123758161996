import * as React from "react";
import { useSelection } from "../../hooks/use-selection";
import { Merchant } from "../../types/merchant-interface";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import DataTable from "../../components/DataTable";
import { CONSTANTS } from "../../constants";
import axiosService from "../../services/axios";
import { useAlert } from "../../components/CommonAlert";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { RecordTypes } from "../../types/enum";
function noop(): void {
  // do nothing
}

interface MerchantsTableProps {
  count?: number;
  page?: number;
  rows?: Merchant[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any;
  recordType: "merchant" | "outlet" | "partner";
}

export function MerchantsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback,
  recordType = "merchant",
}: MerchantsTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((merchant) => merchant.id);
  }, [rows]);

  const { selectAll, deselectAll, selectOne, deselectOne, selected } =
    useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome =
    (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const selectedAll = rows.length > 0 && selected?.size === rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(
    undefined
  );
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const columns = [
    { id: "id", label: "Id" },
    { id: "MerchantName", label: "Name" },
    { id: "MerchantCode", label: "Code" },
    { id: "MerchantEmail", label: "Email" },
    { id: "MerchantPhone1", label: "phone" },
    { id: "username", label: "Username" },
  ];
  const handleEdit = (id: number) => {
    switch (recordType) {
      case "merchant":
        navigate(`/merchants/edit/${id}`);
        break;
      default:
        navigate(`/${recordType}/edit/${id}`);
        break;
    }
  };
  const handleDelete = async (id: number) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  const onDeleteConfirm = async () => {
    try {
      setOpenDeleteDlg(false);
      const response = await axiosService.delete<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.delete[recordType]}/${selectedId}`
      );
      if (response.status != 200) {
        showAlert("Failed to Delete Merchant", "error");
      } else {
        showAlert("Merchant Deleted Successfully", "success");
        onDeleteCallback && onDeleteCallback();
      }
    } catch (ex) {
      showAlert("Failed to Delete Merchant", "error");
      console.error("Exception Caught", ex);
    } finally {
      setSelectedId(undefined);
    }
  };

  const handleView = (id: number) => {
    switch (recordType) {
      case "merchant":
        navigate(`/merchants/view/${id}`);
        break;
      default:
        navigate(`/${recordType}/view/${id}`);
        break;
    }
  };
  return (
    <div>
      <DataTable
        rows={rows}
        columns={columns}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
      />
      <DeleteConfirmationDialog
        open={openDeleteDlg}
        onClose={() => {
          setOpenDeleteDlg(false);
          setSelectedId(undefined);
        }}
        onConfirm={onDeleteConfirm}
        message={"Are you sure you want to delete merchant"}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        title={"Confirm Delete"}
      />
    </div>
  );
}
