import * as React from "react";
import { useSelection } from "../../hooks/use-selection";
import { Staff } from "../../types/staff-interface";
import DataTable from "../../components/DataTable";
// import { CONSTANTS } from "../../constants";
// import axiosService from "../../services/axios";
import { useAlert } from "../../components/CommonAlert";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { deleteData } from "src/services/api.service";
// import { RecordTypes } from "../../types/enum";

interface StaffsTableProps {
  count?: number;
  page?: number;
  rows?: Staff[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any;
}

export function StaffsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback,
}: StaffsTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((staff) => staff.id);
  }, [rows]);
  const navigate = useNavigate();
  const { selected } = useSelection(rowIds);
  const { showAlert } = useAlert();
  // const selectedSome =
  //   (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(
    undefined
  );
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);

  const columns = [
    { id: "id", label: "#" },
    { id: "firstName", label: "Firt Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "gender", label: "Gender" },
  ];

  const handleEdit = (id: number) => {
    navigate(`/staff/edit/${id}`);
  };

  const handleDelete = async (id: number) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  // const handleView = (id: number) => {
  //   navigate(`/staff/view/${id}`);
  // };

  async function onDeleteConfirm() {
    try {
      setOpenDeleteDlg(false);
      const response = await deleteData(`/staff/delete/${selectedId}`);
      if (response && response.data && response.data.success) {
        showAlert("Staff Deleted Successfully", "success");
        onDeleteCallback && onDeleteCallback();
      } else {
        showAlert("Failed to Delete Staff", "error");
        console.error("Error in API call: ", response.data.message);
      }
    } catch (error) {
      showAlert("Failed to Delete Staff", "error");
      console.error("Error deleting staff: ", error);
    } finally {
      setSelectedId(undefined);
    }
  }

  return (
    <div>
      <DataTable
        rows={rows}
        columns={columns}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={() => {}}
      />
      <DeleteConfirmationDialog
        open={openDeleteDlg}
        onClose={() => {
          setOpenDeleteDlg(false);
          setSelectedId(undefined);
        }}
        onConfirm={onDeleteConfirm}
        message={"Are you sure you want to delete Staff"}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        title={"Confirm Delete"}
      />
    </div>
  );
}
