import React from 'react';
import { Paper, Box, Typography, Avatar } from '@mui/material';
import { LocationOn, Phone, Email, Padding, ArrowForward } from '@mui/icons-material';
import { Merchant } from 'src/types/merchant-interface';
import { red, blue, green } from '@mui/material/colors';

const ContactInfo: React.FC<any> = ({ merchant }: { merchant: Merchant }) => {
    const openGoogleMaps = () => {
        const zoom = 14;
        const googleMapsUrl = `https://www.google.com/maps/@${merchant.MerchantLatitude},${merchant.MerchantLongitude},${zoom}z`;
        window.open(googleMapsUrl, '_blank');
    };
    const openPhoneDialer = (phone: string) => {
        window.location.href = `tel:${phone}`;
    };
    const openEmail = () => {
        window.location.href = `mailto:${merchant?.MerchantEmail}`;
    };
    return ( <Paper elevation={0} sx={{ pl: 2 ,pr: 2 , pb: 0}}>
        {merchant.MerchantAddress && <Paper elevation={3} sx={{ p: 1, mb: 2 }}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={openGoogleMaps}>
                <Box display="flex" alignItems="center"  >
                    <Avatar sx={{ bgcolor: "#faebe6", mr: 1, color: "#eb693b" }}>
                        <LocationOn />
                    </Avatar>
                    <Typography variant="body2">{merchant.MerchantAddress}</Typography>
                </Box>

                <ArrowForward />
            </Box>
        </Paper>}
        {merchant.MerchantPhone1 && <Paper elevation={3} sx={{ p: 1, mb: 2 }}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={() => openPhoneDialer(merchant.MerchantPhone1)}>
                <Box display="flex" alignItems="center"  >

                    <Avatar sx={{ bgcolor: "#faebe6", mr: 1, color: "#eb693b" }}>
                        <Phone />
                    </Avatar>

                    {merchant.MerchantPhone1 && <Typography variant="body2">{merchant.MerchantPhone1}</Typography>}

                </Box>
                <ArrowForward />

            </Box>

        </Paper>
        }
        {merchant.MerchantPhone2 && <Paper elevation={3} sx={{ p: 1, mb: 2 }}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={() => openPhoneDialer(merchant.MerchantPhone2)}>
                <Box display="flex" alignItems="center"  >

                    <Avatar sx={{ bgcolor: "#faebe6", mr: 1, color: "#eb693b" }}>
                        <Phone />
                    </Avatar>

                    <Typography variant="body2">{merchant.MerchantPhone2}</Typography>

                </Box>
                <ArrowForward />
            </Box>
        </Paper >
        }

        {merchant?.MerchantEmail && <Paper elevation={3} sx={{ p: 1, mb: 2 }}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={openEmail}>
                <Box display="flex" alignItems="center"  >

                    <Avatar sx={{ bgcolor: "#faebe6", mr: 1, color: "#eb693b" }}>
                        <Email />
                    </Avatar>
                    <Typography variant="body2">{merchant?.MerchantEmail}</Typography>
                </Box>
                <ArrowForward />
            </Box>
        </Paper>
        }
    </Paper >
    )
};

export default ContactInfo;
