import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { TokenObject } from 'src/types/token-object';

interface ProtectedRouteProps {
  roles?: string[];
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles = [], element }) => {
  const { getToken, isTokenValid } = useAuth();

  const token = getToken();
  let user;
  if (token && isTokenValid(token)) {
    const decoded: TokenObject = jwtDecode(token);
    user = decoded;
    const isAuthorized = (roles.length === 0 || roles.includes(user?.role || ''));
    if (!isAuthorized) {
      return <Navigate to="/unauthorized" replace />;
    }
  } else {
    return <Navigate to="/login" replace />;
  }

  return element;
};

export default ProtectedRoute;
