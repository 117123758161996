import { TextFieldsTwoTone } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link, Box, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, Drawer, Grid, InputAdornment, TextField, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Autocomplete } from "@mui/material"
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAlert } from "src/components/CommonAlert";
import { CONSTANTS } from "src/constants";
import { Coupon, CouponMapping } from "src/intefaces/coupon.interface";
import axiosService from "src/services/axios";
import { RecordActions, RecordTypes } from "src/types/enum";
import { Member } from "src/types/member-interface";
export const MemberCoupons: React.FC<{ member: Member }> = ({ member }) => {
    const { showAlert } = useAlert();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [couponMaster, setCouponMaster] = useState<Coupon[]>([]);
    const [memberCoupons, setMemberCoupons] = useState<CouponMapping[]>([]);
    const [validAfterDay, setValidAfterDay] = useState<string>("0");
    const [validTillDay, setValidTillDay] = useState<string>("0");
    const toggleDrawer = (open: boolean) => {
        setIsOpen(open);
    };
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const fetchCoupons = async () => {
        try {
            const response = await axiosService.get<CouponMapping[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.couponMapping]}/${member?.id}`)
            if (response.status != 200) {
                setMemberCoupons([]);
            } else {
                const json = response.data;
                setMemberCoupons(json);
            }
        } catch (ex: any) {
            setMemberCoupons([]);
            // showAlert(ex?.response?.data?.message ?? 'Failed to get coupons', 'error');
            console.error("Exception Caught", ex)
        }
    };
    const handleAddCoupon = (id: number) => {
        toggleDrawer(true);
    };
    const getCoupons = async () => {
        try {
            const response = await axiosService.get<Coupon[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.coupon]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get coupons', 'error');
                setCouponMaster([])
            } else {
                const json = response.data;

                setCouponMaster(json);
            }
        } catch (ex: any) {
            setCoupons([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get coupons', 'error');
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        fetchCoupons();
        getCoupons();
    }, [])
    const methods = useForm<CouponMapping>({
        defaultValues: {
            id: 0,
            coupon_id: undefined,
            validFrom: moment(),
            validTill: moment(),
            member_id: undefined,
            redeemed: false
        }
    });
    const onAssign = methods.handleSubmit(async (data: any) => {
        try {
            const payload = {...data};
            payload.coupon_id = data?.coupon?.id
            const response = await (await axiosService.post<CouponMapping | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.couponMapping]}`, payload))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Coupon', 'error');
            } else {
                const json = response.data;
                fetchCoupons();
                setLoading(false);
                showAlert('Coupon Assigned Successfully', 'success');
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save Coupon', 'error');
            console.error("Exception Caught", ex)
        }
    })

    const validAfterWatch = methods.watch("validFrom")
    const validUntilWatch = methods.watch("validTill")
    // useEffect(() => {
    //     if (validAfterWatch)
    //         setValidAfterDay(moment(validAfterWatch).diff(moment(), "days").toString())
    //     if (validUntilWatch)
    //         setValidTillDay(moment(validUntilWatch).diff(validAfterWatch, "days").toString())
    //     console.log(moment(validUntilWatch).diff(validAfterWatch, "days"));
    // }, [validAfterWatch, validUntilWatch])
    useEffect(() => {
        if (member) {
            methods.setValue("member_id", member?.id);
        }
    }, [member])
    useEffect(() => {
        if (moment(validAfterWatch).diff(moment(), "days") != Number(validAfterDay))
            methods.setValue("validFrom", moment().add(validAfterDay ?? 0, "days"))
        if (moment(validAfterWatch).diff(moment(), "days") != Number(validTillDay))
            methods.setValue("validTill", moment().add(validTillDay ?? 0, "days"))
    }, [validTillDay, validAfterDay])
    return (<Box margin={1}>
        <Typography variant="h6" gutterBottom component="div">
            Coupons
        </Typography>
        <Button variant="outlined" color="primary" onClick={() => handleAddCoupon(1)}>
            Add New Coupon
        </Button>
        <Table size="small" aria-label="coupons">
            <TableHead>
                <TableRow>
                    <TableCell>Coupon</TableCell>
                    <TableCell>Valid From</TableCell>
                    <TableCell>Valid Till</TableCell>
                    <TableCell>Redeemed</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {memberCoupons?.map((coupon) => (
                    <TableRow key={coupon.id}>
                        <TableCell>{<Link href={`/coupons/view/${coupon.id}`} target="_blank" >{coupon.coupon_name}</Link>}</TableCell>
                        <TableCell>{coupon?.validFrom ? moment(coupon?.validFrom ?? null).utc(true).local().format("D-M-yyyy") : ""}</TableCell>
                        <TableCell>{coupon?.validTill ? moment(coupon?.validTill ?? null).utc(true).local().format("D-M-yyyy") : ""}</TableCell>
                        <TableCell>{coupon.redeemed?"Yes":"No"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={() => toggleDrawer(false)}
        >
            <Box
                sx={{ width: "50vw", p: 2 }}
                role="presentation"
            >    <Card>
                    <CardContent>
                        <Typography variant="h6">Assign Coupon to {member?.MemberName}</Typography>
                        <FormProvider {...methods} >
                            <Box component="form" onSubmit={onAssign} key="assignForm">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Controller
                                            name="coupon"
                                            control={methods.control}
                                            render={({ field, fieldState }) =>
                                                <Autocomplete
                                                    onChange={(_, newValue) => field.onChange(newValue)}
                                                    value={field.value || null} // Ensure value is a Coupon object or null
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    options={couponMaster}
                                                    getOptionLabel={(option) => option.name}
                                                    loading={loading}
                                                    renderInput={(params) => <TextField {...params} label="Select Coupon" variant="outlined" />}
                                                />
                                            } />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            fullWidth
                                            onChange={e => { setValidAfterDay(isNaN(Number(e.target.value)) ? "0" : e.target.value) }}
                                            id="validAfterDay"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        Days
                                                    </InputAdornment>
                                                )
                                            }}
                                            label="Valid date will take effect after"
                                            value={validAfterDay}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Controller
                                            name="validFrom"
                                            control={methods.control}
                                            render={({ field, fieldState }) =>
                                                <DatePicker
                                                    readOnly={true}
                                                    sx={{ width: "100%" }}
                                                    {...field}
                                                    label="Valid From"
                                                />
                                            } />
                                    </Grid>

                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            fullWidth
                                            id="validTillDay"
                                            label="Valid Duration"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        Days
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={e => { setValidTillDay(isNaN(Number(e.target.value)) ? "0" : e.target.value) }}
                                            value={validTillDay}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Controller
                                            name="validTill"
                                            control={methods.control}
                                            render={({ field, fieldState }) =>
                                                <DatePicker
                                                    readOnly={true}
                                                    sx={{ width: "100%" }}
                                                    {...field}
                                                    label="Valid Until"
                                                />
                                            } />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Button variant="contained" color="primary" type="submit" onClick={onAssign}>
                                            Assign
                                        </Button>
                                        &nbsp;
                                        &nbsp;
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => toggleDrawer(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Box>
                        </FormProvider>

                    </CardContent>
                </Card>
            </Box>
        </Drawer>
    </Box>
    )
}