import React, { useState, useRef, useEffect, useCallback } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

// import '@emoji-mart/react/styles/emoji-mart.css'; // Adjust if necessary

interface EmojiPickerButtonProps {
  onEmojiSelect: (emoji: any) => void;
}

export const EmojiPickerButton: React.FC<EmojiPickerButtonProps> = ({ onEmojiSelect }) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const handleButtonClick = () => {
    //console.log("clicked",showPicker)
    setShowPicker(!showPicker); // Toggle picker visibility
  };

  const handleEmojiSelect = (emoji: any) => {
    console.log("clicked", emoji)
    onEmojiSelect(emoji); // Pass the selected emoji to the parent component
    setShowPicker(false); // Close picker after selection
  };
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (pickerRef && pickerRef.current && !pickerRef.current.contains(event.target as Node) && !buttonRef.current?.contains(event.target as Node)) {
      setShowPicker(false);
    }
  }, []);

  useEffect(() => {
    if (showPicker) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showPicker, handleClickOutside]);

  useEffect(() => {
    if (showPicker && pickerRef && pickerRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const picker = pickerRef.current;

      // Calculate the best position for the picker
      const isBelowSpace = window.innerHeight - buttonRect.bottom > picker.clientHeight;
      const isAboveSpace = buttonRect.top > picker.clientHeight;
      const isLeftSpace = buttonRect.left > picker.clientWidth;
      const isRightSpace = window.innerWidth - buttonRect.right > picker.clientWidth;

      let top = 0;
      let left = 0;

      // Determine the top position
      if (isBelowSpace) {
        top = buttonRect.bottom + window.scrollY;
      } else if (isAboveSpace) {
        top = buttonRect.top - picker.clientHeight + window.scrollY;
      } else {
        top = window.innerHeight - picker.clientHeight + window.scrollY;
      }

      // Determine the left position
      if (isRightSpace) {
        left = buttonRect.left;
      } else if (isLeftSpace) {
        left = buttonRect.right - picker.clientWidth;
      } else {
        left = window.innerWidth - picker.clientWidth;
      }

      picker.style.top = `${top}px`;
      picker.style.left = `${left}px`;
    }
  }, [showPicker]);

  return (
    <div >
      <button ref={buttonRef} onClick={handleButtonClick} type="button" style={{ cursor: 'pointer' }}>
        😀
      </button>
      {showPicker && (
        <div ref={pickerRef} style={{ position: 'absolute', zIndex: 9 }}>
          <Picker onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
    </div>
  );
};

