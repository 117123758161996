import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context type
interface StyleContextType {
  childStyle: string;
  setChildStyle: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context with a default value
const StyleContext = createContext<StyleContextType | undefined>(undefined);

interface StyleProviderProps {
  children: ReactNode;
}

export const StyleProvider: React.FC<StyleProviderProps> = ({ children }) => {
  const [childStyle, setChildStyle] = useState<string>("");

  return (
    <StyleContext.Provider value={{ childStyle, setChildStyle }}>
      {children}
    </StyleContext.Provider>
  );
};

// Custom hook to use the StyleContext
export const useStyle = (): StyleContextType => {
  const context = useContext(StyleContext);
  if (!context) {
    throw new Error("useStyle must be used within a StyleProvider");
  }
  return context;
};
