import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Grid, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { useMerchant } from 'src/context/MerchantContext';

const MemberSignIn = () => {
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const { memberLogin } = useAuth();
    const navigate = useNavigate();
    const { merchant } = useMerchant();
    const location = useLocation();

    // Get the 'from' location state from the location object, if available
    const from = location.state?.from || '/';
    const handleSubmit = (event: any) => {
        memberLogin(mobile, password, merchant?.id || 0).then((isLoggedIn: string) => {
            if (isLoggedIn == "true") {
                setMessage('');
                if (from)
                    navigate(from, { replace: true });
                else
                    navigate(`/${merchant?.id}`);
            }
            else {
                setMessage(isLoggedIn)
            }

        }).catch((ex: any) => {
            setMessage(ex.message)
        });
        event.preventDefault();
    };

    return (
        <Container maxWidth="sm">
            <Grid container spacing={2} sx={{ minHeight: '100vh' }}>
                <Grid item xs={12} md={6} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '80%' }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="mobile"
                            label="Username"
                            name="mobile"
                            autoFocus
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Typography color="red">
                            {message}
                        </Typography>
                        <br/>
                        <br/>
                        <br/>
                        <Typography>
                            Not a Member yet? 
                        <Button
                            onClick={()=>navigate(`/${merchant?.id}/membersignup`)}
                            variant="text"
                            color="primary"
                        >
                            Register here
                        </Button>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MemberSignIn;
