// src/components/Home.tsx
import { AppBar, Toolbar, Stack, IconButton, Typography, Avatar, Box, Divider, MenuItem, Button, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { useNavigate } from 'react-router-dom';
import { NoticeForm } from './notice-form';
import NoticeTabs from './notice-tabs';
import { Tabs as TabsIcon } from '@phosphor-icons/react/dist/ssr/Tabs';

export const NoticeBoardForm: React.FC = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setIsOpen(open);
    };
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">NoticeBoard Details</Typography>
                </Stack>
                <div>
                    <Button
                        startIcon={<TabsIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => toggleDrawer(true)}>
                        Tabs
                    </Button>
                    <Button
                        startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/noticeBoards')}
                    >
                        View All
                    </Button>
                </div>
            </Stack>
            <NoticeForm />

            <Drawer
                anchor="right"
                open={isOpen}
                onClose={() => toggleDrawer(false)}
            >
                <Box
                    sx={{ width: "50vw", p: 2 }}
                    role="presentation"
                >
                    <NoticeTabs />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => toggleDrawer(false)}
                        fullWidth
                        style={{ marginTop: 16 }}
                    >
                        Close
                    </Button>
                </Box>
            </Drawer>
        </Stack>
    );
};
