import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { UserPlus as UserPlus } from "@phosphor-icons/react";
import { MagnifyingGlass as Search } from "@phosphor-icons/react";

import { CONSTANTS } from "../../constants";
import { MembersTable } from "./member-table";
import { useNavigate } from "react-router-dom";
import axiosService from "../../services/axios";
import { Member } from "../../types/member-interface";
import { Box, Card, OutlinedInput, InputAdornment } from "@mui/material";
import { useAlert } from "../../components/CommonAlert";
import MemberInviteDialog from "../../components/ConfirmationDialog";
import { useAuth } from "../../context/AuthContext";
import { Roles } from "src/types/enum";
import moment from "moment";

export default function MembersPage(): React.JSX.Element {
  const page = 0;
  const rowsPerPage = 25;
  const [members, setMembers] = React.useState<Member[]>([]);
  const [openInvite, setOpenInvite] = React.useState<boolean>(false);
  const [filteredData, setFilteredData] = React.useState<Member[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [inviteLInk, setInviteLink] = React.useState("");
  const { user } = useAuth();

  const { showAlert } = useAlert();
  const getInviteLink = async () => {
    try {
      let meta = "";
      console.log("user", user);
      switch (user?.role) {
        case Roles.merchant:
          meta = `mid${user.account.id}`;
          break;
        case Roles.outlet:
          meta = `oid${user.account.id}`;
          break;
        case Roles.staff:
          meta = `sid${user.account.id}`;
          break;
        default:
          break;
      }
      setInviteLink(
        `${process.env.REACT_APP_APP_BASE_URL}/mregister/${encodeURIComponent(
          btoa(meta)
        )}`
      );
    } catch (ex: any) {
      showAlert(
        ex?.response?.data?.message ?? "Failed to create link",
        "error"
      );
      console.error("Exception Caught", ex);
      setInviteLink("");
    }
  };
  const getMembers = async () => {
    try {
      const response = await axiosService.get<Member[] | any>(
        `${CONSTANTS.ApiConstants.getMembers}`
      );
      if (response.status != 200) {
        showAlert(response?.data?.message ?? "Failed to get members", "error");
        setMembers([]);
        setFilteredData([]);
      } else {
        const json = response.data;

        setMembers(json);
        setFilteredData(json);
      }
    } catch (ex: any) {
      setMembers([]);
      setFilteredData([]);
      showAlert(
        ex?.response?.data?.message ?? "Failed to get members",
        "error"
      );
      console.error("Exception Caught", ex);
    }
  };
  React.useEffect(() => {
    getMembers();
  }, []);
  const onCopy = () => {
    navigator.clipboard
      .writeText(inviteLInk)
      .then(() => {
        setOpenInvite(false);
      })
      .catch((err) => {
        setOpenInvite(false);
        showAlert("Failed to copy!", "error");
        console.log("failed to copy", err);
      });
  };
  React.useEffect(() => {
    setFilteredData(
      members.filter((item: Member) => {
        console.log("item?.dateFrom", item);
        return (
          (item?.DOB &&
            item?.DOB.toString().includes(searchQuery.toLowerCase())) ||
          (item?.Address1 &&
            item?.Address1.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.Address2 &&
            item?.Address2.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.Country &&
            item?.Country.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.Email &&
            item?.Email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.IdNumber &&
            item?.IdNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.MemberName &&
            item?.MemberName.toLowerCase().includes(
              searchQuery.toLowerCase()
            )) ||
          (item?.MemberLocation &&
            item?.MemberLocation.toLowerCase().includes(
              searchQuery.toLowerCase()
            )) ||
          (item?.Mobile &&
            item?.Mobile.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      })
    );
  }, [searchQuery]);
  const onDeleteCallback = () => getMembers();
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Members</Typography>
        </Stack>

        <div style={{ marginRight: "27px" }}>
          <Button
            startIcon={<Search fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={() => {
              navigate("/members/globalsearch");
            }}
          >
            Global Search
          </Button>
          <Button
            startIcon={<UserPlus fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={() => {
              setOpenInvite(true);
              getInviteLink();
            }}
          >
            Invite Member
          </Button>
          <Button
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={() => navigate("/members/add")}
          >
            Add
          </Button>
        </div>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search member"
            startAdornment={
              <InputAdornment position="start">
                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <MembersTable
        count={filteredData?.length ?? 0}
        page={page}
        rows={filteredData}
        rowsPerPage={rowsPerPage}
        onDeleteCallback={onDeleteCallback}
      />
      <MemberInviteDialog
        open={openInvite && !!inviteLInk}
        onClose={() => {
          setOpenInvite(false);
        }}
        onConfirm={onCopy}
        confirmLabel={"Copy Link"}
        cancelLabel={"Cancel"}
        message={`Copy and share the link :  ${inviteLInk}`}
        title={"Invite Member"}
      />
    </Stack>
  );
}
