import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  Stack,
  Typography,
  Box,
  Card,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { deleteData, get, post, put } from "../../services/api.service";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import { Add, Search } from "@mui/icons-material";
import { UserPlus } from "@phosphor-icons/react";
import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
// import DeleteDialog from "../../components/form-control/deleteDialog";

const GroupsPage: React.FC = () => {
  const [AddDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [EditDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [branchData, setBranchData] = useState<any[]>([]);
  const [groupName, setGroupName] = useState("");
  const [totalPerson, setTotalPerson] = useState("");
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState("");

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const rowsPerPageOptions = [10, 25, 50, 100];

  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(groupData.length, (page + 1) * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const confirmDelete = (rowData: any) => {
    setSelectedId(rowData.id);
    setDeleteDialogVisible(true);
  };

  const openAddNewGroup = () => {
    resetFileds();
    setAddDialogVisible(true);
  };

  const confirmEdit = (rowData: any) => {
    setEditDialogVisible(true);
    setSelectedId(rowData.id);
    getDataByGroupId(rowData.id);
    setIsEdit(true);
  };

  const onDeleteCancel = () => {
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setDeleteDialogVisible(false);
    setIsEdit(false);
  };

  function resetFileds() {
    setSelectedBranch("");
    setGroupName("");
    setTotalPerson("");
  }

  async function getBranchList() {
    try {
      let url = "/branch/dropdown";

      const response: any = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setBranchData(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function addGroup() {
    try {
      const response = await post("/group", {
        name: groupName,
        totalperson: parseFloat(totalPerson),
        branch_id: selectedBranch,
      });
      if (response && response.data && response.data.success) {
        setAddDialogVisible(false);
        getAllGroupData();
      } else {
        console.error("Error in Api call: ", response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllGroupData() {
    try {
      let url = "/group/list";
      const response = await get(url);
      if (response && response.data) {
        console.log(response.data, "here is group response");

        if (response.data.success) {
          setGroupData(response.data.data);
        } else {
          console.log("Error in api call", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteGroup() {
    try {
      let url = `/group/delete/${selectedId}`;
      const response = await deleteData(url);
      if (response && response.data) {
        if (response.data.success) {
          setDeleteDialogVisible(false);
          getAllGroupData();
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function getDataByGroupId(id: any) {
    try {
      let url = `/group/${id}`;

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let group = response.data.data;

          setGroupName(group.name);
          setTotalPerson(group.totalperson.toString());
          setSelectedBranch(group.branchname);
          setSelectedBranch(group.branchid);
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function updateGroup() {
    try {
      let url = `/group/${selectedId}`;
      let body = {
        name: groupName,
        totalperson: parseFloat(totalPerson),
        branch_id: selectedBranch,
      };
      const response = await put(url, body);

      if (response && response.data) {
        if (response.data.success) {
          setEditDialogVisible(false);
          getAllGroupData();
        } else {
          console.log("Error in Api call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBranchList();
    getAllGroupData();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        groupData.filter((item: any) => {
          return (
            (item?.categoryname &&
              item?.categoryname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(groupData);
    }
  }, [searchQuery, groupData]);

  const actionBodyTemplate = (rowData: any) => (
    <div>
      <IconButton
        style={{
          color: "#264164",
        }}
      >
        <VisibilityIcon />
      </IconButton>

      <IconButton onClick={() => confirmEdit(rowData)}>
        <EditIcon color="primary" />
      </IconButton>

      <IconButton onClick={() => confirmDelete(rowData)}>
        <DeleteIcon color="error" />
      </IconButton>
    </div>
  );

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Groups</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Messages"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <Paper>
        <Box sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#e9e9e9" }}>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Group Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Total Person
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Branch Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                    className="flex justify-content-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Add />}
                      onClick={openAddNewGroup}
                    >
                      Add New
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((group: any, index: any) => (
                    <TableRow key={group.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{group.name}</TableCell>
                      <TableCell>{group.totalperson}</TableCell>
                      <TableCell>{group.branchname}</TableCell>
                      <TableCell align="right">
                        {actionBodyTemplate(group)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      {/* Delete Dialog */}
      <ConfirmationDialog
        open={deleteDialogVisible}
        onClose={onDeleteCancel}
        onConfirm={deleteGroup}
        title="Delete Group"
        message="Are you sure you want to delete this Group?"
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />

      {/* Add Dialog */}
      <Dialog
        open={!isEdit ? AddDialogVisible : EditDialogVisible}
        onClose={onDeleteCancel}
      >
        <DialogTitle>
          {!isEdit ? "Add New Group" : "Update Existing Group"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Total Person"
            value={totalPerson}
            onChange={(e) => setTotalPerson(e.target.value)}
          />
          <TextField
            id={`branch`}
            label="Branches"
            select
            value={selectedBranch}
            onChange={(e: any) => {
              setSelectedBranch(e.target.value);
            }}
            fullWidth
            style={{ width: "552px" }}
            className="mt-3"
            InputProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {branchData.map((branch) => (
              <MenuItem key={branch.id} value={branch.id}>
                {branch.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={!isEdit ? addGroup : updateGroup}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button onClick={onDeleteCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default GroupsPage;
