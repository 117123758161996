import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";

import { CONSTANTS } from "../../constants";
import { InvoicesTable } from "./invoice-table";
import { useNavigate } from "react-router-dom";
import axiosService from "../../services/axios";
import { Member } from "../../types/member-interface";
import { Box, Card, OutlinedInput, InputAdornment } from "@mui/material";
import { useAlert } from "../../components/CommonAlert";
import { useAuth } from "../../context/AuthContext";
import { Roles } from "src/types/enum";
import moment from "moment";
import { get } from "src/services/api.service";
import { Invoice } from "src/types/invoice-interface";
export default function InvoicesPage(): React.JSX.Element {
  const page = 0;
  const rowsPerPage = 25;
  const [members, setMembers] = React.useState<Invoice[]>([]);
  const [openInvite, setOpenInvite] = React.useState<boolean>(false);
  const [filteredData, setFilteredData] = React.useState<Invoice[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [inviteLInk, setInviteLink] = React.useState("");
  const { user } = useAuth();

  const { showAlert } = useAlert();
  const getMembers = async () => {
    try {
      let url = `/cartmanage/list`;

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setMembers(response.data.data);
          setFilteredData(response.data.data);
        } else {
          setMembers([]);
          setFilteredData([]);
          showAlert(
            response?.data?.message ?? "Failed to get invoices",
            "error"
          );
        }
      } else {
        showAlert(response?.data?.message ?? "Failed to get invoices", "error");
      }
    } catch (ex: any) {
      setMembers([]);
      setFilteredData([]);
      showAlert(
        ex?.response?.data?.message ?? "Failed to get invoices",
        "error"
      );
      console.error("Exception Caught", ex);
    }
  };
  React.useEffect(() => {
    getMembers();
  }, []);

  React.useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    setFilteredData(
      members.filter((item: Invoice) => {
        const createdAtStr = item?.created_at
          ? moment(item.created_at).format("MM/DD/YYYY")
          : "";

        return (
          createdAtStr.includes(lowercasedQuery) ||
          (item?.billnumber &&
            item?.billnumber.toLowerCase().includes(lowercasedQuery))
        );
      })
    );
  }, [searchQuery, members]);

  const onDeleteCallback = () => getMembers();
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Invoices</Typography>
        </Stack>
        <div>
          <Button
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={() => navigate("/cart")}
          >
            Add
          </Button>
        </div>
      </Stack>
      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Invoice"
            startAdornment={
              <InputAdornment position="start">
                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>
      <InvoicesTable
        count={filteredData?.length ?? 0}
        page={page}
        rows={filteredData}
        rowsPerPage={rowsPerPage}
        onDeleteCallback={onDeleteCallback}
      />
    </Stack>
  );
}
