import React from 'react';
import { AppBar, Box, BottomNavigation, BottomNavigationAction, Card, CardMedia, Typography } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HomeIcon from '@mui/icons-material/Home';

const Layout2: React.FC = () => {
  return (
    <Box>
      <AppBar position="static" color="primary">
        <Typography variant="h6" component="div" align="center">
          Promotions
        </Typography>
      </AppBar>
      <Box p={2}>
        <Card>
          <CardMedia
            component="img"
            height="140"
            image="https://via.placeholder.com/300"
            alt="Promotion Image"
          />
          <Typography variant="body1" component="div" p={2}>
            10% OFF Storewide
          </Typography>
        </Card>
      </Box>
      <BottomNavigation
        showLabels
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        value={1}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Promos" icon={<StorefrontIcon />} />
      </BottomNavigation>
    </Box>
  );
};

export default Layout2;
