// src/components/Home.tsx
import { AppBar, Toolbar, Stack, IconButton, Typography, Avatar, Box, Divider, MenuItem, Button, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { useNavigate } from 'react-router-dom';
import { Tabs as TabsIcon } from '@phosphor-icons/react/dist/ssr/Tabs';
import { CForm } from './form';

export const CouponForm: React.FC = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setIsOpen(open);
    };
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Coupon Details</Typography>
                </Stack>
                <div>
                 
                    <Button
                        startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/coupons')}
                    >
                        View All
                    </Button>
                </div>
            </Stack>
            <CForm />

        </Stack>
    );
};
