import { useParams } from "react-router-dom";
import { Button, Card, CardActions, CardContent, Grid, TextField } from "@mui/material"
import React from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";

export const Noticeboard = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="NoticeBoardFontsizeTitle"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }
                            }
                            {...field}
                            fullWidth
                            label="Fontsize Title"
                            name="FontsizeTitle"
                        />} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="NoticeBoardFontsizeDescription"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }
                            }
                            {...field}

                            fullWidth
                            label="Fontsize Description"
                            id="FontsizeDescription"
                        />} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="NoticeBoardFontsizeTag"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }
                            }
                            {...field}
                            fullWidth
                            label="Fontsize Tab"
                            id="FontsizeTag"
                        />} />
            </Grid>

        </Grid>)
}