import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  FormControlLabel,
  Checkbox,
  Stack,
  Typography,
  Box,
  Card,
  OutlinedInput,
  InputAdornment,
  Paper,
} from "@mui/material";
import { Delete, Edit, Add, Visibility, Search } from "@mui/icons-material";
import { deleteData, get, post, put } from "../../services/api.service";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { useAuth } from "src/context/AuthContext";
import axiosService from "src/services/axios";
import { CONSTANTS } from "../../constants";
import { useAlert } from "../../components/CommonAlert";
import { Merchant } from "src/types/merchant-interface";
import { RecordTypes } from "src/types/enum";

const ProductPage: React.FC = () => {
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [AddCategoryDialogVisible, setAddCategoryDialogVisible] =
    useState<boolean>(false);
  const [AddDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [EditDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [productData, setProductData] = useState<any[]>([]);

  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");

  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");

  const [price, setPrice] = useState("");
  const [productName, setProductName] = useState("");
  const [redeemPoints, setRedeemPoints] = useState("");

  const rowsPerPageOptions = [10, 25, 50, 100];
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);

  const [productNameError, setProductNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [redeemPointsError, setRedeemPointsError] = useState("");

  useEffect(() => {
    getAllProduct();
    getAllCategory();
    // getBranchList();
    getOutlets();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        productData.filter((item: any) => {
          return (
            (item?.categoryname &&
              item?.categoryname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(productData);
    }
  }, [searchQuery, productData]);

  const confirmDelete = (rowData: any) => {
    setDeleteDialogVisible(true);
    setSelectedId(rowData.id);
  };

  const confirmEdit = (rowData: any) => {
    setEditDialogVisible(true);
    setSelectedId(rowData.id);
    getDataByProductID(rowData.id);
    setIsEdit(true);
  };

  const onDeleteCancel = () => {
    setDeleteDialogVisible(false);
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setIsEdit(false);
  };

  const openAddNewProduct = (rowData: any) => {
    setAddDialogVisible(true);
    resetFields();
  };

  const categorybox = [
    {
      name: "This Product is redeemable by reward points",
      key: "Reward Points",
    },
  ];

  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let _selectedCategoryBox = [...selectedCategoryBox];
    if (e.target.checked) {
      _selectedCategoryBox.push(categorybox[0]);
    } else {
      _selectedCategoryBox = _selectedCategoryBox.filter(
        (category) => category.key !== categorybox[0].key
      );
    }
    setSelectedCategoryBox(_selectedCategoryBox);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div
        className="action-icons"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {/* <IconButton style={{ color: "#FFFF00" }}>
          <PictureAsPdf style={{ height: "19px", width: "19px" }} />
        </IconButton>

        <IconButton
          style={{
            color: "#00FFFF",
          }}
        >
          <Undo style={{ height: "19px", width: "19px" }} />
        </IconButton>

        <IconButton
          style={{
            color: "#264164",
          }}
        >
          <CameraAlt style={{ height: "19px", width: "19px" }} />
        </IconButton> */}

        <IconButton
          style={{
            color: "#264164",
          }}
        >
          <Visibility style={{ height: "19px", width: "19px" }} />
        </IconButton>

        <IconButton color="primary" onClick={() => confirmEdit(rowData)}>
          <Edit style={{ height: "19px", width: "19px" }} />
        </IconButton>

        <IconButton color="error" onClick={() => confirmDelete(rowData)}>
          <Delete style={{ height: "19px", width: "19px" }} />
        </IconButton>
      </div>
    );
  };

  const [selectedCategoryBox, setSelectedCategoryBox] = useState([
    categorybox[0],
  ]);

  const isRewardPointsSelected = selectedCategoryBox.some(
    (category) => category.key === "Reward Points"
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function resetFields() {
    setProductName("");
    setSelectedBranch("");
    setSelectedBranchId("");
    setSelectedCategory("");
    setSelectedCategoryId("");
    setPrice("");
    setRedeemPoints("");
    setProductNameError("");
    setCategoryError("");
    setPriceError("");
    setRedeemPointsError("");
  }

  async function addProduct() {
    try {
      let url = "/product";
      let body = {
        name: productName,
        description: "High-quality shampoo for all hair types.",
        price: parseInt(price),
        stock: 100,
        redeem_points: redeemPoints,
        // outlet_id: selectedBranchId,
        outlet_id: "",
        category_id: selectedCategory,
        isActive: true,
      };

      const response = await post(url, body);

      if (response && response.data) {
        if (response.data.success) {
          setAddDialogVisible(false);
          getAllProduct();
        } else {
          console.log("Error in Api call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteProduct() {
    try {
      let url = `/product/delete/${selectedId}`;

      const response = await deleteData(url);

      if (response && response.data) {
        if (response.data.success) {
          setDeleteDialogVisible(false);
          getAllProduct();
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function getDataByProductID(id: any) {
    try {
      let url = `/product/${id}`;

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let service = response.data.data;

          setProductName(service.name);
          setSelectedBranch(service.outlet_id);
          setSelectedBranchId(service.outlet_id);
          setSelectedCategory(service.categoryname);
          setSelectedCategoryId(service.category_id);
          setPrice(service.price.toString());
          setRedeemPoints(service.redeem_points.toString());

          if (service.redeem_points !== "") {
            setSelectedCategoryBox([categorybox[0]]);
          }

          setSelectedBranch(response.data.data.outlet_id);
          setSelectedCategory(response.data.data.category_id);
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function updateProduct() {
    try {
      let url = `/product/${selectedId}`;
      let body = {
        name: productName,
        description: "High-quality shampoo for all hair types.",
        price: parseInt(price),
        stock: 100,
        redeem_points: redeemPoints,
        outlet_id: "",
        // outlet_id: selectedBranch,
        category_id: selectedCategory,
        isActive: true,
      };

      const response = await put(url, body);

      if (response && response.data) {
        if (response.data.success) {
          setEditDialogVisible(false);
          getAllProduct();
        } else {
          console.log("Error in Api call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validateFields = () => {
    let valid = true;

    if (!productName.trim()) {
      setProductNameError("Product Name is required");
      valid = false;
    } else {
      setProductNameError("");
    }

    // Category validation
    if (!selectedCategory && !selectedCategoryId) {
      setCategoryError("Category is required");
      valid = false;
    } else {
      setCategoryError("");
    }

    // Price validation
    if (!price || price === "0") {
      setPriceError("Price must be a positive number");
      valid = false;
    } else {
      setPriceError("");
    }

    // Redeem Points
    if (!redeemPoints || redeemPoints === "0") {
      setRedeemPointsError("Redeem Points must be a positive number");
      valid = false;
    } else {
      setRedeemPointsError("");
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      if (!isEdit) {
        addProduct();
      } else {
        updateProduct();
      }
    }
  };

  async function getAllProduct() {
    try {
      let url = "/product/list";

      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setProductData(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllCategory() {
    try {
      let url = "/category/type/Product";

      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setCategoryData(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([
          { MerchantName: "--Select Outlet--" } as Merchant,
          ...json,
        ]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Products</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Messages"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <Paper>
        <Box sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#e9e9e9" }}>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Product name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                  >
                    Redeem with
                  </TableCell>
                  {user?.role === "merchant" && (
                    <TableCell
                      sx={{
                        fontSize: "medium",
                        fontWeight: "bold",
                        backgroundColor: "#e9e9e9",
                      }}
                      className="flex justify-content-end"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={openAddNewProduct}
                      >
                        Add New
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <TableRow key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.categoryname}</TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>
                        {row.redeem_points.length > 0 ? row.redeem_points : "-"}
                      </TableCell>
                      {user?.role === "merchant" && (
                        <TableCell>{actionBodyTemplate(row)}</TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onDeleteCancel}
        onConfirm={deleteProduct}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete product"}
        title={"Confirm Delete"}
      />

      {/* Add and Edit Dialog */}
      <Dialog
        open={!isEdit ? AddDialogVisible : EditDialogVisible}
        onClose={onDeleteCancel}
      >
        <DialogTitle>
          {!isEdit ? "Add New Product" : "Update Existing Product"}
        </DialogTitle>
        <DialogContent className="pt-0 pb-0">
          <TextField
            label="Product Name"
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value as string);
              if (e.target.value) {
                setProductNameError("");
              }
            }}
            fullWidth
            margin="normal"
            error={!!productNameError}
            helperText={productNameError}
          />

          <TextField
            select
            label="category"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value as string);
              if (e.target.value) {
                setCategoryError("");
              }
            }}
            fullWidth
            error={!!categoryError}
            helperText={categoryError}
            margin="normal"
          >
            {categoryData.map((category: any) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              if (e.target.value !== "0") {
                setPriceError("");
              }
            }}
            fullWidth
            margin="normal"
            type="number"
            error={!!priceError}
            helperText={priceError}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={isRewardPointsSelected}
                onChange={onCategoryChange}
              />
            }
            label={categorybox[0].name}
          />
          {isRewardPointsSelected && (
            <TextField
              fullWidth
              margin="normal"
              label="Redeem Points"
              value={redeemPoints}
              onChange={(e) => {
                setRedeemPoints(e.target.value);
                if (e.target.value !== "0") {
                  setRedeemPointsError("");
                }
              }}
              type="number"
              error={!!redeemPointsError}
              helperText={redeemPointsError}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onDeleteCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ProductPage;
