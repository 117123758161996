import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Grid,
    Typography,
    Divider,
    Stack,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Box,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { NoticeLayout, RecordActions, RecordTypes, ZoomMode } from 'src/types/enum';
import { useAlert } from 'src/components/CommonAlert';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MessageCategory } from 'src/intefaces/message.interface';


const MessageCategories: React.FC = () => {
    const [categories, setCategories] = useState<MessageCategory[]>([]);
    const [categoryName, setCategoryName] = useState('');
    const [layoutValue, setLayoutValue] = useState<string>("");
    const [sortId, setSortId] = useState<number | string>('');
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
    } = useForm<{ layout: string }>()
   
    const createCategory = async (data: any) => {
        try {
            //remove unselected categories
            const response = await (await axiosService.post<MessageCategory | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.messageCategory]}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save', 'error');
            } else {
                setCategories([...categories, data]);
                setLoading(false);
                showAlert('Category Created Successfully', 'success');
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editCategory = async (data: any) => {
        try {
            const response = await axiosService.put<MessageCategory>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.messageCategory]}/${data.id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update ', 'error');
            } else {
                const allCategories = [...categories];
                const editedIndex = allCategories.findIndex((category) => category.id == data.id)
                allCategories[editedIndex] = data;
                setCategories(allCategories);
                setEditingIndex(null);
                setLoading(false);
                showAlert('Updated Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to Update ', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }
    const deleteCategory = async (data: any) => {
        try {
            const response = await axiosService.delete<MessageCategory | any>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.messageCategory]}/${data.id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert(response?.data?.message || 'Failed to Delete ', 'error');
            } else {
                const updatedCategories = categories.filter((_) => _.id !== data.id);
                setCategories(updatedCategories);
                setLoading(false);
                showAlert('Updated Successfully', 'success');
            }
        } catch (ex: any) {
            showAlert(ex.response.data.message || 'Failed to delete ', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }
    const handleAddOrUpdateCategory = () => {
        if (!categoryName) {
            showAlert("Category name is required", "error")
            return
        }
        if (editingIndex !== null) {
            const updatedCategory = { id: categories[editingIndex].id, categoryName, sortId: Number(sortId) } as MessageCategory;
            editCategory(updatedCategory)

        } else {
            const newCategory: MessageCategory = {
                categoryName,
                sortId: Number(sortId),
            } as MessageCategory;
            createCategory(newCategory);
        }
        setCategoryName('');
        setSortId('');
    };

    const handleEditCategory = (index: number) => {
        setEditingIndex(index);
        setCategoryName(categories[index].categoryName);
        setSortId(categories[index].sortId);
    };

    const handleRemoveCategory = (index: number) => {
        deleteCategory(categories[index])
    };

    const getMessageCategories = async () => {
        try {
            const response = await axiosService.get<MessageCategory[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.messageCategory]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get noticeBoards', 'error');
                setCategories([])
            } else {
                const json = response.data;
                setCategories(json);
            }
        } catch (ex: any) {
            setCategories([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get noticeBoards', 'error');
            console.error("Exception Caught", ex)
        }
    }
    React.useEffect(() => {
        getMessageCategories();
    }, [])
    return (
     
            <Card>
                <CardHeader title="Message Categories"></CardHeader>
                <CardContent>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Category Name"
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Sort ID"
                                type="number"
                                value={sortId}
                                onChange={(e) => setSortId(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddOrUpdateCategory}
                                startIcon={editingIndex !== null ? <Edit /> : <Add />}
                                fullWidth
                            >
                                {editingIndex !== null ? 'Update' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>

                    <List>
                        {categories.map((category, index) => (
                            <ListItem key={category.id}>
                                <ListItemText primary={category.categoryName} secondary={`Sort ID: ${category.sortId}`} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(index)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveCategory(index)}>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
   
    );
};

export default MessageCategories;
