import React, { useState, useMemo, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    IconButton,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FixedSizeGrid as Grid } from 'react-window';
import debounce from 'lodash.debounce';

interface IconSelectorProps {
    id: string;
    open: boolean;
    onClose: () => void;
    onSelect: (iconName: string) => void;
}

const IconSelector: React.FC<IconSelectorProps> = ({ open, onClose, onSelect, id }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const iconNames = useMemo(() => Object.keys(Icons), []);

    const handleSearchChange = useCallback(
        debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value.toLowerCase());
        }, 300),
        []
    );

    const filteredIcons = useMemo(() => {
        return iconNames.filter((iconName) =>
            iconName.toLowerCase().includes(searchTerm)
        );
    }, [searchTerm, iconNames]);

    const handleIconClick = (iconName: string) => {
        console.log("onselect", onSelect);
        onSelect(iconName);
        onClose();
    };

    const cellRenderer = ({ columnIndex, rowIndex, style }: any) => {
        const index = rowIndex * 4 + columnIndex;
        if (index >= filteredIcons.length) return null;

        const iconName = filteredIcons[index];
        const IconComponent = (Icons as any)[iconName];

        return (
            <div style={style} key={id + iconName}>
                <IconButton key={id} onClick={() => handleIconClick(iconName)}>
                    <IconComponent />
                </IconButton>
            </div>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} >
            <DialogTitle>Select an Icon</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    placeholder="Search icons..."
                    onChange={handleSearchChange}
                    margin="normal"
                />
                <Grid
                    columnCount={4}
                    columnWidth={100}
                    height={400}
                    rowCount={Math.ceil(filteredIcons.length / 4)}
                    rowHeight={100}
                    width={400}
                    itemData={filteredIcons}
                    children={cellRenderer}
                />
            </DialogContent>
        </Dialog>
    );
};

export default IconSelector;
