import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { CONSTANTS } from "../../constants";
import { StaffsTable } from "./staff-table";
import { useNavigate } from "react-router-dom";
import axiosService from "../../services/axios";
import { Box, Card, OutlinedInput, InputAdornment } from "@mui/material";
import { RecordTypes } from "../../types/enum";
import { Staff } from "../../types/staff-interface";
import { get } from "src/services/api.service";
import { useAlert } from "src/components/CommonAlert";
import { useAuth } from "src/context/AuthContext";

export default function StaffsPage(): React.JSX.Element {
  const page = 0;
  const rowsPerPage = 25;
  const { user } = useAuth();
  const [staffs, setStaffs] = React.useState<Staff[]>([]);
  const [paginatedStaffs, setPaginatedStaffs] = React.useState<Staff[]>();
  const [filteredData, setFilteredData] = React.useState<Staff[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const { showAlert } = useAlert();

  const getStaffs = async () => {
    try {
      let url = `/staff/list`;

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setStaffs(response.data.data);
          setFilteredData(response.data.data);
        } else {
          setStaffs([]);
          setFilteredData([]);
          showAlert(response?.data?.message ?? "Failed to get staff", "error");
        }
      } else {
        showAlert(response?.data?.message ?? "Failed to get staff", "error");
      }
    } catch (ex: any) {
      setStaffs([]);
      setFilteredData([]);
      showAlert(ex?.response?.data?.message ?? "Failed to get staff", "error");
      console.error("Exception Caught", ex);
    }
  };
  React.useEffect(() => {
    getStaffs();
  }, []);

  React.useEffect(() => {
    setFilteredData(
      staffs.filter((item: Staff) => {
        return (
          (item?.address &&
            item?.address.includes(searchQuery.toLowerCase())) ||
          (item?.email &&
            item?.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.firstName &&
            item?.firstName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (item?.lastName &&
            item?.lastName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.phone &&
            item?.phone.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.username &&
            item?.username.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      })
    );
  }, [searchQuery]);
  const onDeleteCallback = () => getStaffs();
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Staffs</Typography>
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            {/* <Button color="inherit" startIcon={<UploadIcon fontSize="var(--icon-fontSize-md)" />}>
              Quick Actions
            </Button> */}
          </Stack>
        </Stack>
        {user?.role === "merchant" && (
          <div>
            <Button
              startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
              variant="text"
              onClick={() => navigate("/staff/add")}
            >
              Add
            </Button>
          </div>
        )}
      </Stack>
      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search member"
            startAdornment={
              <InputAdornment position="start">
                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>
      <StaffsTable
        count={filteredData?.length ?? 0}
        page={page}
        rows={filteredData}
        rowsPerPage={rowsPerPage}
        onDeleteCallback={onDeleteCallback}
      />
    </Stack>
  );
}
