import React from 'react';
import { AppBar, Box, List, ListItem, ListItemText, Typography, Card, CardContent } from '@mui/material';

const Layout3: React.FC = () => {
  return (
    <Box>
      <AppBar position="static" color="primary">
        <Typography variant="h6" component="div" align="center">
          Promotions
        </Typography>
      </AppBar>
      <Box p={2}>
        <Card>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText
                  primary="Connect to Strava and Let's Get Active"
                  secondary="Exciting Rewards to be Claimed"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Introducing The Instant Switch Feature"
                  secondary="Gigi Coffee x Helo Moo!"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Save RM1 Every Order on Gigi Coffee App!"
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Layout3;
