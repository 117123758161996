import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TablePagination,
  Grid,
  useMediaQuery,
  createTheme,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { get } from "../../services/api.service";
// import DateFormat from "../../libs/DateFormate";

const ActivityLogsPage: React.FC = () => {
  const [visibleDetailDialog, setVisibleDetailDialog] =
    useState<boolean>(false);
  const [selectedActivityLog, setSelectedActivityLog] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(10);
  const [activityLogsData, setActivityLogsData] = useState<any[]>([]);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(activityLogsData.length, (page + 1) * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <IconButton
        style={{
          color: "#264164",
        }}
      >
        <VisibilityIcon
          style={{ height: "19px", width: "19px" }}
          onClick={() => viewDetails(rowData)}
        />
      </IconButton>
    );
  };

  const viewDetails = (rowData: any) => {
    setVisibleDetailDialog(true);
    getActivityLogDataByID(rowData.id);
  };

  const onCloseDetailsDialog = () => {
    setVisibleDetailDialog(false);
    setSelectedActivityLog(null);
  };

  async function getActivityLogDataByID(id: any) {
    try {
      let url = `/staffmanagement/activitylogs/get/${id}`;

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setSelectedActivityLog(response.data.data);
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function getActivityLogs() {
    try {
      let url = "/staffmanagement/activitylogs";

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setActivityLogsData(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getActivityLogs();
  }, []);

  return (
    <div className="dashboard-margin">
      <h2 className="card-title">Activity Logs</h2>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Staff Name</TableCell>
              <TableCell>CheckIn Time</TableCell>
              <TableCell>CheckOut Time</TableCell>
              <TableCell>Working Time</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityLogsData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((activity, index) => (
                <TableRow key={activity.id}>
                  <TableCell>{startRow + index}</TableCell>
                  {/* <TableCell>{DateFormat.formatDate(activity.date)}</TableCell> */}
                  <TableCell>{"01-09-2023"}</TableCell>
                  <TableCell>{activity.staffName}</TableCell>
                  <TableCell>{activity.checkInTime}</TableCell>
                  <TableCell>{activity.checkOutTime}</TableCell>
                  <TableCell>{activity.workingTime}</TableCell>
                  <TableCell align="right">
                    {actionBodyTemplate(activity)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <span>
          {`Showing ${startRow} to ${endRow} of ${activityLogsData.length} entries`}
        </span>
        <TablePagination
          component="div"
          count={activityLogsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          labelRowsPerPage={""}
        />
      </div>

      <Dialog
        open={visibleDetailDialog}
        onClose={onCloseDetailsDialog}
        PaperProps={{
          sx: {
            width: isSmallScreen ? "90vw" : "40vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label className="font-bold">Details</label>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                style={{
                  border: "1px solid #e0e0e0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  overflow: "auto",
                }}
              >
                <Table>
                  <TableBody>
                    {selectedActivityLog ? (
                      <>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Staff Name
                          </TableCell>
                          <TableCell>
                            {selectedActivityLog.staffName || "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Date
                          </TableCell>
                          <TableCell>
                            {/* {DateFormat.formatDate(selectedActivityLog.date) ||
                              "-"} */}
                            11-09-2023
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>Day</TableCell>
                          <TableCell>{selectedActivityLog.day}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Check In Time
                          </TableCell>
                          <TableCell>
                            {selectedActivityLog.checkInTime}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Check Out Time
                          </TableCell>
                          <TableCell>
                            {selectedActivityLog.checkOutTime}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Working Time
                          </TableCell>
                          <TableCell>
                            {selectedActivityLog.workingTime}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>No details available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={onCloseDetailsDialog}
              color="primary"
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ActivityLogsPage;
