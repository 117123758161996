import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Box, CircularProgress, Container, Drawer } from '@mui/material';
import { MapCenter, MapLocation } from 'src/types/maps-interface';
import { Merchant } from 'src/types/merchant-interface';
import './google-map.css';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import BusinessInfo from './business-info';
import ContactInfo from './contact-info';
import { HeaderImage } from './header-image';
import Gallery from './image-gallery';
import OperatingHours from './operating-hours';
import OutletList from './outlet-list';
const containerStyle = {
  width: '100%',
  height: '80vh',
};


const getMapCenter = (locations: MapLocation[]): MapCenter => {
  if (locations.length === 0) { console.log("0 length", locations); return { lat: 0, lng: 0 }; }
  if (locations.length == 1) return { lat: locations[0].lat, lng: locations[0].lng };
  const latSum = locations.reduce((sum, loc) => sum + loc.lat, 0);
  const lngSum = locations.reduce((sum, loc) => sum + loc.lng, 0);

  return { lat: latSum / locations.length, lng: lngSum / locations.length };
};
interface BusinessInfoProps {
  merchant: Merchant;
  outlets: Merchant[]
  openOutletCallback?: (outlet:Merchant) => any
}
const GoogleMapView: React.FC<BusinessInfoProps> = ({ merchant, outlets,openOutletCallback }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [locations, setLocations] = useState<MapLocation[]>([]);
  const [center, setCenter] = useState<MapCenter>();
  const customIcon = 'https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png';
  const navigate = useNavigate();
  const [openQuickView, setOpenQuickView] = useState<Merchant | undefined>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEW1Wc11SUHoF3sgsxEq0M1XgOqSelP5M",
    libraries: ['marker'] // Ensure the marker library is included
  });

  useEffect(() => {
    const loc:MapLocation[] = [...outlets.filter(o => o.MerchantLatitude && Number(o.MerchantLatitude) && o.MerchantLongitude && Number(o.MerchantLongitude)).map(o => {
      return {
        id: o.id,
        name: o.MerchantName,
        lat: Number(o.MerchantLatitude),
        lng: Number(o.MerchantLongitude),
        icon: o.MerchantLogo,
        outlet:o
      }
    })]

    if (Number(merchant.MerchantLatitude) && Number(merchant.MerchantLongitude)) {
      loc.push({
        id: merchant.id,
        name: merchant.MerchantName,
        lat: Number(merchant.MerchantLatitude),
        lng: Number(merchant.MerchantLongitude),
        icon: merchant.MerchantLogo
      })
    }
    setLocations(loc ?? []);
  }, [merchant, outlets])

  useEffect(() => {
    merchant && setCenter({ lat: Number(merchant.MerchantLatitude), lng: Number(merchant.MerchantLongitude) })
  }, [])

  const openOutlet = async (currentOutlet?: Merchant) => {
    if (!currentOutlet || !outlets || !outlets.length) { return }
    try {
      openOutletCallback && openOutletCallback(currentOutlet);
    } catch (ex) {
      console.error("Exception Caught", ex)
    }
  }
  useEffect(() => {
    if (map && locations && locations.length) {
      locations.forEach(({ lat, lng, name, icon, id,outlet }) => {

        const markerElement = document.createElement('div');
        markerElement.style.width = '32px';
        markerElement.style.height = '32px';
        markerElement.style.backgroundImage = `url(${icon || "https://maps.google.com/mapfiles/ms/icons/red-dot.png"})`;
        markerElement.style.backgroundSize = 'cover';
        markerElement.style.backgroundPosition = 'center';
        markerElement.classList.add('google-map-marker');

        markerElement.click = () => {
          openOutlet(outlet);
        }

        const marker = new google.maps.marker.AdvancedMarkerElement({
          map: map,
          position: { lat, lng },
          title: name,
          content: icon ? markerElement : null
        });
        marker.addListener('click', () => {
          openOutlet(outlet)
        });
      });
    }
  }, [map, locations]);

  if (!isLoaded) return <CircularProgress />;

  return (
    (center?.lat && center?.lng) ? <Box sx={{ width: '100%',p:0 }}>
      <GoogleMap
        zoom={merchant.MapZoomLevel ?? 10}
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={(map) => setMap(map)}
        options={{
          mapId: "abf7a05f3371d9a1", // Add your Map ID here
        }}
      >

      </GoogleMap>
      <Drawer
        variant={'temporary'}
        open={!!openQuickView}
        onClose={() => setOpenQuickView(undefined)}
        anchor='bottom'
        PaperProps={{
          sx: {
            borderRadius: '16px 16px 0 0', // Rounded corners at the top
            height: '75vh', // Adjust height as needed
          },
        }}
      >
        <Box
          sx={{ padding: 2 }}
          role="presentation"
        >
          <Container sx={{ p: 0 }} maxWidth="sm">

            {openQuickView && <HeaderImage merchant={openQuickView} />}
            {openQuickView && <BusinessInfo merchant={openQuickView} />}
            {openQuickView && <OperatingHours merchant={openQuickView} />}
            {openQuickView && openQuickView.MerchantAddress && openQuickView.MerchantEmail && openQuickView.MerchantPhone1 && openQuickView.MerchantPhone2 && <ContactInfo merchant={openQuickView} />}
            {openQuickView && <Gallery merchant={openQuickView} />}
            {openQuickView && <OutletList merchant={openQuickView} outlets={[]} />}

          </Container>
        </Box>

      </Drawer>
    </Box> : <p><center>Not Available</center></p>
  );
};

export default GoogleMapView;
