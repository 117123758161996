import React, { useState } from 'react';
import { Box, Button, Typography, Avatar, Paper, IconButton, InputLabel, Tooltip } from '@mui/material';
import { Clear as ClearIcon, PictureAsPdf as PdfIcon } from '@mui/icons-material';
import axios from 'axios';
import { CONSTANTS } from '../constants';
import axiosService from '../services/axios';
import { RecordActions } from '../types/enum';

interface ImageUploadProps {
    imageUrl: string | null;
    onImageChange: (url: string | null) => void;
    onRemove?: () => void;
    mode?: string;
    name: string;
    label?: string;
    allowPdf?: boolean
}

const ImageUpload: React.FC<ImageUploadProps> = ({ imageUrl, onImageChange, onRemove, mode, name, label, allowPdf = false }) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);

    const handleUpload = async (file: File) => {
        setUploading(true);
        setMessage(null);

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axiosService.post<{ message: string, fileUrl: string }>(CONSTANTS.ApiConstants.uploadImage, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            onImageChange(response.data.fileUrl); // Set the file URL on success
            setMessage(response.data.message);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Error uploading file');
            }
        } finally {
            setUploading(false);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            handleUpload(file);
        }
    };


    return (
        <Box>
            <InputLabel>{label ?? ""}</InputLabel>
            <Paper
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    border: '2px dashed #ccc',
                    borderRadius: 2,
                    minHeight: '200px',
                    width: '100%'
                }}
            >
                {imageUrl ? (
                    <Box sx={{ position: 'relative', width: '200px', height: '200px' }}>
                        {allowPdf ? (
                            <Tooltip title="PDF File">
                                <PdfIcon sx={{ fontSize: 100, color: 'red' }} />
                            </Tooltip>
                        ) : (
                            <Avatar
                                src={imageUrl}
                                alt="Selected"
                                variant="square"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                display: mode && mode === RecordActions.view ? 'none' : 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*,application/pdf"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id={name}
                            />
                            <label htmlFor={name}>
                                <Button variant="contained" component="span" color="primary" size="small">
                                    Change File
                                </Button>
                            </label>
                            <IconButton
                                onClick={() => {
                                    onImageChange(null);
                                    if (onRemove) onRemove();
                                }}
                                color="secondary"
                                sx={{ mt: 1 }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                        <input
                            type="file"
                            accept={allowPdf ? "image/*,application/pdf" : "image/*"}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id={name}
                        />
                        <label htmlFor={name}>
                            <Button variant="contained" component="span" color="primary">
                                Choose File
                            </Button>
                        </label>
                    </Box>
                )}
            </Paper>
            {message && (
                <Box mt={2}>
                    <Typography variant="body1">{message}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default ImageUpload;
