// src/theme/theme.ts
import { createTheme } from '@mui/material/styles';
import { brandColor, nevada } from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: "#cc0400",
    },
    secondary: {
      main: "#636b74",
    },
  },
});

export default theme;
