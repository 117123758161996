
import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Avatar, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { useState } from 'react';
import { useAuth } from 'src/context/AuthContext';
import { Merchant } from 'src/types/merchant-interface';
import { useMerchant } from 'src/context/MerchantContext';

export function MemberAccLanding(): React.JSX.Element {
    const navigate = useNavigate();
    const { merchant } = useMerchant();
    const { user, logout, isAuthenticated } = useAuth();

    return (
        !isAuthenticated ? <Navigate to={`/${merchant?.id}/membersignin`} replace /> : <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
            flexDirection={"column"}
        >
            <Avatar
                variant="square"
                src={user?.account?.logo}
            ></Avatar>
            <Typography variant='subtitle1'>
                Hi {user?.account?.name}</Typography>
            <br />
            <Button onClick={() => { logout(); navigate(`/${merchant?.id}`) }} variant="text" color="primary" type="button">
                Logout
            </Button>
        </Box >
    );
}


