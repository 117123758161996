
import * as React from 'react';
import Box from '@mui/material/Box';

import { Button, Card, CardActions, CardContent, Stack, Tab, Tabs, Typography } from '@mui/material';
import { AccessControl } from './access-control';
import { FormProp } from '../../types/formprop-interface';
import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { MerchantSettings } from '../../types/merchant-settings-interface';
import moment from 'moment';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { Member } from '../../types/member-interface';
import { useAlert } from '../../components/CommonAlert';

export function Settings({ action, id }: FormProp): React.JSX.Element {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: {} as MerchantSettings
  });
  const { showAlert } = useAlert();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const getmerchantSettings = async () => {
    try {
      const response = await axiosService.get<MerchantSettings>(`${CONSTANTS.ApiConstants.getMerchanSettings}`)
      if (response.status != 200) {
        showAlert('Failed to Retrive Member Details', 'error');
      } else {
        const json: MerchantSettings = response.data;
        methods.reset(json);
      }
    } catch (ex) {
      showAlert('Failed to Retrive Member Details', 'error');
      console.error("Exception Caught", ex)
    }
  }
  const editSettings = async (data: MerchantSettings) => {
    try {
      const response = await axiosService.put<MerchantSettings | any>(`${CONSTANTS.ApiConstants.putMerchanSettings}`, data)
      setLoading(false);
      if (response.status != 200) {
        showAlert(response?.data?.message || 'Failed to Retrive Member Details', 'error');
      } else {
        const json: MerchantSettings = response.data;
        showAlert("Settings updated!", "success");
        methods.reset(json);
      }
    } catch (ex: any) {
      setLoading(false);
      showAlert(ex.response?.data?.message || 'Failed to Retrive Member Details', 'error');
      console.error("Exception Caught", ex)
    }
  }
  React.useEffect(() => {
    getmerchantSettings()
  }, [])
  const onSubmit = methods.handleSubmit(async (data: any) => {
    try {
      if (loading) {
        return
      }
      setLoading(true);
      await editSettings(data);
    }
    catch (ex: any) {
      setLoading(false);
      showAlert(ex.response?.data?.message || 'Failed to Retrive Member Details', 'error');
    } finally {

    }



  });
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>Settings</Typography>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ width: 'calc(100vw - 300px)', bgcolor: 'background.paper' }}>
        <FormProvider {...methods} >
          <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Card>
              <CardContent>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  allowScrollButtonsMobile
                >
                  <Tab label="Access Control" value={0} />

                </Tabs>
                <TabPanel value={value} index={0}>
                  <AccessControl action={action} id={id} />
                </TabPanel>

              </CardContent >
            </Card >
            <br />
            <br />
            <br />
            <br />
            <br />
            {<Box
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                zIndex: 9,
                width: '100%',
                backgroundColor: '#f5f5f5',
                padding: 2,
                display: 'flex',
                justifyContent: 'flex-end', // Align the button to the right
                boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
              }}
            >
              <Button disabled={loading} variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
            }
          </Box>
        </FormProvider>
      </Box>
    </Stack>
  );
}
