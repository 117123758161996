import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { Merchant } from 'src/types/merchant-interface';
import moment from 'moment';

const days = [
    { key: 'mon', value: "Monday" },
    { key: 'tue', value: "Tuesday" },
    { key: 'wed', value: "Wednesday" },
    { key: 'thus', value: "Thursday" },
    { key: 'fri', value: "Friday" },
    { key: 'sat', value: "Satuday" },
    { key: 'sun', value: "Sunday" }]

const OperatingHours: React.FC<any> = ({ merchant }: { merchant: Merchant }) => {
    const _merchant: any = merchant;
    console.log('_merchant[`open_${day}_from`]', _merchant[`open_${days[0].key}_from`]);
    return <Paper elevation={0} sx={{ pl: 2, pr: 2, pb: 0 }}>
        <Typography variant='h6'>Operating Hours</Typography>
        <Box sx={{pt:1}}>
            {days.map((day) => (

                <Box key={day.key} display="flex" justifyContent="space-between">
                    <Typography variant="body2">{day.value}</Typography>
                    {_merchant[`open_${day.key}`] ? <Typography variant="body2">{moment(_merchant[`open_${day.key}_from`], "HH:mm").format("hh:mm A")} - {moment(_merchant[`open_${day.key}_to`], "HH:mm").format("hh:mm A")}</Typography> : <Typography variant="body2">Closed</Typography>}
                </Box>

            ))}
        </Box>

    </Paper>
};

export default OperatingHours;
