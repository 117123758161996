import React, { useState, useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { TextField, Button, Grid, Typography, MenuItem, CircularProgress, Card, CardContent, Container, CardHeader, Box, Stack } from '@mui/material';
import axios from 'axios';
import ImageUpload from 'src/components/ImageUpload';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { RecordTypes, RecordActions } from 'src/types/enum';
import { useMerchant } from 'src/context/MerchantContext';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useAlert } from 'src/components/CommonAlert';
import { Member } from 'src/types/member-interface';
import { keyframes } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const bounceAnimation = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const MemberSignUp: React.FC = () => {
    const { control, handleSubmit, reset } = useForm<Member>();
    const [loading, setLoading] = useState<boolean>(true);
    const { merchant } = useMerchant();
    const { showAlert } = useAlert();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const onSubmit = async (data: Member) => {
        try {
            console.log("user", data);
            const response = await (await axiosService.post<Member | any>(`${CONSTANTS.ApiConstants.public_member_signup}/${merchant?.id}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response?.data?.message && typeof response.data.message == "string" ? response.data.message : 'Oops! Something is not working. Signup Failed', 'error');
            } else {
                navigate(`/${merchant?.id}/membersignup?complete=true`)
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex?.response?.data?.message && typeof ex.response.data.message == "string" ? ex.response.data.message : 'Oops! Something is not working. Signup Failed', 'error');
            console.error("Exception Caught", ex)
        }
    };
    const methods = useForm<Member>({
        defaultValues: {
            "Mobile": "",
            "MemberName": "",
            "Email": "",
            "Password": ""
        }
    });
    return (
        <Container maxWidth={"sm"} sx={{ pt: 2 }}>
            <Card>
                <CardContent>
                    {(!queryParams.get('complete') || queryParams.get('complete')?.toString() == 'false') ?
                        <Box>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="Mobile"
                                                rules={{ required: 'Mobile is required' }}
                                                control={methods.control}
                                                render={({ field, fieldState }) =>
                                                    <TextField

                                                        helperText={typeof methods.formState.errors?.Mobile?.message === 'string' ? methods.formState.errors?.Mobile?.message : ""}
                                                        FormHelperTextProps={{ style: { color: 'red' } }}
                                                        {...field}
                                                        fullWidth
                                                        id="Mobile"
                                                        label="Mobile"
                                                    />} />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="MemberName"

                                                control={methods.control}
                                                render={({ field, fieldState }) =>
                                                    <TextField

                                                        {...field}
                                                        fullWidth
                                                        id="MemberName"
                                                        label="Name"
                                                    />} />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="Email"
                                                control={methods.control}
                                                render={({ field, fieldState }) =>
                                                    <TextField

                                                        {...field}
                                                        fullWidth
                                                        label="Email"
                                                        id="Email"
                                                    />} />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="Password"
                                                control={methods.control}
                                                render={({ field, fieldState }) =>
                                                    <TextField

                                                        {...field}
                                                        fullWidth
                                                        label="Password"
                                                        id="Password"
                                                    />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </form>
                            </FormProvider>
                        </Box> :
                        <Container maxWidth="sm" sx={{ textAlign: 'center', py: 5 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mb: 3,
                                }}
                            >
                                <CheckCircleIcon
                                    sx={{
                                        fontSize: '4rem',
                                        color: '#4caf50',
                                        animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                    }}
                                />
                            </Box>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Thank You!
                            </Typography>
                            <Typography variant="body1">
                                Your registration is complete.
                            </Typography>
                            <Stack sx={{ "justifyContent": "center" }}>
                                <Button type="button" variant="contained" color="primary" fullWidth>
                                    Log In
                                </Button>
                            </Stack>
                        </Container>}
                </CardContent>
            </Card>

        </Container>
    );
};
