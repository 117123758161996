import { useParams } from "react-router-dom";
import React from 'react';
import { Button, Card, CardActions, CardContent, Grid, TextField } from "@mui/material"
import { Image as ImageIcon } from '@phosphor-icons/react/dist/ssr/Image';
import { Controller, useFormContext } from 'react-hook-form';
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";

export const EmailConfig = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
                <Controller
                    name="MsgEmailHost"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            id="MsgEmailHost"
                            label="Host"

                        />} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="MsgEmailPort"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            id="MsgEmailPort"
                            label="Port"

                        />} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="MsgEmailId"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            id="MsgEmailId"
                            label="Email Id"

                        />} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="MsgEmailPassword"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            id="MsgEmailPassword"
                            label="Email Password"

                        />} />
            </Grid>
        </Grid>)
}