import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Table as TableIcon } from '@phosphor-icons/react/dist/ssr/Table';

import { CONSTANTS } from '../../constants';
import { MessagesTable } from './message-table';
import { useNavigate } from 'react-router-dom';
import axiosService from '../../services/axios';
import { Box, Card, OutlinedInput, InputAdornment } from '@mui/material';
import { useAlert } from '../../components/CommonAlert';
import MessagesInviteDialog from '../../components/ConfirmationDialog';
import { useAuth } from '../../context/AuthContext';
import { RecordTypes, Roles } from 'src/types/enum';
import { Messages } from 'src/intefaces/message.interface';
export default function MessagesPage(): React.JSX.Element {
    const page = 0;
    const rowsPerPage = 25;
    const [messages, setMessages] = React.useState<Messages[]>([]);
    const [openInvite, setOpenInvite] = React.useState<boolean>(false);
    const [filteredData, setFilteredData] = React.useState<Messages[]>([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [inviteLInk, setInviteLink] = React.useState("");
    const { user } = useAuth();

    const { showAlert } = useAlert();
    const getInviteLink = async () => {
        try {
            let meta = "";
            console.log("user", user)
            switch (user?.role) {
                case Roles.merchant:
                    meta = `mid${user.account.id}`;
                    break;
                case Roles.outlet:
                    meta = `oid${user.account.id}`;
                    break;
                case Roles.staff:
                    meta = `sid${user.account.id}`;
                    break;
                default:
                    break;
            }
            setInviteLink(`${process.env.REACT_APP_APP_BASE_URL}/mregister/${encodeURIComponent(btoa(meta))}`);
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to create link', 'error');
            console.error("Exception Caught", ex)
            setInviteLink("");

        }
    }
    const getMessages = async () => {
        try {
            const response = await axiosService.get<Messages[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.messages]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get messages', 'error');
                setMessages([])
                setFilteredData([])
            } else {
                const json = response.data;

                setMessages(json);
                setFilteredData(json)
            }
        } catch (ex: any) {
            setMessages([])
            setFilteredData([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get messages', 'error');
            console.error("Exception Caught", ex)
        }
    }
    React.useEffect(() => {
        getMessages()
    }, [])
    const onCopy = () => {
        navigator.clipboard.writeText(inviteLInk).then(() => {
            setOpenInvite(false)
        }).catch(err => {
            setOpenInvite(false)
            showAlert('Failed to copy!', 'error');
            console.log("failed to copy", err);
        });

    }
    React.useEffect(() => {

        setFilteredData(messages.filter((item: Messages) => {
            return (item?.categoryid && item?.categoryid.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.name && item?.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.contact && item?.contact.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.email && item?.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.message && item?.message.toLowerCase().includes(searchQuery.toLowerCase()))            
        }));
    }, [searchQuery])
    const onDeleteCallback = () => getMessages();
    const navigate = useNavigate();
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Messages</Typography>

                </Stack>
                <div>
                  <Button
                        startIcon={<TableIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/messagesForm')}
                    >
                        Edit Message Form
                    </Button>
                </div>
            </Stack>
            <Box>
                <Card sx={{ p: 2 }}>
                    <OutlinedInput
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        placeholder="Search Messages"
                        startAdornment={
                            <InputAdornment position="start">
                                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
                            </InputAdornment>
                        }
                        sx={{ maxWidth: '500px' }}
                    />
                </Card>
            </Box>
            <MessagesTable
                count={filteredData?.length ?? 0}
                page={page}
                rows={filteredData}
                rowsPerPage={rowsPerPage}
                onDeleteCallback={onDeleteCallback}
                
            />
            <MessagesInviteDialog
                open={openInvite && !!inviteLInk}
                onClose={() => { setOpenInvite(false); }}
                onConfirm={onCopy}
                confirmLabel={"Copy Link"}
                cancelLabel={"Cancel"}
                message={`Copy and share the link :  ${inviteLInk}`}
                title={"Invite Messages"}
            />
        </Stack>
    );
}
