import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CONSTANTS } from "../../constants";
import axiosService from "../../services/axios";
import { Merchant } from "../../types/merchant-interface";
import { Member } from "../../types/member-interface";
import { useAlert } from "../../components/CommonAlert";
import { useParams } from "react-router-dom";
import { RecordActions, RecordTypes, Roles } from "../../types/enum";
import ImageUpload from "../../components/ImageUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];
export function MForm({
  publicForm = false,
}: {
  publicForm: boolean;
}): React.JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState("General");
  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const { action, id, token } = useParams();
  const { user, getToken } = useAuth();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { showAlert } = useAlert();
  const methods = useForm<any>({
    defaultValues: {
      Mobile: "",
      MemberName: "",
      DOB: null,
      Email: "",
      Gender: "",
      MemberName2: "",
      Address1: "",
      Address2: "",
      Address3: "",
      Postcode: "",
      MemberLocation: "",
      MemberState: "",
      Country: "",
      Occupation: "",
      PreferLanguage: "",
      MGM: "",
      RelationUpline: "",
      Upline: "",
      Avatar: "",
      RegistredTime: null,
      UplinePhone: "",
      MemberType: "",
      Tag: "",
      Race: "",
      IdNumber: "",
      outletid: "",
    },
  });
  const createNewMember = async (data: any) => {
    try {
      const response = await await axiosService.post<Member | any>(
        `${
          publicForm
            ? CONSTANTS.ApiConstants.postPublicMember + "/" + token
            : CONSTANTS.ApiConstants.postMembers
        }`,
        data
      );
      if (response.status != 200) {
        setLoading(false);
        showAlert(response.data.message || "Failed to Save Merchant", "error");
      } else {
        const json = response.data;
        setLoading(false);
        showAlert("Merchant Created Successfully", "success");
        if (publicForm) {
          navigate(`/mregister/${token}?complete=true`);
        } else {
          navigate(`/members/edit/${response.data.id}`);
        }
      }
    } catch (ex: any) {
      setLoading(false);
      showAlert(ex.response.data.message || "Failed to Save Merchant", "error");
      console.error("Exception Caught", ex);
    }
  };
  const editMember = async (data: any) => {
    try {
      const response = await axiosService.put<Member>(
        `${CONSTANTS.ApiConstants.putMember}/${id}`,
        data
      );
      if (response.status != 200) {
        setLoading(false);
        showAlert("Failed to Update Member", "error");
      } else {
        const json = response.data;
        setLoading(false);
        showAlert("Member Updated Successfully", "success");
      }
    } catch (ex) {
      showAlert("Failed to Update Member", "error");
      setLoading(false);
      console.error("Exception Caught", ex);
    }
  };

  const getMemberDetails = async () => {
    try {
      const response = await axiosService.get<Member>(
        `${CONSTANTS.ApiConstants.getMemberbyId}/${id}`
      );
      if (response.status != 200) {
        showAlert("Failed to Retrive Member Details", "error");
      } else {
        const json: Member = response.data;
        if (json.DOB) {
          json.DOB = moment(json.DOB ?? null)
            .utc(true)
            .local();
          json.RegistredTime = moment(json.RegistredTime ?? null)
            .utc(true)
            .local();
        }
        methods.reset(json);
      }
    } catch (ex) {
      showAlert("Failed to Retrive Member Details", "error");
      console.error("Exception Caught", ex);
    }
  };
  const onSubmit = methods.handleSubmit(async (data: any) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      if (publicForm || action == RecordActions.add) {
        createNewMember(data);
      } else if (action == RecordActions.edit) {
        editMember(data);
      }
    } catch (ex) {
      setLoading(false);
    } finally {
    }
  });
  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([
          { MerchantName: "--Select Outlet--" } as Merchant,
          ...json,
        ]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };
  React.useEffect(() => {
    !publicForm && getOutlets();
    if (action == RecordActions.edit || action == RecordActions.view) {
      getMemberDetails();
    }
  }, []);
  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {!publicForm && (
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="outletid"
                    rules={{
                      required: "Select the Outlet.",
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Outlet
                        </InputLabel>
                        <Select
                          {...field}
                          label="Outlet"
                          name="outlet"
                          variant="outlined"
                        >
                          {outlets.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.MerchantName}
                            </MenuItem>
                          ))}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText style={{ color: "red" }}>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Mobile"
                  rules={{
                    required: "Mobile is required.",
                    minLength: {
                      value: 10,
                      message: "Mobile number should be 10 digits.",
                    },
                    maxLength: {
                      value: 10,
                      message: "Mobile number should be 10 digits.",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Mobile number must contain only digits.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Mobile?.message ===
                        "string"
                          ? methods.formState.errors?.Mobile?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      id="Mobile"
                      label="Mobile"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="MemberName"
                  rules={{
                    required: "Member name is required.",
                    maxLength: {
                      value: 45,
                      message: "Member Name should be less than 45 characters.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.MemberName?.message ===
                        "string"
                          ? methods.formState.errors?.MemberName?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      id="MemberName"
                      label="Name"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="MemberName2"
                  rules={{
                    required: "Member name2 is required.",
                    maxLength: {
                      value: 45,
                      message: "MemberName2 should be less than 45 characters.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.MemberName2
                          ?.message === "string"
                          ? methods.formState.errors?.MemberName2?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Name 2"
                      id="MemberName2"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="DOB"
                  rules={{
                    required: "Date of Birth is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <>
                      <DatePicker
                        sx={{ width: "100%" }}
                        {...field}
                        label="Select DOB"
                      />
                      {fieldState.error && (
                        <FormHelperText style={{ color: "red" }}>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Email"
                  rules={{
                    required: "Email is required.",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Ex. abc@gmail.com",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Email?.message ===
                        "string"
                          ? methods.formState.errors?.Email?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Email"
                      id="Email"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Gender"
                  rules={{
                    required: "Select the Gender.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Gender
                      </InputLabel>
                      <Select
                        {...field}
                        defaultValue={null}
                        label="Gender"
                        name="Gender"
                        variant="outlined"
                      >
                        {genderOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.error && (
                        <FormHelperText style={{ color: "red" }}>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Address1"
                  rules={{
                    required: "Address1 is required.",
                    maxLength: {
                      value: 200,
                      message: "Address1 should be less than 200 characters.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Address1?.message ===
                        "string"
                          ? methods.formState.errors?.Address1?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Address 1"
                      id="Address1"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Address2"
                  rules={{
                    required: "Address2 is required.",
                    maxLength: {
                      value: 200,
                      message: "Address2 should be less than 200 characters.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Address2?.message ===
                        "string"
                          ? methods.formState.errors?.Address2?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Address 2"
                      id="Address2"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Address3"
                  rules={{
                    required: "Address3 is required.",
                    maxLength: {
                      value: 200,
                      message: "Address3 should be less than 200 characters.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Address3?.message ===
                        "string"
                          ? methods.formState.errors?.Address3?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Address 3"
                      id="Address3"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Postcode"
                  rules={{
                    required: "Post Code is required.",
                    maxLength: {
                      value: 10,
                      message: "Code should be 6 digits.",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Post Code must contain only digits.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Postcode?.message ===
                        "string"
                          ? methods.formState.errors?.Postcode?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Postcode"
                      id="Postcode"
                      type="number"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="IdNumber"
                  rules={{
                    required: "Id Number is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.IdNumber?.message ===
                        "string"
                          ? methods.formState.errors?.IdNumber?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="IdNumber"
                      id="IdNumber"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="MemberLocation"
                  rules={{
                    required: "Location is required.",
                    maxLength: {
                      value: 100,
                      message: "Location should be 100 Characters.",
                    },
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.MemberLocation
                          ?.message === "string"
                          ? methods.formState.errors?.MemberLocation?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Location"
                      id="MemberLocation"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="MemberState"
                  control={methods.control}
                  rules={{
                    required: "State is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.MemberState
                          ?.message === "string"
                          ? methods.formState.errors?.MemberState?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="State"
                      id="MemberState"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Country"
                  rules={{
                    required: "Country is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Country?.message ===
                        "string"
                          ? methods.formState.errors?.Country?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Country"
                      id="Country"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Occupation"
                  rules={{
                    required: "Occupation is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Occupation?.message ===
                        "string"
                          ? methods.formState.errors?.Occupation?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Occupation"
                      id="Occupation"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="PreferLanguage"
                  rules={{
                    required: "Language is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.PreferLanguage
                          ?.message === "string"
                          ? methods.formState.errors?.PreferLanguage?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="PreferLanguage"
                      id="PreferLanguage"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="MGM"
                  rules={{
                    required: "MGM is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.MGM?.message ===
                        "string"
                          ? methods.formState.errors?.MGM?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="MGM"
                      id="MGM"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="RelationUpline"
                  rules={{
                    required: "Relation Upline is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.RelationUpline
                          ?.message === "string"
                          ? methods.formState.errors?.RelationUpline?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="RelationUpline"
                      id="RelationUpline"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Upline"
                  rules={{
                    required: "Upline is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Upline?.message ===
                        "string"
                          ? methods.formState.errors?.Upline?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Upline"
                      id="Upline"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="RegistredTime"
                  rules={{
                    required: "Register Time is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <>
                      <DateTimePicker
                        sx={{ width: "100%" }}
                        {...field}
                        label="Registerd Time"
                      />
                      {fieldState.error && (
                        <FormHelperText style={{ color: "red" }}>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="UplinePhone"
                  rules={{
                    required: "Upline Phone is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.UplinePhone
                          ?.message === "string"
                          ? methods.formState.errors?.UplinePhone?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Upline Phone"
                      id="UplinePhone"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="MemberType"
                  rules={{
                    required: "Member Type is required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.UplinePhone
                          ?.message === "string"
                          ? methods.formState.errors?.UplinePhone?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Member Type"
                      id="MemberType"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Tag"
                  rules={{
                    required: "Tag is Required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Tag?.message ===
                        "string"
                          ? methods.formState.errors?.Tag?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Tag"
                      id="Tag"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Race"
                  rules={{
                    required: "Race is Required.",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      InputProps={{
                        readOnly: action == RecordActions.view,
                      }}
                      helperText={
                        typeof methods.formState.errors?.Race?.message ===
                        "string"
                          ? methods.formState.errors?.Race?.message
                          : ""
                      }
                      FormHelperTextProps={{ style: { color: "red" } }}
                      {...field}
                      fullWidth
                      label="Race"
                      id="Race"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="Avatar"
                  rules={{
                    required: "Image is required",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <>
                      <ImageUpload
                        onImageChange={(url: string | null) => {
                          field.onChange(url);
                        }}
                        imageUrl={field.value}
                        mode={action}
                        name={"Avatar"}
                        label="Image"
                      />
                      {fieldState.error && (
                        <FormHelperText style={{ color: "red" }}>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <br />
        <br />
        <br />
        <br />
        {action != RecordActions.view && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 9,
              width: "100%",
              backgroundColor: "#f5f5f5",
              padding: 2,
              display: "flex",
              justifyContent: "flex-end",
              boxShadow: "0px -1px 5px rgba(0,0,0,0.1)",
            }}
          >
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </FormProvider>
  );
}
