import { useParams } from "react-router-dom";
import React from 'react';
import {
    Button, Card, CardActions, CardContent, Grid, TextField
} from "@mui/material";
import { Controller, useFormContext } from 'react-hook-form';
import { RecordActions, RecordTypes, Roles } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";
import { useAuth } from "../../../context/AuthContext";

export const FunctionControl = ({ action, id, recordType }: FormProp) => {
    const methods = useFormContext();
    const { user } = useAuth();
    return (<Grid container spacing={2}>
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofCoupon"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofCoupon"
                        id="NumberofCoupon"
                        label="Numberof Coupon"
                    />} />
        </Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofeWallet"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofeWallet"
                        id="NumberofeWallet"
                        label="Numberof eWallet"
                    />} />
        </Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofVIPCard"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofVIPCard"
                        id="NumberofVIPCard"
                        label="Numberof VIPCard" />} /></Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofChopCard"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofChopCard"
                        id="NumberofChopCard"
                        label="Numberof ChopCard" />} /> </Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofUnlimited"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofUnlimited"
                        id="NumberofUnlimited"
                        label="Numberof Unlimited" />} /> </Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofRedemption"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofRedemption"
                        id="NumberofRedemption"
                        label="Numberof Redemption" />} /></Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofAngpao"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofAngpao"
                        id="NumberofAngpao"
                        label="Numberof Angpao" />} /> </Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofSpinWin"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofSpinWin"
                        id="NumberofSpinWin"
                        label="Numberof SpinWin" />} /></Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofBranches"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofBranches"
                        id="NumberofBranches"
                        label="Numberof Branches" />} /> </Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofProduct"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofProduct"
                        id="NumberofProduct"
                        label="Numberof Product" />} /></Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofService"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofService"
                        id="NumberofService"
                        label="Numberof Service" />} /></Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofOrdering"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofOrdering"
                        id="NumberofOrdering"
                        label="Numberof Ordering" />} /> </Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet) || (Roles.merchant == user?.role && recordType == RecordTypes.partner)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofDirectory"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofDirectory"
                        id="NumberofDirectory"
                        label="Numberof Directory" />} /></Grid>}
        {(user?.role == Roles.superadmin || (Roles.merchant == user?.role && recordType == RecordTypes.outlet) || (Roles.merchant == user?.role && recordType == RecordTypes.partner)) && <Grid item xs={12} sm={4}>
            <Controller
                name="NumberofGallery"
                control={methods.control}
                render={({ field, fieldState }) =>
                    <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        {...field}
                        name="NumberofGallery"
                        id="NumberofGallery"
                        label="Numberof Gallery" />} /></Grid>}

    </Grid>);
}