import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Grid,
    Typography,
    Divider,
    Stack,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Box,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { CONSTANTS } from 'src/constants';
import { NoticeBoard, NoticeTab } from 'src/intefaces/notice-board.inteface';
import axiosService from 'src/services/axios';
import { NoticeLayout, RecordActions, RecordTypes, ZoomMode } from 'src/types/enum';
import { useAlert } from 'src/components/CommonAlert';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';


const NoticeTabs: React.FC = () => {
    const [tabs, setTabs] = useState<NoticeTab[]>([]);
    const [tabName, setTabName] = useState('');
    const [layoutValue, setLayoutValue] = useState<string>("");
    const [sortId, setSortId] = useState<number | string>('');
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
    } = useForm<{ layout: string }>()
    const onLayoutSubmit: SubmitHandler<any> = async () => {
        try {
            const payload = {
                "noticeLayout": layoutValue
            }
            const response = await axiosService.post<{ id: number, noticeLayout: string } | any>(`${CONSTANTS.ApiConstants.updateNoticeLayout}`, payload)
            if (response.status != 200) {
            } else {
                showAlert(response?.data?.message ?? 'Saved layout', 'success');
            }
        } catch (ex: any) {
            // setTabs([])
            showAlert(ex?.response?.data?.message ?? 'Failed to update layout', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const getNoticeLayout = async () => {
        try {
            const response = await axiosService.get<{ id: number, noticeLayout: string } | any>(`${CONSTANTS.ApiConstants.getNoticeLayout}`)
            if (response.status != 200) {
                //showAlert(response?.data?.message ?? 'Failed to get noticeBoards', 'error');
                setLayoutValue("");
            } else {
                const json = response.data;
                setLayoutValue(json.noticeLayout);
            }
        } catch (ex: any) {
            // setTabs([])
            setLayoutValue("");
            // showAlert(ex?.response?.data?.message ?? 'Failed to get noticeBoards', 'error');
            console.error("Exception Caught", ex)
        }
    }

    const createTab = async (data: any) => {
        try {
            //remove unselected tabs
            const response = await (await axiosService.post<NoticeTab | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.noticeTabs]}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save', 'error');
            } else {
                setTabs([...tabs, data]);
                setLoading(false);
                showAlert('Tab Created Successfully', 'success');
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editTab = async (data: any) => {
        try {
            const response = await axiosService.put<NoticeTab>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.noticeTabs]}/${data.id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update ', 'error');
            } else {
                const allTabs = [...tabs];
                const editedIndex = allTabs.findIndex((tab) => tab.id == data.id)
                allTabs[editedIndex] = data;
                setTabs(allTabs);
                setEditingIndex(null);
                setLoading(false);
                showAlert('Updated Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to Update ', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }
    const deleteTab = async (data: any) => {
        try {
            const response = await axiosService.delete<NoticeTab | any>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.noticeTabs]}/${data.id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert(response?.data?.message || 'Failed to Delete ', 'error');
            } else {
                const updatedTabs = tabs.filter((_) => _.id !== data.id);
                setTabs(updatedTabs);
                setLoading(false);
                showAlert('Updated Successfully', 'success');
            }
        } catch (ex: any) {
            showAlert(ex.response.data.message || 'Failed to delete ', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }
    const handleAddOrUpdateTab = () => {
        if (!tabName) {
            showAlert("Tab name is required", "error")
            return
        }
        if (editingIndex !== null) {
            const updatedTab = { id: tabs[editingIndex].id, tabName, sortId: Number(sortId) } as NoticeTab;
            editTab(updatedTab)

        } else {
            const newTab: NoticeTab = {
                tabName,
                sortId: Number(sortId),
            } as NoticeTab;
            createTab(newTab);
        }
        setTabName('');
        setSortId('');
    };

    const handleEditTab = (index: number) => {
        setEditingIndex(index);
        setTabName(tabs[index].tabName);
        setSortId(tabs[index].sortId);
    };

    const handleRemoveTab = (index: number) => {
        deleteTab(tabs[index])
    };

    const getNoticeTabs = async () => {
        try {
            const response = await axiosService.get<NoticeTab[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.noticeTabs]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get noticeBoards', 'error');
                setTabs([])
            } else {
                const json = response.data;
                setTabs(json);
            }
        } catch (ex: any) {
            setTabs([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get noticeBoards', 'error');
            console.error("Exception Caught", ex)
        }
    }
    React.useEffect(() => {
        getNoticeTabs();
        getNoticeLayout();
    }, [])
    return (
        <div>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Tab Manager</Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>

                    </Stack>
                </Stack>
                <div>
                    <Button
                        startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/noticeBoards')}
                    >
                        View Notice
                    </Button>
                    <Button
                        startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/noticeBoards/add')}
                    >
                        Add Notice
                    </Button>
                </div>
            </Stack>
            <Card>
                <CardHeader title="Notice Layout"></CardHeader>
                <CardContent>

                    <form onSubmit={handleSubmit(onLayoutSubmit)}>
                        <Stack direction="row" sx={{ alignItems: "end" }}>
                            <FormControl>
                                <FormLabel id="OTPMode-group-label">Zoom Mode</FormLabel>
                                <Stack
                                    direction="row"
                                    component={RadioGroup}
                                    {...register("layout")}
                                    value={layoutValue}
                                    onChange={(e) => { setLayoutValue(e.target.value) }}                        >
                                    <FormControlLabel value={NoticeLayout.FullDetails} control={<Radio />} label={NoticeLayout.FullDetails} />
                                    <FormControlLabel value={NoticeLayout.ImageSummaries} control={<Radio />} label={NoticeLayout.ImageSummaries} />
                                    <FormControlLabel value={NoticeLayout.BarSummaries} control={<Radio />} label={NoticeLayout.BarSummaries} />
                                </Stack>
                            </FormControl>
                            <Box>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Box>
                        </Stack>
                    </form>

                </CardContent>
            </Card>
            <br />
            <Card>
                <CardHeader title="Tabs"></CardHeader>
                <CardContent>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Tab Name"
                                value={tabName}
                                onChange={(e) => setTabName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Sort ID"
                                type="number"
                                value={sortId}
                                onChange={(e) => setSortId(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddOrUpdateTab}
                                startIcon={editingIndex !== null ? <Edit /> : <Add />}
                                fullWidth
                            >
                                {editingIndex !== null ? 'Update' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>

                    <List>
                        {tabs.map((tab, index) => (
                            <ListItem key={tab.id}>
                                <ListItemText primary={tab.tabName} secondary={`Sort ID: ${tab.sortId}`} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditTab(index)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveTab(index)}>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
        </div>
    );
};

export default NoticeTabs;
