const DB_NAME = "saloon";
const DB_VERSION = 1;
const STORE_NAME = "cartItems";

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const addItem = async (item) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const timestampedItem = {
      ...item,
      addedAt: Date.now(),
    };
    const request = store.add(timestampedItem);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

export const getItems = async () => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAll();

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

export const getItemById = async (id) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(id);

    request.onsuccess = () => {
      const sortedItems = request.result.sort((a, b) => b.addedAt - a.addedAt);
      resolve(sortedItems);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

export const updateItemById = async (id, updatedItem) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(id);

    request.onsuccess = () => {
      const item = request.result;
      if (item) {
        Object.assign(item, updatedItem);
        const updateRequest = store.put(item);
        updateRequest.onsuccess = () => {
          resolve(updateRequest.result);
        };
        updateRequest.onerror = () => {
          reject(updateRequest.error);
        };
      } else {
        reject(new Error("Item not found"));
      }
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

export const deleteItemById = async (id) => {
  const db = await openDB(); // Assuming openDB() is your function to open IndexedDB
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(id);

    request.onsuccess = () => {
      resolve(`Item with id ${id} deleted successfully`);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

export const clearAllItems = async () => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.clear();

    request.onsuccess = () => {
      resolve("All items have been cleared from the store");
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};
