import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const handleSubmit = (event: any) => {
    login(email, password)
      .then((isLoggedIn: string) => {
        if (isLoggedIn == "true") {
          setMessage("");
          navigate("/home");
        } else {
          setMessage(isLoggedIn);
        }
      })
      .catch((ex: any) => {
        setMessage(ex.message);
      });
    event.preventDefault();
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} sx={{ minHeight: "100vh" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/suibox_logo.jpg`}
              alt="Brand Logo"
              style={{ maxWidth: "50%", height: "auto", textAlign: "center" }}
            />
          </Box>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: "80%" }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Typography color="red">{message}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/login_banner.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </Container>
  );
};

export default SignInForm;
