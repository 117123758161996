/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import DateFormate from "../../libs/DateFormate";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

interface City {
  name: string;
  id: string;
}

const AppointmentPage: React.FC = () => {
  const events = [
    { title: "2:00 PM Ashley", date: "2024-08-14" },
    { title: "1:30 PM Elise", date: "2024-08-15" },
    { title: "2:00 PM John", date: "2024-08-01" },
    { title: "1:30 PM Elise", date: "2024-08-02" },
    { title: "1:30 PM Elise", date: "2024-08-05" },
    { title: "3:30 PM Elise", date: "2024-08-08" },
    { title: "4:30 PM John", date: "2024-08-08" },
  ];

  //form-controls
  const [appointMember, setAppointMember] = useState(false);
  const [walkInName, setwalkInName] = useState("");
  const [countryCode, setCountryCode] = useState("+60");
  const [mobileNum, setmobileNum] = useState("");
  const [appointmentDate, setAppointmentDate] = useState<any>(null);
  const [showAppointmentEndTime, setShowAppointmentEndTime] =
    useState<boolean>(false);
  const [staffId, setStaffId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [remarks, setRemarks] = useState("");

  const [date, setDate] = useState<any>(moment(new Date()));
  const [showAppointPopup, setShowAppointmentPopup] = useState<boolean>(false);
  const [showStaffOffDayPopup, setShowStaffOffDayPopup] =
    useState<boolean>(false);
  const [ingredients, setIngredients] = useState<string[]>([]);

  const onIngredientsChange = (e: any) => {
    let _ingredients = [...ingredients];

    if (e.checked) _ingredients.push(e.target.value);
    else _ingredients.splice(_ingredients.indexOf(e.target.value), 1);

    setIngredients(_ingredients);
  };

  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const cities: City[] = [
    { name: "New York", id: "NY" },
    { name: "Rome", id: "RM" },
    { name: "London", id: "LDN" },
    { name: "Istanbul", id: "IST" },
    { name: "Paris", id: "PRS" },
  ];

  const countriesCode: any[] = [
    { name: "(+60)Malasia", id: "+60" },
    { name: "(+91)India", id: "+91" },
  ];

  const staffs: any[] = [
    { name: "Jenny", id: "+jenny" },
    { name: "John", id: "john" },
  ];

  const services: any[] = [
    { name: "Eye Brow - RM120", id: "eqteq" },
    { name: "Eye Brow (Promotion 10%) - RM10B", id: "malasia" },
  ];

  const calendarRef = useRef<any>(null);

  const [currentView, setCurrentView] = useState<string>("dayGridMonth");

  const handleDateClick = (arg: any) => {
    const title = window.prompt("New Event name");
    if (title) {
      const calendarApi = arg.view.calendar;
      calendarApi.addEvent({
        title,
        start: arg.date,
        allDay: true,
      });
    }
  };

  const onDeleteCancel = () => {
    if (showStaffOffDayPopup) {
      setShowStaffOffDayPopup(false);
    } else {
      setShowAppointmentPopup(false);
    }
  };

  const handleViewChange = (view: string) => {
    setCurrentView(view);
  };

  const getHeaderToolbar = () => {
    switch (currentView) {
      case "dayGridMonth":
        return {
          left: "today,prev,next title",
          center: "",
          right: "",
        };
      case "timeGridWeek":
        return {
          left: "",
          center: "prev,next",
          right: "",
        };
      case "timeGridDay":
        return {
          left: "",
          center: "prev,next",
          right: "",
        };
    }
  };

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(currentView);
    }
  }, [currentView]);

  return (
    <Stack spacing={3}>
      <Stack spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Appoinment</Typography>
        </Stack>
        <div className="flex justify-content-center ">
          <DatePicker
            sx={{ width: "100%" }}
            value={date}
            onChange={(date: any) => setDate(date)}
            format="DD/MM/YYYY"
          />
          <div className="col-6 flex gap-3">
            <Button
              variant="contained"
              onClick={() => {
                setShowStaffOffDayPopup(true);
              }}
            >
              Manage staff off-day
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                setShowAppointmentPopup(true);
              }}
            >
              Add new appointment
            </Button>
          </div>
        </div>
        <div className="grid my-4">
          <div className="col-8 flex flex-column gap-1 bg-white border-round-xs align-items-center justify-content-center">
            <label className="">Total appointment for today</label>
            <label className="font-bold text-4xl">3</label>
          </div>
          <div className="col-4 flex flex-column gap-1 align-items-center justify-content-center">
            <label className="">Show in:</label>
            <div className="flex flex-row gap-1">
              <Button
                variant="contained"
                className={`calendar-btn ${
                  currentView == "dayGridMonth" ? "selected" : ""
                }`}
                onClick={() => handleViewChange("dayGridMonth")}
              >
                Month
              </Button>
              <Button
                variant="contained"
                className={`calendar-btn ${
                  currentView == "timeGridWeek" ? "selected" : ""
                }`}
                onClick={() => handleViewChange("timeGridWeek")}
              >
                Day (Table)
              </Button>
              <Button
                variant="contained"
                className={`calendar-btn ${
                  currentView == "timeGridDay" ? "selected" : ""
                }`}
                onClick={() => handleViewChange("timeGridDay")}
              >
                Day (List)
              </Button>
            </div>
          </div>
        </div>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={currentView}
          headerToolbar={getHeaderToolbar()}
          events={events}
          dateClick={handleDateClick}
        />
        <Dialog
          open={showAppointPopup}
          PaperProps={{
            sx: {
              width: "40vw",
              maxWidth: "none",
            },
          }}
          onClose={() => {
            if (!showAppointPopup) return;
            setShowAppointmentPopup(false);
          }}
        >
          <DialogTitle>Add new appointment</DialogTitle>
          <DialogContent>
            <form>
              <div className="grid m-0">
                <div className="col-12 flex align-items-center">
                  <Checkbox
                    id="appointmember"
                    checked={appointMember}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAppointMember(true);
                      } else {
                        setAppointMember(false);
                      }
                    }}
                  />
                  <label htmlFor="appointmember" className="ml-2 form-label">
                    Appointment for member
                  </label>
                </div>
                <div className="col-12">
                  <TextField
                    label="Walk in name"
                    value={walkInName}
                    onChange={(e) => setwalkInName(e.target.value)}
                    fullWidth
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div className="col-12">
                  <div className="grid">
                    <div className="col-4">
                      <TextField
                        label="Country code"
                        select
                        id="countrycode"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value as any)}
                        className="w-full"
                        fullWidth
                        InputProps={{
                          style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      >
                        {countriesCode.map((city: any) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="col-8">
                      <TextField
                        label="Phone number"
                        value={mobileNum}
                        onChange={(e) => setmobileNum(e.target.value)}
                        fullWidth
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="grid">
                    <div className="col-4">
                      {/* <DatePicker
                      selected={appointmentDate}
                      onChange={(date: any) => setAppointmentDate(date)}
                      customInput={
                        <TextField
                          variant="outlined"
                          label="Appointment Date"
                          fullWidth
                        />
                      }
                      dateFormat="dd/MM/yyyy"
                    /> */}
                      <DatePicker
                        sx={{ width: "100%" }}
                        value={appointmentDate}
                        onChange={(date: any) => setAppointmentDate(date)}
                      />
                      {/* <Calendar
                      value={appointmentDate}
                      onChange={(e) => setAppointmentDate(e.value)}
                      dateFormat="dd/mm/yy"
                      className="w-full"
                      // showButtonBar
                    /> */}
                    </div>
                    <div className="col-8">
                      {/* <Calendar
                      value={appointmentStartTime}
                      onChange={(e) => setAppointmentStartTime(e.value)}
                      timeOnly
                      hourFormat="12"
                      className="w-full"
                      // showButtonBar
                    /> */}

                      {/* <DatePicker
                      selected={appointmentStartTime}
                      onChange={(date) => setAppointmentStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={10}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      customInput={
                        <TextField
                          variant="outlined"
                          label="Appointment time"
                          fullWidth
                        />
                      }
                    /> */}
                      <DatePicker
                        sx={{ width: "100%" }}
                        value={appointmentDate}
                        onChange={(date: any) => setAppointmentDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 flex align-items-center">
                  <Checkbox
                    id="showappointendtime"
                    checked={showAppointmentEndTime}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setShowAppointmentEndTime(true);
                      } else {
                        setShowAppointmentEndTime(false);
                      }
                    }}
                  />
                  <label
                    htmlFor="showappointendtime"
                    className="ml-2 form-label"
                  >
                    Appointment end time
                  </label>
                </div>
                <div className="col-12">
                  <TextField
                    label="Staff"
                    select
                    id="staff"
                    value={staffId}
                    onChange={(e) => setStaffId(e.target.value as any)}
                    className="w-full"
                    fullWidth
                    InputProps={{
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  >
                    {staffs.map((city: any) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-12">
                  <TextField
                    label="Branch"
                    select
                    id="branch"
                    value={branchId}
                    onChange={(e) => setBranchId(e.target.value as any)}
                    className="w-full"
                    fullWidth
                    InputProps={{
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  >
                    {staffs.map((city: any) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-12">
                  <TextField
                    label="Services"
                    select
                    id="service"
                    value={serviceId}
                    onChange={(e) => setServiceId(e.target.value as any)}
                    className="w-full"
                    fullWidth
                    InputProps={{
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  >
                    {services.map((city: any) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="flex flex-row align-items-center justify-content-between mt-1">
                    <label htmlFor="walkinname" className="form-label">
                      + services
                    </label>
                    <Button variant="contained" className="py-1 add-button">
                      select
                    </Button>
                  </div>
                </div>
                <div className="col-12">
                  <TextField
                    id="remarks"
                    label="Remarks"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    fullWidth
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      marginTop: "10px",
                    }}
                    multiline
                    rows={2}
                  />
                </div>
                <div className="col-12 flex justify-content-end">
                  <Button variant="contained" color="primary">
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    className="ml-2"
                    onClick={onDeleteCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        <Dialog
          open={showStaffOffDayPopup}
          onClose={() => {
            if (!showStaffOffDayPopup) return;
            setShowStaffOffDayPopup(false);
          }}
        >
          <DialogTitle>Manage staff off-day</DialogTitle>
          <DialogContent>
            <form>
              <div className="grid m-0">
                <div className="col-12">
                  {/* <Calendar
                  id="offdaydate"
                  value={offdayDate}
                  onChange={(e) => setOffDayDate(e.value)}
                  dateFormat="dd/mm/yy"
                  className="w-full"
                  // showButtonBar
                /> */}
                  {/* <DatePicker
                  selected={offdayDate}
                  onChange={(date: any) => setOffDayDate(date)}
                  customInput={
                    <TextField
                      variant="outlined"
                      label="Off-day date"
                      fullWidth
                    />
                  }
                  dateFormat="dd/MM/yyyy"
                /> */}
                  <DatePicker
                    sx={{ width: "90%" }}
                    value={appointmentDate}
                    onChange={(date: any) => setAppointmentDate(date)}
                    format="DD/MM/YYYY"
                  />
                </div>
                <div className="col-12 flex align-items-center">
                  <div className="flex flex-wrap justify-content-start gap-3">
                    <div className="flex align-items-center">
                      <Checkbox
                        id="ingredient1"
                        value="Cheese"
                        checked={ingredients.includes("Cheese")}
                        onChange={onIngredientsChange}
                      />
                      <label htmlFor="ingredient1" className="ml-2">
                        Jerry
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        id="ingredient2"
                        value="Mushroom"
                        checked={ingredients.includes("Mushroom")}
                        onChange={onIngredientsChange}
                      />
                      <label htmlFor="ingredient2" className="ml-2">
                        John
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        id="ingredient3"
                        value="Pepper"
                        onChange={onIngredientsChange}
                        checked={ingredients.includes("Pepper")}
                      />
                      <label htmlFor="ingredient3" className="ml-2">
                        Dave
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        id="ingredient4"
                        value="Onion"
                        onChange={onIngredientsChange}
                        checked={ingredients.includes("Onion")}
                      />
                      <label htmlFor="ingredient4" className="ml-2">
                        Chong
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        id="ingredient5"
                        value="abc"
                        onChange={onIngredientsChange}
                        checked={ingredients.includes("abc")}
                      />
                      <label htmlFor="ingredient5" className="ml-2">
                        ABC
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        id="ingredient5"
                        value="xyz"
                        onChange={onIngredientsChange}
                        checked={ingredients.includes("xyz")}
                      />
                      <label htmlFor="ingredient4" className="ml-2">
                        XYZ
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        id="ingredient6"
                        value="All"
                        onChange={onIngredientsChange}
                        checked={ingredients.includes("All")}
                      />
                      <label htmlFor="ingredient6" className="ml-2">
                        All
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 flex justify-content-end">
                  <Button variant="contained" color="primary">
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    className="ml-2"
                    onClick={onDeleteCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default AppointmentPage;
