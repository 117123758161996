import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Divider, IconButton, List, ListItem, ListItemText, Container, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import GoogleMapView from './google-map-view';
import { Merchant } from 'src/types/merchant-interface';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import BusinessInfo from './business-info';
import ContactInfo from './contact-info';
import { HeaderImage } from './header-image';
import Gallery from './image-gallery';
import OperatingHours from './operating-hours';


const TabPanel = ({ children, value, index, ...other }: any) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box >
        {children}
      </Box>
    )}
  </div>
);
interface BusinessInfoProps {
  merchant: Merchant;
  outlets: Merchant[]
}

// Styled components
const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white,
  },
}));

const OutletName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const OutletDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ArrowIcon = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',
}));
const OutletList: React.FC<BusinessInfoProps> = ({ merchant, outlets }) => {
  const [openQuickView, setOpenQuickView] = useState<Merchant | undefined>();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const openOutlet = (outlet: Merchant) => {
    setOpenQuickView(outlet)
  }
  const getTabs = () => {
    const tabs: any = [];
    tabs.push(<Tab sx={{
      '&.Mui-selected': {
        backgroundColor: '#000',
        color: 'white',
      },
      '&:not(.Mui-selected)': {
        backgroundColor: '#dcdcdc',
        color: '#000',
      },
      width: "100%"
    }}
      disableRipple label={'Map'} id={'Map'} aria-controls="map" />)
    if (outlets && outlets.length) {
      tabs.push(<Tab sx={{
        '&.Mui-selected': {
          backgroundColor: '#000',
          color: 'white',
        },
        '&:not(.Mui-selected)': {
          backgroundColor: '#dcdcdc',
          color: '#000',
        },
        width: "100%"
      }}
        disableRipple label={'Address'} id={'Address'} aria-controls="map" />
      )
    }
    return tabs;
  }
  return (
    <Box sx={{ width: '100%', pl: 2, pr: 2 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label=""
        variant="fullWidth"
        TabIndicatorProps={{ style: { background: merchant?.HeaderColorCode } }}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          "&.Mui-selected": {
            color: merchant?.HeaderColorCode || "initial"
          }
        }}
      >
        {getTabs()}
      </Tabs>
      {/* <Tabs
        value={value}
        onChange={handleChange}
        aria-label=""
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >

      </Tabs> */}
      <TabPanel style={{
        padding: 0, '& .MuiBox-root': {
          padding: '0px',
        }
      }} value={value} index={0}>
        <GoogleMapView merchant={merchant} outlets={outlets ?? []} openOutletCallback={openOutlet} />
      </TabPanel>
      {outlets && outlets.length ? <TabPanel style={{
        padding: 0, '& .MuiBox-root': {
          padding: '0px',
        }
      }} fullWidth value={value} index={1}>
        <List>
          {outlets.map((outlet, index) => (
            <StyledListItem key={index} onClick={() => openOutlet(outlet)}>
              <ListItemText
                primary={<OutletName>{outlet.MerchantName}</OutletName>}
                secondary={
                  <Box>
                    <OutletDetails>{outlet.MerchantAddress}</OutletDetails>

                  </Box>
                }
              />
              <ArrowIcon>
                <ArrowForwardIcon />
              </ArrowIcon>
              {index < outlets.length - 1 && <Divider />}
            </StyledListItem>
          ))}
        </List>
      </TabPanel> : <></>}
      <Drawer
        variant={'temporary'}
        open={!!openQuickView}
        onClose={() => setOpenQuickView(undefined)}
        anchor='bottom'
        PaperProps={{
          sx: {
            borderRadius: '16px 16px 0 0', // Rounded corners at the top
            height: '75vh', // Adjust height as needed
          },
        }}
      >
        <Box
          sx={{ padding: 2 }}
          role="presentation"
        >
          <Container sx={{ p: 0 }} maxWidth="sm">
            {openQuickView && <Box ><HeaderImage merchant={openQuickView} /></Box>}
            {openQuickView && <Box sx={{ pt: 2 }} ><BusinessInfo merchant={openQuickView} /></Box>}
            {openQuickView && <Box sx={{ pt: 2 }} ><OperatingHours merchant={openQuickView} /></Box>}
            {openQuickView && openQuickView.MerchantAddress && openQuickView.MerchantEmail && openQuickView.MerchantPhone1 && openQuickView.MerchantPhone2 && <Box sx={{ pt: 2 }} ><ContactInfo merchant={openQuickView} /></Box>}
            {openQuickView && <Box sx={{ pt: 2 }} ><Gallery merchant={openQuickView} /></Box>}
            {openQuickView && <Box sx={{ pt: 2 }} ><OutletList merchant={openQuickView} outlets={[]} /></Box>}

          </Container>
        </Box>

      </Drawer>
    </Box>
  );
};

export default OutletList;
