import { Avatar, Card, CardContent, CardMedia, Link, Stack, Typography } from "@mui/material"
import moment from "moment"
import { useMerchant } from "src/context/MerchantContext"
import { NoticeBoard } from "src/intefaces/notice-board.inteface"

export const ShortPreviewImgNotice = ({ notice, onClick }: { notice: NoticeBoard, onClick: () => any }) => {
    const { merchant } = useMerchant();
    return <Card onClick={onClick}>
        <CardMedia
            sx={{ height: "200px" }}
            image={notice.image}
        />
        <CardContent>
            <Typography
                variant="body1"
                sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 3, // Limits text to 3 lines
                    lineHeight: '1.5rem', // Set line height (adjust as needed)
                    height: '4.5rem', // 3 lines * lineHeight (3 * 1.5rem)
                    textAlign:'left',
                    fontSize: merchant?.NoticeBoardFontsizeTitle + "px"
                }}
            >
                <b>{notice.title}</b>
            </Typography>
        </CardContent>
    </Card>
}
