import { RecordTypes } from "./types/enum";
// const BASE_URL = "http://localhost:9008";
const BASE_URL = "https://i2ilvj0s6k.execute-api.ap-south-1.amazonaws.com/dev";

export const CONSTANTS = {
  BOTTOMBUTTONFEATURE: {
    HOME: "Home",
    GALLERY: "Gallery",
    APPOINTMENT: "Appointment+Message",
    LUCKYDRAW: "Lucky Draw",
    BOOKING: "Booking",
    CHECKIN: "Check In",
    NOTICE: "Notice",
    CART: "Cart",
    PROFILE: "Profile",
    MARKETING: "Marketing/Reward",
    MESSAGE: "Message",
    COUPON: "Coupon",
  } as any,
  ApiConstants: {
    GENERIC: {
      get: {
        [RecordTypes.merchant]: `${BASE_URL}/merchant`,
        [RecordTypes.outlet]: `${BASE_URL}/outlet`,
        [RecordTypes.partner]: `${BASE_URL}/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
      },
      find: {
        [RecordTypes.merchant]: `${process.env.REACT_APP_API_BASE_URL}/api/merchant/find`,
        [RecordTypes.outlet]: `${process.env.REACT_APP_API_BASE_URL}/api/outlet/find`,
        [RecordTypes.partner]: `${process.env.REACT_APP_API_BASE_URL}/api/partner/find`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff/find`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards/find`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs/find`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons/find`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages/find`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm/find`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory/find`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon/find`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/find`,
      },
      getById: {
        [RecordTypes.merchant]: `${BASE_URL}/merchant`,
        [RecordTypes.outlet]: `${BASE_URL}/outlet`,
        [RecordTypes.partner]: `${BASE_URL}/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff/find`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards/find`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs/find`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons/find`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages/find`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm/find`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory/find`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon/find`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/find`,
      },
      delete: {
        [RecordTypes.merchant]: `${BASE_URL}/merchant`,
        [RecordTypes.outlet]: `${BASE_URL}/outlet`,
        [RecordTypes.partner]: `${BASE_URL}/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
      },
      post: {
        [RecordTypes.merchant]: `${BASE_URL}/merchant`,
        [RecordTypes.outlet]: `${BASE_URL}/outlet`,
        [RecordTypes.partner]: `${BASE_URL}/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
      },
      put: {
        [RecordTypes.merchant]: `${BASE_URL}/merchant`,
        [RecordTypes.outlet]: `${BASE_URL}/outlet`,
        [RecordTypes.partner]: `${BASE_URL}/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
      },
    },
    findAllGroupInfo: `${process.env.REACT_APP_API_BASE_URL}/api/outlet-groups/findAllGroupInfo`,
    outletGroupCreate: `${process.env.REACT_APP_API_BASE_URL}/api/outlet-groups`,
    outletGroupDelete: `${process.env.REACT_APP_API_BASE_URL}/api/outlet-groups`,

    getMembers: `${BASE_URL}/members`,
    getMemberbyId: `${BASE_URL}/members`,
    deleteMember: `${BASE_URL}/members`,
    postMembers: `${BASE_URL}/members`,
    putMember: `${BASE_URL}/members`,
    inviteMemberToken: `${BASE_URL}/members/inviteToken`,
    findMemberByMobile: `${BASE_URL}/members/findByMobile`,

    login: `${BASE_URL}/user/login`,
    signup: `${process.env.REACT_APP_API_BASE_URL}/api/user/signup`,

    getAccount: `${BASE_URL}/account`,
    updateAccount: `${BASE_URL}/account`,

    uploadImage: `${BASE_URL}/file/uploadimage`,

    postMerchantSettings: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings`,
    putMerchanSettings: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings`,
    getMerchanSettings: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings`,

    getMerchantProfile: `${BASE_URL}/public/merchant`,
    getMerchantOutlets: `${process.env.REACT_APP_API_BASE_URL}/api/public/merchantoutlets`,
    getMerchantProfileTokenized: `${process.env.REACT_APP_API_BASE_URL}/api/public/profile`,
    postPublicMember: `${process.env.REACT_APP_API_BASE_URL}/api/public/member/register`,
    getBottomButtons: `${process.env.REACT_APP_API_BASE_URL}/api/public/bottombuttons`,
    getNoticeTabs: `${process.env.REACT_APP_API_BASE_URL}/api/public/getNoticeTabs`,
    getAllNotices: `${process.env.REACT_APP_API_BASE_URL}/api/public/getAllNotices`,
    findOneNotice: `${process.env.REACT_APP_API_BASE_URL}/api/public/findOneNotice`,
    public_getNoticeLayout: `${process.env.REACT_APP_API_BASE_URL}/api/public/getNoticeLayout`,
    public_findMessageForm: `${process.env.REACT_APP_API_BASE_URL}/api/public/findMessageForm`,
    public_findMessageCategories: `${process.env.REACT_APP_API_BASE_URL}/api/public/findMessageCategories`,
    public_createMessage: `${process.env.REACT_APP_API_BASE_URL}/api/public/createMessage`,
    public_findCoupons: `${process.env.REACT_APP_API_BASE_URL}/api/public/findCoupons`,
    public_member_signup: `${process.env.REACT_APP_API_BASE_URL}/api/public/membersignup`,
    public_memberLogin: `${process.env.REACT_APP_API_BASE_URL}/api/public/memberLogin`,
    getMemberDetails: `${process.env.REACT_APP_API_BASE_URL}/api/getMemberDetails`,

    redeemCoupon: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/redeem`,
    transferCoupon: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/transfer`,

    getNoticeLayout: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings/getNoticeLayout`,
    updateNoticeLayout: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings/updateNoticeLayout`,
  },
};
