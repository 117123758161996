
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';

import * as Icons from '@mui/icons-material';
import { useAlert } from '../../components/CommonAlert';
import { useParams } from 'react-router-dom';
import { RecordActions, RecordTypes, Roles } from '../../types/enum';
import ImageUpload from '../../components/ImageUpload';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Coupon } from 'src/intefaces/coupon.interface';
import { HtmlEditor } from 'src/components/HtmlEditor';
import IconSelector from 'src/components/IconSelector';
import { MuiColorInput } from 'mui-color-input';

const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
]
export function CForm(): React.JSX.Element {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState("General");
    const [outlets, setOutlets] = React.useState<Coupon[]>([])
    const { action, id, token } = useParams();
    const { user, getToken } = useAuth();
    const [iconSelectorOpen, setIconSelectorOpen] = useState<string>();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const { showAlert } = useAlert();
    const methods = useForm<Coupon>({
        defaultValues: {
            id: 0,
            name:"",
            validAfter: "",
            validUntil: "",
            description: "",
            terms: "",
            format: "image",
            allowTransfer: false,
            allowRedeem: false,
            criteria: "amount",
            conditionValue: 0,
            promocode: "",
            image: "",
            icon: "",
            themeColor: "",
            bgColor: "",
            iconColor: "",
        }
    });

    const createNewCoupon = async (data: any) => {
        try {

            const response = await (await axiosService.post<Coupon | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.coupon]}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Coupon', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Coupon Created Successfully', 'success');
                navigate(`/coupons/edit/${response.data.id}`)
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save Coupon', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editCoupon = async (data: any) => {
        try {
            const response = await axiosService.put<Coupon>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.coupon]}/${id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update Coupon', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Coupon Updated Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to Update Coupon', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }

    const getCouponDetails = async () => {
        try {
            const response = await axiosService.get<Coupon>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.coupon]}/${id}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive Coupon Details', 'error');
            } else {
                const json: Coupon = response.data;
                methods.reset(json);
            }
        } catch (ex) {
            showAlert('Failed to Retrive Coupon Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const onSubmit = methods.handleSubmit(async (data: any) => {
        try {
            if (loading) {
                return
            }
            setLoading(true);
            if (action == RecordActions.add) {
                createNewCoupon(data);
            } else if (action == RecordActions.edit) {
                editCoupon(data);
            }
        }
        catch (ex) {
            setLoading(false);
        } finally {

        }
    });
    React.useEffect(() => {
        if (action == RecordActions.edit || action == RecordActions.view) {
            getCouponDetails();
        }
    }, [])
    return (
        <FormProvider {...methods} >
            <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="name"
                                    rules={{ required: 'Name is required' }}
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            helperText={typeof methods.formState.errors?.name?.message === 'string' ? methods.formState.errors?.name?.message : ""}
                                            FormHelperTextProps={{ style: { color: 'red' } }}
                                            {...field}
                                            fullWidth
                                            id="Name"
                                            label="Name"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="validAfter"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="validAfter"
                                            label="Valid date will take effect after"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="validUntil"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        Day
                                                    </InputAdornment>
                                                ),
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Valid Duration"
                                            id="validUntil"
                                        />} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="description"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <InputLabel>Description</InputLabel>
                                            <HtmlEditor
                                                key={field.name}
                                                {...field}
                                                placeholder="Enter your description here"
                                            />
                                        </div>
                                    )} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="terms"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <InputLabel>Terms & Conditions</InputLabel>
                                            <HtmlEditor
                                                key={field.name}
                                                {...field}
                                                placeholder="Enter your terms & conditions here"
                                            />
                                        </div>
                                    )} />
                            </Grid>
                        </Grid>
                    </CardContent >
                </Card >
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="format"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (<FormControl>
                                        <RadioGroup
                                            {...field}
                                            value={field.value || ''}
                                            onChange={(e) => field.onChange(e.target.value)} >
                                            <Stack direction={"row"}>
                                                <FormControlLabel value="image" control={<Radio disabled={action == RecordActions.view} />} label="Upload & Show" />
                                                <FormControlLabel value="card" control={<Radio disabled={action == RecordActions.view} />} label="Fix Format" />

                                            </Stack>
                                        </RadioGroup>
                                    </FormControl>)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="image"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value ?? ""} mode={action} name={"image"} label="Image" />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{"alignContent":"center","textAlign":"center"}}>
                                <Controller
                                    name="icon"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                        <div >

                                            <Button variant="outlined" onClick={() => setIconSelectorOpen("true")}>
                                                Select Icon
                                            </Button>
                                            {field.value && (
                                                <IconButton onClick={() => setIconSelectorOpen("true")}>
                                                    {React.createElement(Icons[(field.value) as keyof typeof Icons])}
                                                </IconButton>
                                            )}

                                            <IconSelector
                                                id={"icon"}
                                                open={!!iconSelectorOpen}
                                                onClose={() => setIconSelectorOpen("")}
                                                onSelect={(iconname) => field.onChange(iconname)}
                                            />
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} >
                                <Stack>
                                    <Controller
                                        name="themeColor"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <Stack>
                                                <InputLabel>Theme Color</InputLabel>
                                                <MuiColorInput format="hex" {...field} />
                                            </Stack>
                                        } />
                                    <Controller
                                        name="bgColor"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <Stack>
                                                <InputLabel>Background Color</InputLabel>
                                                <MuiColorInput format="hex" {...field} />
                                            </Stack>
                                        } />
                                    <Controller
                                        name="iconColor"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <Stack>
                                                <InputLabel>Icon Color</InputLabel>
                                                <MuiColorInput format="hex" {...field} />
                                            </Stack>
                                        } />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Stack>
                                    <Stack>Allow</Stack>
                                    <Stack direction={"row"}>
                                        <Controller
                                            name="allowTransfer"
                                            control={methods.control}
                                            render={({ field }) =>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    }
                                                    label="Transfer"
                                                />
                                            } />
                                        <Controller
                                            name="allowRedeem"
                                            control={methods.control}
                                            render={({ field }) =>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    }
                                                    label="Redeem"
                                                />
                                            } />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent >
                </Card >
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="criteria"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (<FormControl>
                                        <FormLabel>Condition</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            value={field.value || ''}
                                            onChange={(e) => field.onChange(e.target.value)} >
                                            <Stack>
                                                <FormControlLabel value="amount" control={<Radio disabled={action == RecordActions.view} />} label="Amount" />
                                                <FormControlLabel value="percent" control={<Radio disabled={action == RecordActions.view} />} label="Percentage" />
                                                <FormControlLabel value="fixed" control={<Radio disabled={action == RecordActions.view} />} label="Fix Price" />
                                            </Stack>
                                        </RadioGroup>
                                    </FormControl>)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="conditionValue"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        RM
                                                    </InputAdornment>
                                                ),
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label=""
                                            id="conditionValue"
                                        />)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="promocode"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            InputProps={{

                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Promo Code"
                                            id="promocode"
                                        />)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <br/>
                <br/>
                <br/>
                <br/>
                {action != RecordActions.view && <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        zIndex: 9,
                        width: '100%',
                        backgroundColor: '#f5f5f5',
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'flex-end', // Align the button to the right
                        boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
                    }}
                >
                    <Button disabled={loading} variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Box>
                }
            </Box>
        </FormProvider >
    );
}

