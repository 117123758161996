// src/components/Home.tsx
import { AppBar, Toolbar, Stack, IconButton, Typography, Avatar, Box, Divider, MenuItem, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { keyframes } from '@mui/system';
import { MForm } from './form';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { Merchant } from 'src/types/merchant-interface';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { Member } from 'src/types/member-interface';
import { SetMealRounded } from '@mui/icons-material';

const bounceAnimation = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const MemberInvite: React.FC = () => {
    const { user } = useAuth();
    const { token } = useParams();
    const [merchantProfile, setMerchantProfile] = useState<Merchant>();
    const [valid, setValid] = useState<boolean>(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const getMerchantProfile = async () => {
        try {
            const response = await axiosService.get<Merchant | any>(`${CONSTANTS.ApiConstants.getMerchantProfileTokenized}/${token}`)
            if (response.status != 200) {
                setValid(false);
            } else {
                setValid(true);
                const json = response.data;
                setMerchantProfile(json)
            }
        } catch (ex: any) {
            setValid(false);
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        getMerchantProfile();
    }, [])
    return (
        <React.Fragment>
            <Toolbar >
                <Stack sx={{ p: 1 }}>
                    <Stack direction={"row"}>
                        {merchantProfile?.MerchantLogo && <img src={`${merchantProfile?.MerchantLogo}`} height={50} />}
                        <Typography variant={"h4"}>
                            {merchantProfile?.MerchantName}
                        </Typography>

                    </Stack>
                    <Typography variant={"h6"}>
                        Member Registration
                    </Typography>
                </Stack>
            </Toolbar>
            {(!queryParams.get('complete') || queryParams.get('complete')?.toString() == 'false') ?
                <Box >
                    {valid ? <MForm publicForm={true} /> : <Typography sx={{ textAlign: "center" }}> Invite expired or invalid </Typography>}
                </Box> :
                <Container maxWidth="sm" sx={{ textAlign: 'center', py: 5 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 3,
                        }}
                    >
                        <CheckCircleIcon
                            sx={{
                                fontSize: '4rem',
                                color: '#4caf50',
                                animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                            }}
                        />
                    </Box>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Thank You!
                    </Typography>
                    <Typography variant="body1">
                        Your registration is complete.
                    </Typography>
                </Container>
            }
        </React.Fragment>
    );
};


