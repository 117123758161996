// src/context/AuthContext.tsx

import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axiosService from '../services/axios';
import { User } from '../types/user-interface';
import { CONSTANTS } from '../constants';
import { useAlert } from '../components/CommonAlert';
import { jwtDecode } from 'jwt-decode';
import { Token } from 'typescript';
import { TokenObject } from '../types/token-object';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Define the type for the context
interface AuthContextType {
  isAuthenticated: boolean;
  user: TokenObject | null;
  login: (username: string, password: string) => Promise<string>;
  memberLogin: (username: string, password: string, merchantid: number) => Promise<string>;
  logout: () => void;
  getToken: () => string | null;
  isTokenValid: (token: string) => boolean;
}

// Create context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<TokenObject | null>(null);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isTokenValid = (token: string) => {
    try {
      const decoded: TokenObject = jwtDecode(token);
      return Date.now() < (decoded.exp * 1000); // Check if token is expired
    } catch (error) {
      return false;
    }
  };
  const getToken = (): string | null => {
    return queryParams.get('ref') ? queryParams.get('ref') : localStorage.getItem('token');
  }
  const login = async (username: string, password: string) => {
    try {
      const response = await axiosService.post<User | any>(`${CONSTANTS.ApiConstants.login}`, { username: username, password: password })
      if (response.status != 200) {
        showAlert(response?.data?.message || "Failed to login", "error");
        console.error("Exception Caught", response);
        return response?.data?.message || "Failed to login";
      } else {
        const token = response.data.token;
        localStorage.setItem('token', token);
        const decodedToken: TokenObject = jwtDecode(token);
        setIsAuthenticated(true);
        setUser(decodedToken);
        return "true";
      }
    } catch (ex: any) {
      console.error("Exception Caught", ex)
      if (ex?.response?.status == 500) {
        return ex?.response?.data?.message
      } else if (ex.code == "ERR_NETWORK") {
        return "Failed to connect to server"
      }
      return ex?.response?.data?.message ?? ex.message ?? "Failed to login"

    }
  };
  const memberLogin = async (username: string, password: string, merchantid: number) => {
    try {
      const response = await axiosService.post<User | any>(`${CONSTANTS.ApiConstants.public_memberLogin}/${merchantid}`, { mobile: username, password: password })
      if (response.status != 200) {
        showAlert(response?.data?.message || "Failed to login", "error");
        console.error("Exception Caught", response);
        return response?.data?.message || "Failed to login";
      } else {
        const token = response.data.token;
        localStorage.setItem('token', token);
        const decodedToken: TokenObject = jwtDecode(token);
        setIsAuthenticated(true);
        setUser(decodedToken);
        return "true";
      }
    } catch (ex: any) {
      console.error("Exception Caught", ex)
      if (ex?.response?.status == 500) {
        return ex?.response?.data?.message
      } else if (ex.code == "ERR_NETWORK") {
        return "Failed to connect to server"
      }
      return ex?.response?.data?.message ?? ex.message ?? "Failed to login";
    }
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded: TokenObject = jwtDecode(token);
      setIsAuthenticated(true);
      setUser(decoded);
    }
  }, []);
  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
  };
  const publicRoutes = ['/'];
  // useEffect(() => {
  //   console.log("pathname",location.pathname)
  //   if (publicRoutes.includes(location.pathname)) {
  //     return; // Skip authentication check for public routes
  //   }
  //   const token = getToken();

  //   if (token && isTokenValid(token)) {
  //     const decoded: TokenObject = jwtDecode(token);
  //     setIsAuthenticated(true);
  //     setUser(decoded);
  //     //console.log("location.pathname",location.pathname)
  //     navigate(`${location.pathname}${location.search}`);
  //   } else {
  //     // Token is not valid or not present, navigate to login or another appropriate page
  //     navigate('/login');
  //   }
  // }, [navigate, location.pathname]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, getToken, isTokenValid,memberLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for using the context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
