import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { UserPlus as UserPlus } from '@phosphor-icons/react';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { CONSTANTS } from '../../constants';
import { useNavigate } from 'react-router-dom';
import axiosService from '../../services/axios';
import { Box, Card, OutlinedInput, InputAdornment, Divider, InputLabel, CardContent } from '@mui/material';
import { useAlert } from '../../components/CommonAlert';
import MemberInviteDialog from '../../components/ConfirmationDialog';
export default function GlobalMemberSearch(): React.JSX.Element {
    const page = 0;
    const rowsPerPage = 25;
    const [showOtp, setShowOtp] = React.useState<boolean>();
    const [openInvite, setOpenInvite] = React.useState<boolean>(false);
    const [memberId, setMemberId] = React.useState<number>();
    const [searchQuery, setSearchQuery] = React.useState('');
    const [inviteLInk, setInviteLink] = React.useState("");

    const { showAlert } = useAlert();
    const getInviteLink = async () => {
        try {
            const response = await axiosService.get<{ token?: string, message?: string }>(`${CONSTANTS.ApiConstants.inviteMemberToken}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to create link', 'error');
                setInviteLink("")
            } else {
                setInviteLink(`${process.env.REACT_APP_APP_BASE_URL}/member/add/invite?ref=${encodeURIComponent(response.data?.token ?? "")}`);
            }
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to create link', 'error');
            console.error("Exception Caught", ex)
            setInviteLink("");

        }
    }

    const onCopy = () => {
        navigator.clipboard.writeText(inviteLInk).then(() => {
            setOpenInvite(false)
        }).catch(err => {
            setOpenInvite(false)
            showAlert('Failed to copy!', 'error');
            console.log("failed to copy", err);
        });

    }
    const getMember = async () => {
        try {
            setMemberId(0)
            setShowOtp(false);
            const response = await axiosService.get<any>(`${CONSTANTS.ApiConstants.findMemberByMobile}/${searchQuery}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get members', 'error');
            } else {
                const id = response.data.message;
                if (id)
                    navigate(`/members/view/${id}`);
                else {
                    showAlert("Member not found", 'error');
                }
            }
        } catch (ex: any) {
            if (ex?.response?.status == 417) {
                setShowOtp(true);
                setMemberId(ex.response.message);
            } else {
                showAlert('Failed to get members', 'error');
                setShowOtp(false);
            }
        }
    }
    const navigate = useNavigate();
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Search Member in all outlets</Typography>
                </Stack>
                <div>
                    <Button
                        startIcon={<UserPlus fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => { setOpenInvite(true); getInviteLink(); }}
                    >
                        Invite Member
                    </Button>
                    <Button
                        startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/members')}
                    >
                        View All
                    </Button>
                    <Button
                        startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/members/add')}
                    >
                        Add
                    </Button>
                </div>
            </Stack>
            <Box>
                <Card sx={{ p: 2 }}>
                    <CardContent>
                        <OutlinedInput
                            onChange={(e) => setSearchQuery(e.target.value)}
                            fullWidth
                            placeholder="Search member"
                            startAdornment={
                                <InputAdornment position="start">
                                    <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
                                </InputAdornment>
                            }
                            sx={{ maxWidth: '500px' }}
                        />
                        &nbsp;
                        &nbsp;
                        <Button type='submit' variant='contained' onClick={getMember}>Search</Button>
                        {showOtp && <Box>
                            <br />
                            <br />
                            <Divider />
                            <br />
                            <br />
                            <InputLabel>Enter OTP to access this member</InputLabel>

                            <OutlinedInput
                                fullWidth
                                placeholder="Enter OTP"
                                sx={{ maxWidth: '500px' }}
                            />
                            <Button type='button' variant='contained' >Submit</Button>
                        </Box>}
                    </CardContent>
                </Card>
            </Box>
            <MemberInviteDialog
                open={openInvite && !!inviteLInk}
                onClose={() => { setOpenInvite(false); }}
                onConfirm={onCopy}
                confirmLabel={"Copy Link"}
                cancelLabel={"Cancel"}
                message={`Copy and share the link :  ${inviteLInk}`}
                title={"Invite Member"}
            />
        </Stack>
    );
}
