import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Grid, Typography, MenuItem, CircularProgress, Card, CardContent, Container, CardHeader } from '@mui/material';
import axios from 'axios';
import { MessageCategory, Messages } from 'src/intefaces/message.interface';
import ImageUpload from 'src/components/ImageUpload';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { RecordTypes, RecordActions } from 'src/types/enum';
import { useMerchant } from 'src/context/MerchantContext';
import { MessageForm } from 'src/intefaces/message-form.interface';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useAlert } from 'src/components/CommonAlert';
import { useLocation } from 'react-router-dom';
interface formTemplate { visible: boolean, label: string, sequence: number, key: string }
export const QueryForm: React.FC = () => {
    const { control, handleSubmit, reset } = useForm<Messages>();
    const [categories, setCategories] = useState<MessageCategory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [messageFormConfig, setMessageFormConfig] = useState<MessageForm>();
    const [messageFormControls, setMessageFormControls] = useState<formTemplate[]>([]);
    const { merchant } = useMerchant();
    const { showAlert } = useAlert();

    const sortDataBySeq = (data: any) => {
        // Extract the sequence keys and sort them
        const seqKeys = Object.keys(data)
            .filter(key => key.endsWith('Seq'))
            .sort((a, b) => data[a] - data[b]);

        const sortedKeys = seqKeys.map(seqKey => {
            const baseKey = seqKey.replace('Seq', '');
            return {
                'visible': data[baseKey + 'Visible'],
                'label': data[baseKey + 'Label'],
                'sequence': data[baseKey + 'Seq'],
                'key': baseKey
            } as formTemplate;
        });
        // Combine non-sequence related keys with sorted sequence keys
        // const nonSeqKeys = Object.keys(data).filter(key => !sortedKeys.includes(key) && !key.endsWith('Seq'));
        // const finalSortedKeys = [...nonSeqKeys, ...sortedKeys];

        // // Create a new sorted object
        // const sortedData: any = {};
        // finalSortedKeys.forEach(key => {
        //     if (data.hasOwnProperty(key)) {
        //         sortedData[key] = data[key];
        //     }
        // });

        return sortedKeys;
    };

    const getMessageFormDetails = async () => {
        try {
            const response = await axiosService.get<MessageForm>(`${CONSTANTS.ApiConstants.public_findMessageForm}/${merchant?.id}`)
            if (response.status != 200) {

            } else {
                const json: MessageForm = response.data;
                console.log("sortDataBySeqbfore", json)
                console.log("sortDataBySeq", sortDataBySeq(json));
                setMessageFormConfig(json)
                setMessageFormControls(sortDataBySeq(json))
            }
        } catch (ex) {
            console.error("Exception Caught", ex)
        }
    }
    const fetchCategories = async () => {
        try {
            const response = await axiosService.get<MessageCategory[]>(`${CONSTANTS.ApiConstants.public_findMessageCategories}/${merchant?.id}`)
            if (response.status != 200) {

            } else {
                const json = response.data;
                setCategories(json)
            }
        } catch (ex) {
            console.error("Exception Caught", ex)
        }
    };

    useEffect(() => {

        merchant && getMessageFormDetails();
        fetchCategories();
    }, [merchant]);

    const onSubmit = async (data: Messages) => {
        try {

            const response = await (await axiosService.post<Messages | any>(`${CONSTANTS.ApiConstants.public_createMessage}/${merchant?.id}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || `Failed to ${messageFormConfig?.title} Message`, 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert(`${messageFormConfig?.title} Submitted Successfully`, 'success');
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || `Failed to Save ${messageFormConfig?.title}`, 'error');
            console.error("Exception Caught", ex)
        }

    };

    return (
        <Container maxWidth={"sm"} sx={{ pt: 2 }}>
            <Card>
                <CardContent>
                <Typography variant='h6'> {messageFormConfig?.title}</Typography><br/>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {messageFormControls.map((c) => {
                                return c?.visible ? <Grid item xs={12}>
                                    <Controller
                                        name={c.key == 'category' ? 'categoryid' : c.key as any}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => {
                                            if (c.key == 'category') {
                                                return <TextField
                                                    {...field}
                                                    select
                                                    label={messageFormConfig?.categoryLabel}
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                >
                                                    {categories.sort((a, b) => {
                                                        if (a.sortId < b.sortId) {
                                                            return -1;
                                                        } else if (a.sortId > b.sortId) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    }).map((option) => (
                                                        <MenuItem key={option.categoryName} value={option.categoryName}>
                                                            {option.categoryName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            } else if (c.key == 'message') {
                                                return <TextField {...field} label={c.label} fullWidth variant="outlined" multiline rows={4} required />
                                            } else if (c.key == 'file') {
                                                return <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value || ""} name={"File"} label={messageFormConfig?.fileLabel} allowPdf={true} />
                                            } else if (c.key == 'dateTime') {
                                                return <DateTimePicker
                                                    sx={{ width: "100%" }}
                                                    onChange={field.onChange}
                                                    value={field.value || null}
                                                    label={messageFormConfig?.dateTimeLabel}
                                                />
                                            }
                                            return <TextField {...field} label={c?.label} fullWidth variant="outlined" required />
                                        }}
                                    />

                                </Grid> : <></>
                            })}
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>

                    </form>
                </CardContent>
            </Card>

        </Container>
    );
};
