import { useParams } from "react-router-dom";
import React from 'react';
import { Card, CardContent, Grid, FormControlLabel, TextField, Button, CardActions, FormControl, FormLabel, Radio, RadioGroup, InputLabel } from "@mui/material"
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { RecordActions, RecordTypes, Roles } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useAuth } from "../../../context/AuthContext";
import { HtmlEditor } from "src/components/HtmlEditor";

export const OtherDetails = ({ action, id, recordType }: FormProp) => {
    const methods = useFormContext();
    const { user } = useAuth();;
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
                <Controller
                    name="OTPMode"
                    control={methods.control}
                    render={({ field, fieldState }) => <FormControl>
                        <FormLabel id="OTPMode-group-label">OTPMode</FormLabel>
                        <RadioGroup
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)} >
                            <FormControlLabel value="sms" control={<Radio disabled={action == RecordActions.view} />} label="SMS" />
                            <FormControlLabel value="whatsapp" control={<Radio disabled={action == RecordActions.view} />} label="Whatsapp" />
                        </RadioGroup>
                    </FormControl>
                    } />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="TermnPolicy"
                    control={methods.control}
                    render={({ field, fieldState }) =>

                        <div>
                            <InputLabel>Terms & Policy</InputLabel>
                            <HtmlEditor
                                key={field.name}
                                {...field}
                                placeholder="Enter your description here"
                            />
                        </div>
                    } />
            </Grid>
            {recordType == RecordTypes.merchant && <Grid item xs={12} sm={12}>
                <Controller
                    name="Share2EarnDescription"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <div>
                            <InputLabel>Share & Earn Description</InputLabel>
                            <HtmlEditor
                                key={field.name}
                                {...field}
                                placeholder="Enter your description here"
                            />
                        </div>
                    } />
            </Grid>}
        </Grid>)
}