
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import { useParams } from 'react-router-dom';
import { RecordActions, RecordTypes, ZoomMode } from '../../types/enum';
import ImageUpload from '../../components/ImageUpload';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { useState } from 'react';
import { NoticeBoard, NoticeTab } from 'src/intefaces/notice-board.inteface';
import { HtmlEditor } from 'src/components/HtmlEditor';


export function NoticeForm(): React.JSX.Element {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { action, id, token } = useParams();

    const { showAlert } = useAlert();
    const { control, setValue, handleSubmit, reset, formState, getValues } = useForm<NoticeBoard>({
        defaultValues:
        {
            "id": 0,
            "zoomMode": undefined,
            "title": "",
            "description": "",
            "image": "",
            "dateFrom": null,
            "expiry": null,
            "imageLink": "",
            "status": false,
            "pinTop": false,
            "tabs": []
        }

    });

    const { fields } = useFieldArray({
        control,
        name: "tabs",
    });

    const getTabs = async () => {
        try {
            const response = await axiosService.get<NoticeTab[]>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.noticeTabs]}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive tabs ', 'error');
            } else {
                const json: NoticeTab[] = response.data;

                if (action == RecordActions.edit || action == RecordActions.view) {
                    const selectedTabs = getValues("tabs").map(t => t.id);
                    console.log("selectedTabs", selectedTabs)
                    json.map((j: NoticeTab) => { console.log(selectedTabs.includes(j.id), j.id); j.selected = selectedTabs.includes(j.id); return j });
                    setValue("tabs", json);
                } else {
                    json.map((j: NoticeTab) => { j.selected = false; return j });
                    setValue("tabs", json);
                }
            }
        } catch (ex) {
            showAlert('Failed to Retrive NoticeBoard Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const createNewNoticeBoard = async (data: any) => {
        try {
            //remove unselected tabs
            data.tabs = data.tabs.filter((t: NoticeTab) => t.selected).map((t: NoticeTab) => { delete t.selected; return t });
            const response = await (await axiosService.post<NoticeBoard | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.noticeBoard]}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Notive Created Successfully', 'success');
                navigate(`/noticeBoards/edit/${response.data.id}`);
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editNoticeBoard = async (data: any) => {
        try {
            data.tabs = data.tabs.filter((t: NoticeTab) => t.selected).map((t: NoticeTab) => { delete t.selected; return t });
            const response = await axiosService.put<NoticeBoard>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.noticeBoard]}/${id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update NoticeBoard', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('NoticeBoard Updated Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to Update NoticeBoard', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }

    const getNoticeBoardDetails = async () => {
        try {
            const response = await axiosService.get<NoticeBoard>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.noticeBoard]}/${id}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive NoticeBoard Details', 'error');
            } else {
                const json: NoticeBoard = response.data;
                if (json.dateFrom) {
                    json.dateFrom = moment(json.dateFrom ?? null).utc(true).local();
                }
                if (json.expiry) {
                    json.expiry = moment(json.expiry ?? null).utc(true).local();;
                }
                reset(json);
                getTabs();
            }
        } catch (ex) {
            showAlert('Failed to Retrive NoticeBoard Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const handleChecked = (e: any, control: any) => {

    }
    const onSubmit = handleSubmit(async (data: any) => {
        try {
            if (loading) {
                return
            }
            setLoading(true);
            if (action == RecordActions.add) {
                createNewNoticeBoard(data);
            } else if (action == RecordActions.edit) {
                editNoticeBoard(data);
            }
        }
        catch (ex) {
            setLoading(false);
        } finally {

        }
    });

    React.useEffect(() => {
        console.log("Form called");
        if (action == RecordActions.edit || action == RecordActions.view) {
            getNoticeBoardDetails();
        } else {
            getTabs();
        }
    }, [])
    return (
        <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="zoomMode"
                                control={control}
                                render={({ field, fieldState }) => <FormControl>
                                    <FormLabel id="OTPMode-group-label">Zoom Mode</FormLabel>
                                    <RadioGroup
                                        {...field}
                                        value={field.value || ''}
                                        onChange={(e) => field.onChange(e.target.value)}                        >
                                        <FormControlLabel value={ZoomMode.Fullpage} control={<Radio disabled={action == RecordActions.view} />} label={ZoomMode.Fullpage} />
                                        <FormControlLabel value={ZoomMode.Ascend} control={<Radio disabled={action == RecordActions.view} />} label={ZoomMode.Ascend} />
                                    </RadioGroup>
                                </FormControl>
                                } />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="title"
                                rules={{ required: 'Title is required' }}
                                control={control}
                                render={({ field, fieldState }) =>
                                    <TextField
                                        InputProps={{
                                            readOnly: action == RecordActions.view,
                                        }}
                                        helperText={typeof formState.errors?.title?.message === 'string' ? formState.errors?.title?.message : ""}
                                        FormHelperTextProps={{ style: { color: 'red' } }}
                                        {...field}
                                        fullWidth
                                        id="title"
                                        label="Title"
                                    />} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="description"

                                control={control}
                                render={({ field, fieldState }) =>
                                    <div>
                                        <InputLabel>Description</InputLabel>
                                        <HtmlEditor
                                            key={field.name}
                                            {...field}
                                            value={field.value}
                                            placeholder="Enter your description here"
                                        />
                                    </div>
                                } />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="image"
                                control={control}
                                render={({ field, fieldState }) =>
                                    <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value ?? null} mode={action} name={"Image"} label="Image" />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="dateFrom"
                                control={control}
                                render={({ field, fieldState }) =>
                                    <DateTimePicker
                                        sx={{ width: "100%" }}
                                        {...field}
                                        label="Date From"
                                    />
                                } />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="expiry"
                                control={control}
                                render={({ field, fieldState }) =>
                                    <DateTimePicker
                                        sx={{ width: "100%" }}
                                        {...field}
                                        label="Expiry"
                                    />
                                } />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="imageLink"
                                control={control}
                                render={({ field, fieldState }) =>
                                    <TextField
                                        InputProps={{
                                            readOnly: action == RecordActions.view,
                                        }}
                                        {...field}
                                        fullWidth
                                        label="Image Link"
                                        id="imageLink"
                                    />} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field, fieldState }) =>
                                    <FormControlLabel
                                        control={
                                            <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="status" />
                                        }
                                        label="Status"
                                    />} />

                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="pinTop"
                                control={control}
                                render={({ field, fieldState }) =>
                                    <FormControlLabel
                                        control={
                                            <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="pinTop" />
                                        }
                                        label="Pin Top"
                                    />} />

                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">Tabs</FormLabel>
                            <List>
                                {fields.map((item, index) => (
                                    <Grid container spacing={2} key={item.id}>
                                        <Grid item xs={5}>
                                            <ListItem key={item.id}>
                                                <ListItemIcon>
                                                    <Controller
                                                        name={`tabs.${index}.selected`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox {...field} checked={field.value} />
                                                        )}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={item?.tabName} />
                                            </ListItem>


                                        </Grid>
                                    </Grid>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </CardContent >
            </Card >
            <br />
            <br />
            <br />
            <br />
            <br />
            {action != RecordActions.view && <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    zIndex: 9,
                    width: '100%',
                    backgroundColor: '#f5f5f5',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'flex-end', // Align the button to the right
                    boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
                }}
            >
                <Button disabled={loading} variant="contained" color="primary" type="submit">
                    Save
                </Button>
            </Box>
            }
        </Box>
    );
}

