import { useParams } from "react-router-dom";
import React from 'react';
import { Button, Card, CardActions, CardContent, Grid, InputLabel, TextField } from "@mui/material"
import { Image as ImageIcon } from '@phosphor-icons/react/dist/ssr/Image';
import { Controller, useFormContext } from 'react-hook-form';
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";
import ImageUpload from "../../../components/ImageUpload";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { HtmlEditor } from "src/components/HtmlEditor";
export const ThankyouBox = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={12}>
                <Controller
                    name="RegistrationThankYouImage"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { console.log("2222"); field.onChange(url) }} imageUrl={field.value} mode={action} name="RegistrationThankYouImage" label="Thankyou Image" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="RegistrationThankYouMessage"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <div>
                            <InputLabel>ThankYou Message</InputLabel>
                            <HtmlEditor
                                key={field.name}
                                {...field}
                                placeholder="Enter your description here"
                            />
                        </div>
                    } />


            </Grid>

            <Grid item xs={12} sm={12}>
                <Controller
                    name="UrlPlaystore"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            name="UrlPlaystore"
                            label="Url Playstore"
                            id="UrlPlaystore"
                        />} />
            </Grid>

            <Grid item xs={12} sm={12}>
                <Controller
                    name="UrlAppstore"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Url Appstore"
                            id="UrlAppstore"
                        />} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="UrlAppGallery"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            label="Url AppGallery"
                            id="UrlAppGallery"
                        />} />
            </Grid>

        </Grid>)
}