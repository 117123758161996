// src/pages/UnauthorizedPage.tsx

import React from 'react';
import { Container, Typography, Box, Button, Stack } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Typography variant="h4">Unauthorized</Typography>
        <Typography variant="body1">You do not have access to this page.</Typography>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
        >
          Go back to home
        </Button>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Button
          onClick={() => { logout(); navigate("/") }}
          variant="text"
        >
          Logout
        </Button>
      </Box>
    </Container>
  );
};

export default UnauthorizedPage;
