// src/components/Home.tsx
import { AppBar, Toolbar, Stack, IconButton, Typography, Avatar, Box, Divider, MenuItem, Button, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@mui/material';
import React, { useState } from 'react';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { useNavigate } from 'react-router-dom';
import { MsgForm } from './form';
import { Edit, Add, Delete } from '@mui/icons-material';
import { useAlert } from 'src/components/CommonAlert';
import { MessageCategory } from 'src/intefaces/message.interface';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { RecordTypes } from 'src/types/enum';
import MessageCategories from './message-categories';

export const EditMessageForm: React.FC = () => {

    const navigate = useNavigate();
    
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Message Form</Typography>
                </Stack>
                <div>
                    <Button
                        startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/messages')}
                    >
                        View All
                    </Button>
                </div>
            </Stack>
            <MsgForm />
            <MessageCategories />
            <br/>
            <br/>
            <br/>
            <br/>
        </Stack>
    );
};
