import React from "react";
import logo from "./logo.svg";
import "./App.css";
import MyButton from "./components/Button";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import { ThemeProvider } from "@emotion/react";
import { Box, CssBaseline } from "@mui/material";
import theme from "./theme/theme";
import { AuthProvider, useAuth } from "./context/AuthContext";
import AppRoutes from "./routes/Routes";
import { AlertProvider } from "./components/CommonAlert";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StyleProvider } from "./context/StyleContext";
function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <StyleProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <AppRoutes />
            </LocalizationProvider>
          </ThemeProvider>
        </StyleProvider>
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;
