import { Avatar, Card, CardContent, Link, Stack, Typography } from "@mui/material"
import moment from "moment"
import { useMerchant } from "src/context/MerchantContext"
import { NoticeBoard } from "src/intefaces/notice-board.inteface"

export const FullCardViewNotice = ({ notice }: { notice?: NoticeBoard }) => {
    const { merchant } = useMerchant();
    if (notice?.description) notice.description = notice.description.replace(/<p><br><\/p>/g, '');
    return <Card>
        <CardContent>
            {notice && <Stack>
                <Stack direction="row" sx={{ "justifyContent": "space-between", pb: "16px" }}>
                    <Typography
                        variant="body1"
                        sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '1.5rem',
                            textAlign: 'left',
                            fontSize: merchant?.NoticeBoardFontsizeTitle + "px"
                        }}
                    >
                        <b>{notice.title}</b>
                    </Typography>
                </Stack>
                {notice.image && <Link href={notice.imageLink || ""}><img src={notice.image} style={{ width: "100%" }} /></Link>}
                {notice.description && <Typography variant="body2" sx={{ fontSize: merchant?.NoticeBoardFontsizeDescription + "px" }} dangerouslySetInnerHTML={{ __html: notice.description }} />}

            </Stack>}
        </CardContent>
    </Card>
}