
import { Button, Checkbox, Divider, FormControlLabel, Grid, InputLabel, Slider, Stack, TextField } from "@mui/material"
import { Image as ImageIcon } from '@phosphor-icons/react/dist/ssr/Image';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";
import ImageUpload from "../../../components/ImageUpload";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { HtmlEditor } from "src/components/HtmlEditor";
import { TimeField, TimePicker, TimePickerProps } from "@mui/x-date-pickers";
import GoogleMapView from "src/pages/notice-board/about-us/google-map-view";
import { Merchant } from "src/types/merchant-interface";
import { useEffect, useState } from "react";
export const MerchantInfo = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    const { handleSubmit, control, formState: { errors } } = useForm<any>();
 
    return (

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="MerchantCode"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        id="MerchantCode"
                        label="Code"
                    />} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="MerchantName"
                    control={methods.control}
                    rules={{ required: 'Name is required' }}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        helperText={typeof methods.formState.errors?.MerchantName?.message === 'string' ? methods.formState.errors?.MerchantName?.message : ""}
                        FormHelperTextProps={{ style: { color: 'red' } }}
                        id="MerchantName"
                        label="Name *"
                    />} />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantSubTitle"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        id="MerchantSubTitle"
                        label="SubTitle"
                    />} />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantSMSHeader"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        label="SMS Header"
                        id="MerchantSMSHeader"
                    />} />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantAddress"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        label="Address"
                        id="MerchantAddress"
                    />} />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="MerchantPhone1"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        label="Phone 1"
                        id="MerchantPhone1"
                    />} />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="MerchantPhone2"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        label="Phone 2"
                        id="MerchantPhone2"
                    />} />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantEmail"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        label="Email"
                        id="MerchantEmail"
                    />} />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantIntro"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <div>
                            <InputLabel>Intro</InputLabel>
                            <HtmlEditor
                                key={field.name}
                                {...field}
                                placeholder="Enter your intro here"
                            />
                        </div>

                    } />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantDescription"
                    control={methods.control}
                    render={({ field, fieldState }) => (
                        <div>
                            <InputLabel>Description</InputLabel>
                            <HtmlEditor
                                key={field.name}
                                {...field}
                                placeholder="Enter your description here"
                            />
                        </div>
                    )} />
            </Grid>
            <Grid item xs={12}>

                <Controller
                    name="MapZoomLevel"
                    control={methods.control}
                    render={({ field }) => (
                        <Stack spacing={2} direction="column" >
                            <InputLabel>Map Zoom Level</InputLabel>
                            <Slider aria-label="Map Zoom Level"
                                value={field.value ?? 10}
                                onChange={field.onChange}
                                valueLabelDisplay="on"
                                max={22}
                            />
                        </Stack>)
                    } />

            </Grid>

            <Grid item xs={6}>
                <Controller
                    name="MerchantLatitude"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is toduched/blur
                        value={value}
                        label="Latitude"
                        id="MerchantLatitude"
                    />} />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="MerchantLongitude"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                        InputProps={{
                            readOnly: action == RecordActions.view,
                        }}
                        fullWidth
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        label="Longitude"
                        id="MerchantLongitude"
                    />} />
            </Grid>
            <Grid item xs={12}>
                Operating Hours
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="open_mon"
                            control={methods.control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Monday"
                                />
                            } />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_mon_from"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_mon_from"
                            />} />
                    </Grid>
                    <Grid item xs={12} sm={1} sx={{ alignContent: "center", textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_mon_to"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_mon_to"
                            />} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="open_tue"
                            control={methods.control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Tuesday"
                                />
                            } />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_tue_from"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_tue_from"
                            />} />
                    </Grid>
                    <Grid item xs={12} sm={1} sx={{ alignContent: "center", textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_tue_to"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_mon_to"
                            />} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="open_wed"
                            control={methods.control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Wednesday"
                                />
                            } />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_wed_from"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_wed_from"
                            />} />
                    </Grid>
                    <Grid item xs={12} sm={1} sx={{ alignContent: "center", textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_wed_to"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_mon_to"
                            />} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="open_thus"
                            control={methods.control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Thursday"
                                />
                            } />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_thus_from"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_thus_from"
                            />} />
                    </Grid>
                    <Grid item xs={12} sm={1} sx={{ alignContent: "center", textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_thus_to"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_thus_to"
                            />} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="open_fri"
                            control={methods.control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Friday"
                                />
                            } />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_fri_from"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_fri_from"
                            />} />
                    </Grid>
                    <Grid item xs={12} sm={1} sx={{ alignContent: "center", textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_fri_to"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_fri_to"
                            />} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="open_sat"
                            control={methods.control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Saturday"
                                />
                            } />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_sat_from"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_sat_from"
                            />} />
                    </Grid>
                    <Grid item xs={12} sm={1} sx={{ alignContent: "center", textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_sat_to"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_sat_to"
                            />} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="open_sun"
                            control={methods.control}
                            render={({ field }) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Sunday"
                                />
                            } />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_sun_from"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_sun_from"
                            />} />
                    </Grid>
                    <Grid item xs={12} sm={1} sx={{ alignContent: "center", textAlign: "center" }}>
                        to
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="open_sun_to"
                            control={methods.control}
                            render={({ field: { onChange, onBlur, value, ref } }) => <TextField
                                InputProps={{
                                    readOnly: action == RecordActions.view,
                                }}
                                fullWidth
                                onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is toduched/blur
                                value={value}
                                label=""
                                type="time"
                                id="open_sun_to"
                            />} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantLogo"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name={"MerchantLogo"} label="Merchant Logo" />
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="MerchantCover"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="MerchantCover" label="Merchant Cover" />
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel>Merchant Gallery</InputLabel>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_1"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_1" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_2"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_2" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_3"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_3" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_4"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_4" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_5"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_5" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_6"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_6" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_7"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_7" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_8"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_8" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_9"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_9" label="" />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Controller
                    name="g_img_10"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="g_img_10" label="" />
                    }
                />
            </Grid>

        </Grid>

    );
}