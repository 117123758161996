import * as React from "react";
import { useSelection } from "../../hooks/use-selection";
import DataTable from "../../components/DataTable";
import { CONSTANTS } from "../../constants";
import axiosService from "../../services/axios";
import { useAlert } from "../../components/CommonAlert";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { RecordTypes } from "src/types/enum";
import { Messages } from "src/intefaces/message.interface";
import { MessageForm } from "src/intefaces/message-form.interface";

interface MessagesTableProps {
  count?: number;
  page?: number;
  rows?: Messages[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any;
}

export function MessagesTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback,
}: MessagesTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((messages) => messages.id);
  }, [rows]);
  const navigate = useNavigate();
  const { selectAll, deselectAll, selectOne, deselectOne, selected } =
    useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome =
    (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(
    undefined
  );
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState<{ id: string; label: string }[]>(
    []
  );
  const handleEdit = (id: number) => {
    navigate(`/messages/edit/${id}`);
  };
  const getMessageFormDetails = async () => {
    try {
      const response = await axiosService.get<MessageForm>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.messageForm]}`
      );
      if (response.status != 200) {
      } else {
        const json: MessageForm = response.data;
        let cols = [];
        if (json.id) {
          cols.push({ id: "id", label: "Id" });
          if (json.categoryVisible) {
            cols.push({
              id: "categoryid",
              label: json.categoryLabel ?? "Category",
            });
          }
          if (json.nameVisible) {
            cols.push({ id: "name", label: json.nameLabel ?? "Name" });
          }
          if (json.contactVisible) {
            cols.push({ id: "contact", label: json.contactLabel ?? "Contact" });
          }
          if (json.emailVisible) {
            cols.push({ id: "email", label: json.emailLabel ?? "Email" });
          }
          if (json.messageVisible) {
            cols.push({ id: "message", label: json.messageLabel ?? "Message" });
          }
          if (json.fileVisible) {
            cols.push({
              id: "file",
              label: json.fileLabel ?? "File",
              type: "file",
            });
          }
          if (json.dateTimeVisible) {
            cols.push({
              id: "dateTime",
              label: json.dateTimeLabel ?? "Date/Time",
              type: "datetime",
            });
          }
        } else {
          cols = [
            { id: "id", label: "Id" },
            { id: "categoryid", label: "Category" },
            { id: "name", label: "Name" },
            { id: "contact", label: "Contact" },
            { id: "email", label: "Email" },
            { id: "message", label: "Message" },
            { id: "file", label: "File", type: "file" },
            { id: "dateTime", label: "Date", type: "date" },
          ];
        }
        setColumns(cols);
      }
    } catch (ex) {
      console.error("Exception Caught", ex);
    }
  };
  const handleDelete = async (id: number) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  const handleView = (id: number) => {
    navigate(`/messages/view/${id}`);
  };

  const onDeleteConfirm = async () => {
    try {
      setOpenDeleteDlg(false);
      const response = await axiosService.delete<Messages[]>(
        `${
          CONSTANTS.ApiConstants.GENERIC.delete[RecordTypes.messages]
        }/${selectedId}`
      );
      if (response.status != 200) {
        showAlert("Failed to Delete Merchant", "error");
      } else {
        showAlert("Merchant Deleted Successfully", "success");
        onDeleteCallback && onDeleteCallback();
      }
    } catch (ex) {
      showAlert("Failed to Delete Merchant", "error");
      console.error("Exception Caught", ex);
    } finally {
      setSelectedId(undefined);
    }
  };
  React.useEffect(() => {
    getMessageFormDetails();
  }, []);
  return (
    <div>
      <DataTable
        rows={rows}
        columns={columns}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        showActionDelete={false}
        showActionEdit={false}
        showActionView={false}
      />
      <DeleteConfirmationDialog
        open={openDeleteDlg}
        onClose={() => {
          setOpenDeleteDlg(false);
          setSelectedId(undefined);
        }}
        onConfirm={onDeleteConfirm}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete merchant"}
        title={"Confirm Delete"}
      />
    </div>
  );
}
