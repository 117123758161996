import { Button, Card, CardActions, CardContent, FormControlLabel, Grid, Switch } from "@mui/material"
import React from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { RecordActions, Roles } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";
import { useAuth } from "../../../context/AuthContext";

export const MajorFunction = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    const { user } = useAuth();
    return (
        <Grid container spacing={2} >
            {user?.role == Roles.superadmin && <Grid item xs={12} sm={12}>

                <Controller
                    name="FunctionBranches"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionBranches" />
                            }
                            label="Branch"
                        />} />

            </Grid>}

            {user?.role == Roles.superadmin && <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionPartner"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionPartner" />
                            }
                            label="Partner"
                        />} />

            </Grid>}
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionProduct"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionProduct" />
                            }
                            label="Product"
                        />} />

            </Grid>

            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionService"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionService" />
                            }
                            label="Service"
                        />} />

            </Grid>

            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionPOS"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionPOS" />
                            }
                            label="POS"
                        />} />

            </Grid>
            {user?.role == Roles.superadmin && <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionStore"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionStore" />
                            }
                            label="Store"
                        />} />

            </Grid>}
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionOrdering"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionOrdering" />
                            }
                            label="Ordering"
                        />} />

            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionBooking"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionBooking" />
                            }
                            label="Booking"
                        />} />

            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionMessage"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionMessage" />
                            }
                            label="Message"
                        />} />

            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionNoticeboard"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionNoticeboard" />
                            }
                            label="Noticeboard"
                        />} />

            </Grid>
            <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionDirectory"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionDirectory" />
                            }
                            label="Directory"
                        />} />

            </Grid> <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionGallery"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view}{...field} checked={field.value} name="FunctionGallery" />
                            }
                            label="Gallery"
                        />} />

            </Grid>
            {user?.role == Roles.superadmin && <Grid item xs={12} sm={12}>
                <Controller
                    name="FunctionGame"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <FormControlLabel
                            control={
                                <Switch disabled={action == RecordActions.view} {...field} checked={field.value} name="FunctionGame" />
                            }
                            label="Game"
                        />} />

            </Grid>}

        </Grid>)
}