import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ResponsiveDrawer from './ResponsiveDrawer';
import { CssBaseline, Box } from '@mui/material';

const Layout: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const shouldHideDrawer = location.pathname === '/signup' || location.pathname === '/login';

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!shouldHideDrawer && (
        <ResponsiveDrawer open={drawerOpen} onClose={handleDrawerToggle} />
      )}
      <Box component="main" sx={{ flexGrow: 1, p:2, pt: 10, }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
