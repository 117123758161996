import React, { useEffect, useState } from 'react';
import { AppBar, Box, Tab, Typography, Toolbar, useTheme, BottomNavigation, BottomNavigationAction, styled, CssBaseline, MenuItem, Popover, Avatar, IconButton, Stack, Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CONSTANTS } from 'src/constants';
import { BottomButton } from 'src/intefaces/bottom-button.interface';
import axiosService from 'src/services/axios';
import { BottomButtonType } from 'src/types/enum';
import { useAlert } from 'src/components/CommonAlert';
import { useParams } from 'react-router-dom';
import { MerchantProvider, useMerchant } from 'src/context/MerchantContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from 'src/context/AuthContext';


interface CustomAppBarProps {
  backgroundColor?: string;
  textColor?: string;
  selectedColor?: string;
}
const StyledAppBar = styled(AppBar)<CustomAppBarProps>(({ backgroundColor, textColor }) => ({
  backgroundColor: backgroundColor || "primary",
  color: textColor || '#FFFFFF', // Default to white if textColor is not provided
}));
const StyledBottomNavigation = styled(BottomNavigation)<CustomAppBarProps>(


  ({ backgroundColor, textColor }) => ({
    backgroundColor,
    color: textColor || '#FFFFFF',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    zIndex: 9
  })
);
const StyledBottomNavigationAction = styled(BottomNavigationAction)<CustomAppBarProps>(


  ({ backgroundColor, textColor, selectedColor }) => ({
    color: textColor || '#FFFFFF',
    '&.Mui-selected': {
      color: selectedColor || 'primary', // Color when selected
    },
  })
);
type MenuState = {
  [key: string]: HTMLElement | null;
};
const MobileLayout: React.FC = () => {
  return (<MerchantProvider>
    <Layout />
  </MerchantProvider>)
}
const Layout: React.FC = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [navigationValue, setNavigationValue] = React.useState(0);
  const [bottomButtons, setBottomButtons] = useState<BottomButton[]>([]);
  const [tabValue, setTabValue] = React.useState("1");
  const { merchant, setBackButton, getBackButton } = useMerchant();
  const { merchantid } = useParams();
  const location = useLocation();
  const [anchorEls, setAnchorEls] = useState<MenuState>({});
  const { user, getToken } = useAuth();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const { showAlert } = useAlert();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  // Custom styled Tab to apply active styles
  const StyledTab = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  }));
  const setTheme = () => {
    // Change theme color dynamically for PWA (Android)
    const metaThemeColor = document.querySelector("meta[name='theme-color']");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "rgba(255, 0, 0, 0.5)");
    }

    // Change status bar color for iOS PWA
    const metaAppleStatusBar = document.querySelector("meta[name='apple-mobile-web-app-status-bar-style']");
    if (metaAppleStatusBar) {
      metaAppleStatusBar.setAttribute("content", "rgba(255, 0, 0, 0.5)");
    }
  }
  useEffect(() => {
    getButtons();
    setTheme();
  }, [])
  useEffect(() => {
    if (location && bottomButtons) {

      const locArr = location.pathname.split('/');
      const index = bottomButtons.findIndex((b) => {
        return locArr[2] && b?.hyperlink.toLowerCase() == locArr[2].toLowerCase()
      })
      console.log("index", index)
      setNavigationValue(index > -1 ? index : 0)
    }
  }, [location, bottomButtons])
  const getButtons = async () => {
    try {
      const response = await axiosService.get<BottomButton[] | any>(`${CONSTANTS.ApiConstants.getBottomButtons}/${merchantid}`)
      if (response.status != 200) {
        showAlert(response?.data?.message ?? 'Failed to get buttons', 'error');
        setBottomButtons([]);
      } else {
        const json = response.data;
        setBottomButtons(json)
      }
    } catch (ex: any) {
      setBottomButtons([])
      showAlert(ex?.response?.data?.message ?? 'Failed to get buttons', 'error');
      console.error("Exception Caught", ex)
    }
  }
  const menuClicked = (event: any, b: BottomButton) => {
    const buttonKey = b.type + b.feature + b.id;
    switch (b.type) {
      case BottomButtonType.STANDARD:
        navigate(`${merchant?.id}/${b.hyperlink}`)
        break;
      case BottomButtonType.SELFDEFINE:
        window.location.href = b.hyperlink
        break;
      case BottomButtonType.SELFSUBBUTTON:
        setAnchorEls({ ...anchorEls, [buttonKey]: event.currentTarget });
        break;
      default:
        break;
    }
  }
  const handleClose = (buttonKey: string) => {
    setAnchorEls({ ...anchorEls, [buttonKey]: null });
  };
  const renderPopover = (b: BottomButton) => {
    const buttonKey = b.type + b.feature + b.id;
    const anchorEl = anchorEls[buttonKey];
    const open = Boolean(anchorEl);
    const id = open ? `${buttonKey}-popover` : undefined;

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose(buttonKey)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {b?.subButtons && b?.subButtons.map((sb, index) => (
          sb.name && <MenuItem key={index} onClick={() => { handleClose(buttonKey); window.location.href = sb.hyperlink }}>
            {sb.symbol && React.createElement(Icons[(sb.symbol) as keyof typeof Icons])} &nbsp;&nbsp;{sb.name}
          </MenuItem>
        ))}
      </Popover>
    );
  }
  return (
    <Box>
      <CssBaseline />
      <Box>
        {bottomButtons[navigationValue] && bottomButtons[navigationValue].showHeader ? <StyledAppBar backgroundColor={merchant?.HeaderColorCode} position="static">

          <Toolbar sx={{ justifyContent: "start" }}>
            {getBackButton && <IconButton onClick={() => navigate(-1)} sx={{ color: "white" }}>
              <ArrowBackIcon />
            </IconButton>}

            <Stack sx={{ alignItems: "center", flex: 1 }}>{merchant?.HeaderLogo ? <Avatar variant='square' src={merchant?.HeaderLogo}></Avatar> :
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
                {bottomButtons[navigationValue]?.name || merchant?.MerchantName}
              </Typography>}
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              {user ?
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="account"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => { navigate(`${merchant?.id}/memberacclanding`) }}
                >
                  <Avatar
                    alt={user?.account?.name}
                    src={user?.account?.logo}
                    sx={{ width: 25, height: 25, marginBottom: 1 }}
                  />
                </IconButton> :
                <Button variant="text" style={{ color: "white" }} onClick={() => navigate(`${merchant?.id}/membersignin`)}>Login</Button>
              }
            </Stack>
          </Toolbar>
        </StyledAppBar> : <></>}
        <Box component="main">
          <Outlet />
          <br />
          <br />
          <br />
        </Box>
        {bottomButtons && bottomButtons.map(
          (b) => renderPopover(b)
        )}
        <StyledBottomNavigation
          value={navigationValue}
          onChange={(event, newValue) => setNavigationValue(newValue)}
          showLabels={true}
          backgroundColor={merchant?.FooterColorCode}>
          {bottomButtons && bottomButtons.map(
            (b) =>
              <StyledBottomNavigationAction textColor={merchant?.FooterButtonColor} selectedColor={merchant?.FooterActiveButtonColor} label={b.name} icon={React.createElement(Icons[(b.symbol) as keyof typeof Icons])} sx={{ minWidth: 0 }} onClick={(e) => menuClicked(e, b)} />
          )}

        </StyledBottomNavigation>
      </Box >
    </Box >
  );
};

export default MobileLayout;
