import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Paper,
  Box,
  Typography,
  Tooltip,
  Collapse,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import { DataType } from "../types/enum";
import { Download as DownloadIcon } from "@mui/icons-material";
import { Coupon } from "src/intefaces/coupon.interface";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useAuth } from "src/context/AuthContext";
import * as Icons from "@mui/icons-material";

interface RowData {
  id: number;
  [key: string]: any; // Allows for additional dynamic properties
}

interface DataTableProps {
  rows: RowData[];
  columns: { id: string; label: string; type?: string }[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onView: (id: number) => void;
  rowsPerPageOptions?: number[];
  expandComponent?: (row: RowData) => React.ReactNode;
  expandButtonLabel?: string;
  expandButtonIcon?: string;
  showActionEdit?: boolean;
  showActionDelete?: boolean;
  showActionView?: boolean;
  showActionExpand?: boolean;
}

const formatLocalDateTime = (utcDate: string) => {
  if (!utcDate) return null;
  const date = new Date(utcDate);
  return format(date, "Pp"); // Example: '07/27/2024, 12:00 PM'
};
const downloader = (url: string) => {
  return (
    <Tooltip title={"Download"}>
      <IconButton color="primary" onClick={() => window.open(url, "_blank")}>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};
const formatLocalDate = (utcDate: string) => {
  if (!utcDate) return null;
  const date = new Date(utcDate);
  return format(date, "P"); // Example: '07/27/2024, 12:00 PM'
};
const DataTable: React.FC<DataTableProps> = ({
  rows,
  columns,
  onEdit,
  onDelete,
  onView,
  rowsPerPageOptions = [10, 25, 50, 100],
  expandComponent,
  expandButtonLabel,
  expandButtonIcon,
  showActionDelete = true,
  showActionEdit = true,
  showActionView = true,
  showActionExpand = false,
}) => {
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandRow = (id: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };
  const paginatedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#e9e9e9" }}>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#e9e9e9",
                    }}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {(showActionDelete ||
                  showActionEdit ||
                  showActionView ||
                  showActionExpand) &&
                  user?.role === "merchant" && (
                    <TableCell
                      sx={{
                        fontSize: "medium",
                        fontWeight: "bold",
                        backgroundColor: "#e9e9e9",
                      }}
                    >
                      Actions
                    </TableCell>
                  )}
              </TableRow>
            </TableHead>
            {/* <TableBody>
              {paginatedRows.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow key={"tr_" + index}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {(column?.type ?? "").toLowerCase() === DataType.date
                          ? formatLocalDate(row[column.id])
                          : (column?.type ?? "").toLowerCase() ===
                            DataType.datetime
                          ? formatLocalDateTime(row[column.id])
                          : (column?.type ?? "").toLowerCase() === DataType.file
                          ? downloader(row[column.id])
                          : row[column.id]}
                      </TableCell>
                    ))}
                    {(showActionDelete ||
                      showActionEdit ||
                      showActionView ||
                      showActionExpand) &&
                      user?.role === "merchant" && (
                        <TableCell sx={{ minWidth: "160px" }}>
                          {showActionEdit && (
                            <IconButton
                              onClick={() => onEdit(row.id)}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          {showActionExpand && (
                            <IconButton onClick={() => handleExpandRow(row.id)}>
                              {expandedRows.has(row.id) ? (
                                expandButtonLabel ? (
                                  <Button
                                    variant="outlined"
                                    startIcon={<ExpandLessIcon />}
                                  >
                                    {expandButtonLabel}
                                  </Button>
                                ) : (
                                  <ExpandLessIcon />
                                )
                              ) : expandButtonLabel ? (
                                <Button
                                  variant="outlined"
                                  startIcon={
                                    expandButtonIcon ? (
                                      React.createElement(
                                        Icons[
                                          expandButtonIcon as keyof typeof Icons
                                        ],
                                        { color: "primary" }
                                      )
                                    ) : (
                                      <ExpandMoreIcon color="primary" />
                                    )
                                  }
                                >
                                  {expandButtonLabel}
                                </Button>
                              ) : expandButtonIcon ? (
                                React.createElement(
                                  Icons[expandButtonIcon as keyof typeof Icons],
                                  { color: "primary" }
                                )
                              ) : (
                                <ExpandMoreIcon color="primary" />
                              )}
                            </IconButton>
                          )}

                          {showActionDelete && (
                            <IconButton
                              onClick={() => onDelete(row.id)}
                              color="secondary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                  </TableRow>
                  {expandComponent &&
                    expandedRows &&
                    expandedRows.has(row.id) && (
                      <TableRow key={"tr2_" + index}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={columns.length + 2}
                        >
                          <Collapse
                            in={expandedRows.has(row.id)}
                            timeout="auto"
                            unmountOnExit
                            key={row.id}
                          >
                            <Box key={`ex${row.id}`}>
                              {expandComponent ? expandComponent(row) : <></>}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                </React.Fragment>
              ))}
            </TableBody> */}
            <TableBody>
              {paginatedRows.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow key={"tr_" + index}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {/* Check if the column is 'Id', and then shorten the display */}
                        {column.label.toLowerCase() === "#"
                          ? page * rowsPerPage + index + 1 // Condense to first 3 characters, e.g., '123...'
                          : (column?.type ?? "").toLowerCase() === DataType.date
                          ? formatLocalDate(row[column.id])
                          : (column?.type ?? "").toLowerCase() ===
                            DataType.datetime
                          ? formatLocalDateTime(row[column.id])
                          : (column?.type ?? "").toLowerCase() === DataType.file
                          ? downloader(row[column.id])
                          : row[column.id]}
                      </TableCell>
                    ))}
                    {(showActionDelete ||
                      showActionEdit ||
                      showActionView ||
                      showActionExpand) &&
                      user?.role === "merchant" && (
                        <TableCell sx={{ minWidth: "160px" }}>
                          {showActionEdit && (
                            <IconButton
                              onClick={() => onEdit(row.id)}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          {showActionExpand && (
                            <IconButton onClick={() => handleExpandRow(row.id)}>
                              {expandedRows.has(row.id) ? (
                                expandButtonLabel ? (
                                  <Button
                                    variant="outlined"
                                    startIcon={<ExpandLessIcon />}
                                  >
                                    {expandButtonLabel}
                                  </Button>
                                ) : (
                                  <ExpandLessIcon />
                                )
                              ) : expandButtonLabel ? (
                                <Button
                                  variant="outlined"
                                  startIcon={
                                    expandButtonIcon ? (
                                      React.createElement(
                                        Icons[
                                          expandButtonIcon as keyof typeof Icons
                                        ],
                                        { color: "primary" }
                                      )
                                    ) : (
                                      <ExpandMoreIcon color="primary" />
                                    )
                                  }
                                >
                                  {expandButtonLabel}
                                </Button>
                              ) : expandButtonIcon ? (
                                React.createElement(
                                  Icons[expandButtonIcon as keyof typeof Icons],
                                  { color: "primary" }
                                )
                              ) : (
                                <ExpandMoreIcon color="primary" />
                              )}
                            </IconButton>
                          )}

                          {showActionDelete && (
                            <IconButton
                              onClick={() => onDelete(row.id)}
                              color="secondary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                  </TableRow>
                  {expandComponent &&
                    expandedRows &&
                    expandedRows.has(row.id) && (
                      <TableRow key={"tr2_" + index}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={columns.length + 2}
                        >
                          <Collapse
                            in={expandedRows.has(row.id)}
                            timeout="auto"
                            unmountOnExit
                            key={row.id}
                          >
                            <Box key={`ex${row.id}`}>
                              {expandComponent ? expandComponent(row) : <></>}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
};

export default DataTable;
